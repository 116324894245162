import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Microphone from './microphone/Microphone';
import Transcription from './transcription/Transcription';
import Document from './document/Document';

const Note: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '79.5%',
          height: '100%', 
          position: 'absolute', 
          right: 0,
          bottom: 0, 
          backgroundColor: colors?.interface, 
      }}>

        <Microphone /> 

        <Transcription />

        <Document /> 

      </Box>

  );

};

export default Note;