import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = (t) => [
    { 
        type: 'item', 
        section: 'groupAccessList', 
        text: 'group', 
        key:'i-groupAccessList' 
    },
    { 
        type: 'item', 
        section: 'createGroupAccess', 
        text: 'create', 
        key:'i-createGroupAccess' 
    },
];

export default menuItems; 