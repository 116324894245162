import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '@mui/material/Button';

// Routes

import { createHacker } from '../../../../routes/hacker/users';

// Redux 

import { resetCreateHacker } from '../../../../redux/features/hacker/users/createHacker';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { setDashboardSection } from '../../../../redux/features/general/navigation';
import { setIsRefreshHackersList } from '../../../../redux/features/hacker/users/hackersAccess';



const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation(); 
  
  const colors = useAppSelector(state => state.theme.colors); 

  const firstName = useAppSelector(state => state.createHacker.firstName); 
  const lastName = useAppSelector(state => state.createHacker.lastName); 
  const email = useAppSelector(state => state.createHacker.email); 
  const main = useAppSelector(state => state.createHacker.main); 

  const [allowUserCreation, setAllowUserCreation] = useState(false); 
  const [error, setError] = useState('');  

  useEffect(() => { 

    if (firstName && lastName && email) { 

        setAllowUserCreation(true); 

    } else { 

        setAllowUserCreation(false); 

    };

  },[firstName, lastName, email]); 

  // -- 

  const handleCreateHacker = async () => { 

     const res = await createHacker(email, firstName, lastName, main) as any; 

     if (res.data.status === 200) { 

      // Redux is reset. 

      dispatch(resetCreateHacker()); 

      dispatch(setNotificationMessage('Hacker Successfully Created')); 

      dispatch(setDashboardSection('hackers')); 

      dispatch(setIsRefreshHackersList(true)); 

     } else if (res.data.status === 401) { 

        setError(res.data.error); 

    }; 

  }; 

  // -- 

  return (

    <Box
    sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%'
    }}
>

      <Box
          onClick={handleCreateHacker}
          sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              padding: 1, 
              fontSize: 16, 
              borderRadius: 1, 
              marginBottom: 4, 
              marginTop: 3, 
              marginRight: 2, 
              height: 40,
              color: allowUserCreation ? 'white' : colors?.text, 
              cursor: allowUserCreation ? 'pointer' : 'default', 
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
              backgroundColor: allowUserCreation ? colors?.navbarToolEnabled : colors?.navbarToolDisabled, 
              '&:hover': { backgroundColor: allowUserCreation ? colors?.navbarToolEnabledHover : colors?.navbarToolDisabled  }
          }}
      >

          <Box>{t('createHackerButton')}</Box>

          <AddCircleOutlineIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

      </Box>

      <Box 
          sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              marginLeft: 1, 
              color: colors?.red
          }}
      >
          {error}
      </Box>

  </Box>

  );

};

export default SubmitButton;