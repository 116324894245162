import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Components 

import DoctorsList from './doctors/DoctorsList';
import CreateDoctor from './doctors/CreateDoctor';
import SupportTicketsList from './supportTickets/SupportTicketsList';
import CreateSupportTicket from './supportTickets/CreateSupportTicket';
import HackersList from './hackers/hackers/HackersList';
import CreateHacker from './hackers/account/CreateHacker';
import Data from './data/Data';
import PatientsList from './patients/patientsList/PatientsList';

const Container: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.navigation.section);
  const accountInfo = useAppSelector(state => state.meta.accountInfo); 

  return (

      <Box component="main" sx={{ 
          display: 'flex', 
          height: '95vh', 
          width: '95vw',
          right: 0, 
          bottom: 0, 
          overflowX: 'hidden',
          scrollbarWidth: 'none', 
          position: 'absolute',
          backgroundColor: colors?.interface,  
          color: colors?.text }}>

          { section === 'overview' && <Data />}

          { ((section === 'doctors') && (accountInfo?.main)) && <DoctorsList />}

          { section === 'createDoctor' && <CreateDoctor />}

          { section === 'patients' && <PatientsList />}

          { section === 'supportTickets' && <SupportTicketsList />}

          { section === 'createSupportTicket' && <CreateSupportTicket />}

          { ((section === 'hackers') && (accountInfo?.main)) && <HackersList />}

          { ((section === 'createHacker') && (accountInfo?.main)) && <CreateHacker />}


      </Box>
  );

};

export default Container;