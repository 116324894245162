import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

// -- Component 

import Box from '@mui/material/Box';
import LoginBackground from '../login/LoginBackground';
import AccessList from './accessList/AccessList';
import Title from './items/Title';
import Logo from './items/Logo';

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate();
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    return (

        <Box
            component="main"
            maxWidth="xs"
            sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            color: colors?.highlightedText
            }}
        >

            <LoginBackground />

            <Title />

            <AccessList /> 

            <Logo />

        </Box>
    );
};

export default Container;