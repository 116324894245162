import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Components 

import { useTranslation } from 'react-i18next';

interface Props { 
    title?: string
}

const Legend: React.FC<Props> = ({title}) => {

  const { t } = useTranslation();  

  const colors = useAppSelector(state => state.theme.colors);  
  
  return (

      <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        width: '100%',
        paddingLeft: '1%',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
        backgroundColor: colors?.listHeaderBackground,
        color: colors?.text, 
        fontSize: 14, 
    }}>

      <>

        <div style={{  width: '18%', marginLeft: '2%' }}>{t('user')}</div>

        <Box sx={{ width: '15%' }}>{t('status')}</Box>

        <Box sx={{ width: '25%' }}>{t('email')}</Box>

        <Box sx={{ width: '20%' }}>{t('accountType')}</Box>

        <Box sx={{ width: '15%' }}>{t('lastActive')}</Box>

        <Box sx={{ width: '5%' }}>More</Box>

      </>


  </Box>

  );

};

export default Legend;