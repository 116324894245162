import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import QrCode from './code/QrCode';
import CloseSidebar from './CloseSidebar';
import Fields from './fields/Fields';
import Shortcuts from './shortcuts/Shortcuts';

const Sections: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const sidebarSection = useAppSelector(state => state.consultationNote.sidebarSection); 
    const isSidebarOpened = useAppSelector((state) => state.consultationNote.isSidebarOpened);

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: isSidebarOpened ? '25vw' : 0,
          maxWidth: ((window?.innerWidth - 560) / 2) - 175, 
          height: '90vh',
          position: 'absolute', 
          left: '5vw',
          paddingTop: 8,
          bottom: 0,  
          backgroundColor: isSidebarOpened ? colors?.consultationNoteSideBar : 'transparent', 
          transition: 'width 0.3s'
      }}>

        
        { (isSidebarOpened && (sidebarSection === 'code')) && <QrCode />} 

        { (isSidebarOpened && (sidebarSection === 'fields')) && <Fields />} 

        { (isSidebarOpened && (sidebarSection === 'shortcuts')) && <Shortcuts />}

        { (isSidebarOpened && sidebarSection) && <CloseSidebar />}        

      </Box>

    );

};

export default Sections;