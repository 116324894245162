import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import FolderDropdown from './FolderDropdown';
import FileDropdown from './FileDropdown';
import { Folder } from '@mui/icons-material';

const Dropdown: React.FC = () => {

    const colors = useAppSelector(state => state.theme.colors);
    
    const mouseInfo = useAppSelector(state => state.fileExplorer.mouseInfo); 
    const isDropdownOpened = useAppSelector(state => state.fileExplorer.isDropdownOpened); 
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 
    const folderView = useAppSelector(state => state.fileExplorer.folderView); 

    const [height, setHeight] = useState(-180); 

    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0); 

    // --

    useEffect(() => { 

        if (selectedFolder) { 

            selectedFolder?.protected && setHeight(-100); 

            if (selectedFolder?.relatedPatient) { 

                selectedFolder?.protected ? setHeight(-140) : setHeight(-260);

            };

        } else if (selectedFile) { 

            selectedFile?.relatedPatient && setHeight(-240); 

        };


    },[selectedFolder, selectedFile]); 

    // --


    useEffect(() => { 

        if (mouseInfo) { 

            if (folderView === 'row') { 

                setXPosition(mouseInfo?.clientX - 490); 
                setYPosition(height + 200);

            } else if (folderView === 'box') { 

                setXPosition(mouseInfo?.clientX - 160); 
                setYPosition(height + 540);

            }; 

        }; 

    },[mouseInfo]);

    // -- 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 200,
                height: 'auto',
                top: yPosition, 
                left: xPosition, 
                position: 'absolute',
                backgroundColor: colors?.fileExplorerDropdown,
                borderRadius: 4,
                zIndex: 5, 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                transition: 'width 0.3s',
            }}
        >

            { (isDropdownOpened && selectedFolder) && <FolderDropdown />}

            { (isDropdownOpened && selectedFile) && <FileDropdown />}

        </Box>

    )

}; 

export default Dropdown; 