import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const HelperText: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            position: 'absolute',
            bottom: '5vh'
      }}>

    <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 15,
          color: 'rgb(160, 160, 160)',
        }}
      >
        <Box sx={{
          marginRight: 1
        }}>{t('pinIssueInfo')}
        </Box>

        <Box
            onClick={() => navigate('/login')}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: 'auto',
                cursor: 'pointer', 
                backgroundColor: 'transparent',
                textDecoration: 'none',
                color: 'rgb(9,109,108)', 
                textTransform: 'none',
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#05E5D3',
                },
            }}
        >{t('login')}
        </Box>

      </Box>


      </Box>

  );

};

export default HelperText;