import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Box from '@mui/material/Box';
import { setEmergencyContactPhone, setPhone } from '../../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

interface Props { 
    action: string; 
}


const PhoneSelector: React.FC<Props> = ({ action}) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const phone = useAppSelector(state => state.createPatient.phone); 
    const emergencyContactPhone = useAppSelector(state => state.createPatient.emergencyContactPhone); 

    const [phoneNumber, setPhoneNumber] = useState(action === 'phone' ? phone : emergencyContactPhone) as any; 

    // --

    useEffect(() => { 

        if (action === 'phone') { 

            dispatch(setPhone(phoneNumber));

        } else if (action === 'emergencyContactPhone') { 

            dispatch(setEmergencyContactPhone(phoneNumber)); 

        }; 

    },[phoneNumber]); 

    // --

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems:'center', 
                width: 300,
                marginLeft: 2, 
            }}
        >

            <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="CA"
                value={phoneNumber}
                onChange={setPhoneNumber}
            />

        </Box>
  );

};

export default PhoneSelector;