import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
  trashFiles: any,
  selectedData: any, 
  isRefreshTrashBin: boolean; 
}

const initialState: InitialState = {
  trashFiles: [],
  selectedData: null, 
  isRefreshTrashBin: false,  
};

export const trashSlice = createSlice({
  name: 'trash',
  initialState,
    reducers: {
    setTrashFiles: (state, action: PayloadAction<any>) => {
      state.trashFiles = action.payload 
    },
    setSelectedData: (state, action: PayloadAction<any>) => {
      state.selectedData = action.payload 
    }, 
    setIsRefreshTrashBin: (state, action: PayloadAction<boolean>) => {
      state.isRefreshTrashBin = action.payload 
    },
    resetTrash: (state) => { 

      state.trashFiles = []; 
      state.selectedData = null; 
      state.isRefreshTrashBin = false; 

    } 
  },
});

export const {
  setTrashFiles, 
  setSelectedData,
  setIsRefreshTrashBin, 
  resetTrash,
} = trashSlice.actions;

export default trashSlice.reducer;