import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// -- 

export const getConsultation = async (consultationId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a consultation:', error);
    }
};

// -- 

export const getConsultations = async (patientId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/patient/${patientId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a list of consultations:', error);
    }
};

// -- 

export const createConsultation = async () => {

    try {

        const content = {}; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a consultation:', error);
    }
};

// -- 

export const saveConsultation = async (consultationId: string) => {

    try {

        const content = { 
            consultationId: consultationId
        }; 

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/consultation`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error saving a consultation:', error);
    }
};

// -- 

export const deleteConsultation = async (consultationId: string) => {

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a consultation:', error);
    }; 
};

// --- 

// ---

// --- 

// --- Consultation Notes 

// -- 

// -- 

// -- 

// -- 

// -- 

export const getConsultationNote = async (consultationNoteId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a consultation note.', error);
    }; 

}; 

// -- 

export const getNoteTemplates = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/templates/list`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving note templates.', error);
    }; 

}; 

// -- 

export const getConsultationNotes = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/notes/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation notes.', error);
    }; 

}; 

interface ConsultationNoteProps { 
    patientId?: string; 
    consultationId?: string; 
    type?: string; 
}

export const createConsultationNote = async (content: ConsultationNoteProps) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a consultation note:', error);
    }
};

// -- 

export const createNoteWithTemplate = async (noteTemplateId: string, consultationId: string) => {

    try {

        const content = { 
            consultationId: consultationId
        };

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/templates/${noteTemplateId}`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a consultation note with a template:', error);
    }
};

// -- 

interface EditConsultationNoteProps { 
    consultationNoteId: string | undefined; 
    mode: string | undefined; 
    newDescription?: string; 
    overWrite?: boolean; 
    securityPin: string | undefined; 
    attribute?: string; 
    newField?: string; 
    selectionStart?: number; 
    selectedNoteFieldId?: string; 
}; 

export const editConsultationNote = async (content: EditConsultationNoteProps) => {

    try {

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/note/${content.consultationNoteId}`, content);

        return res; 
        
    } catch (error) {
        console.error('Error editing a consultation note:', error);
    }
};

// --

export const getAutoComplete = async (formData: any, consultationNoteId: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/autoComplete/${consultationNoteId}`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error rendering the auto complete', error);
    }; 

}; 

// -- 

export const getConsultationLanguage = async (consultationNoteId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/note/language/${consultationNoteId}`);

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation note default language:', error);
    }
};

// -- 

export const serveConsultationNote = async (consultationNoteId?: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/serve/${consultationNoteId}`, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a consultation note.', error);
    }; 

}; 

export const getConsultationWithPin = async (consultationId: any, pin: string) => { 

    try {

        const content = { 
            pin: pin 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/access/${consultationId}`, content);

        return res; 

    } catch (error) {
        console.error('Error when trying to access a consultation.', error);
    };

}; 


// -- 

export const saveConsultationNote = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/save`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error saving a consultation note.', error);
    }; 

}; 

// -- 

export const duplicateTemplate = async (consultationNoteId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/duplicate/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error duplicating a template:', error);
    }
};

// -- 

export const getNoteFields = async (consultationNoteId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/fields/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving note fields', error);
    }; 

}; 

// -- 

export const getNoteFieldsWithPin = async (consultationNoteId: any, securityPin: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/note/fields/${consultationNoteId}/${securityPin}`);

        return res; 
        
    } catch (error) {
        console.error('Error retrieving note fields with PIN', error);
    }; 

}; 

// -- 

export const deleteConsultationNote = async (consultationNoteId: string) => {

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a consultation note:', error);
    }; 
};

// --- 

// ---

// --- 

// --- Consultation Files 

// -- 

// -- 

// -- 

// -- 

// -- 

export const getAttachedFiles = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/files/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation files.', error);
    }; 

};  

// -- 

export const attachFileToConsultation = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/file/attach`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error attaching a file to the consultation.', error);
    }; 

}; 

export const editAttachedFile = async (fileId: any, title: any) => { 

    try {

        const content = { 
            title: title
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/file/edit/${fileId}`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error editiing a file.', error);
    }; 

}; 


// -- 

export const deleteAttachedFile = async (attachedFileId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/file/${attachedFileId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting an attached file.', error);
    }; 

}; 


// ---

// --- 

// --- Consultation Images 

// -- 

// -- 

// -- 

export const serveConsultationImage = async (imageId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/image/${imageId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving an image.', error);
    }; 

};  

// -- 

export const getConsultationImages = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/images/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation images.', error);
    }; 

};  

// -- 

export const uploadImageToConsultation = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/image`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error attaching an images to the consultation.', error);
    }; 

}; 

// -- 

export const uploadImageWithPin = async (formData: any, consultationId: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/image/${consultationId}`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading an image with PIN', error);
    }; 

}; 

// -- 

export const uploadNotePreview = async (formData: any, consultationNoteId: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/preview/${consultationNoteId}`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading a note preview', error);
    }; 

}; 

// -- 

export const deleteConsultationImage = async (imageId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/image/${imageId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting an attached image.', error);
    }; 

}; 
