import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import {  PDFDocument, StandardFonts, rgb  } from 'pdf-lib';
import { useNavigate, useParams } from 'react-router-dom';

// Redux 

// Components 

import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutIcon from '@mui/icons-material/Logout';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../redux/features/general/popup';
import { deleteConsultationNote, getConsultationNote, saveConsultationNote, uploadNotePreview } from '../../../../routes/doctor/consultation';
import { resetConsultationNote, setIsCurrentlySaving, setIsSavingEnabled } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import CircularProgress from '@mui/material/CircularProgress';
import { setIsNewNoteCreated, setIsNewTemplateCreated } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';

const SavingOptions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 
    const { t } = useTranslation(); 

    const { consultationId } = useParams(); 

    const textComponent = document.getElementById('consultationNote-textField') as any;

    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const isSavingEnabled = useAppSelector(state => state.consultationNote.isSavingEnabled); 
    const mode = useAppSelector(state => state.consultationNote.mode); 
    const type = useAppSelector(state => state.consultationNote.type); 
    const title = useAppSelector(state => state.consultationNote.title); 
    const originalTitle = useAppSelector(state => state.consultationNote.originalTitle); 
    const titleError = useAppSelector(state => state.consultationNote.titleError); 
    const isTextModified = useAppSelector(state => state.consultationNote.isTextModified); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 
    const isCurrentlySaving = useAppSelector(state => state.consultationNote.isCurrentlySaving); 

    // -- 

    const handleQuit = async () => { 

        if (isSavingEnabled) { 

            dispatch(setPopupAction('exitConsultationNote')); 
            dispatch(setOpenPopup()); 

        } else { 

            const res = await getConsultationNote(consultationNoteId) as any; 
    
            if (res.data.status === 200) { 
    
                if (!res.data.consultationNote.fileUrl) { 
    
                    await deleteConsultationNote(consultationNoteId) as any; 
    
                };
    
                dispatch(resetConsultationNote()); 
    
                navigate(`/dashboard/consultation/${consultationId}`);
    
            } else { 
    
                dispatch(resetConsultationNote()); 
    
                navigate(`/dashboard/consultation/${consultationId}`);
    
            }; 

        }; 

    }; 

    // -- 

    const handleSaveNote = async () => { 

        if (isCurrentlySaving) { 
            return; 
        }; 

        dispatch(setIsCurrentlySaving(true)); 

        const response = await getConsultationNote(consultationNoteId); 

        if (response?.data.status === 200) { 

            const documentComponent = document.getElementById('consultationNote-pdf') as any;     

            if (type === 'template') { 

                const canvas = await html2canvas(documentComponent, {
                    backgroundColor: '#ffffff', 
                    onclone: (clonedDocument) => {
                      Array.from(clonedDocument.querySelectorAll('textarea')).forEach((textArea: any) => {
                        const div = clonedDocument.createElement('div')
                        div.innerText = textArea.value
                        // div.style.border = '1px solid #ffffff'
                        div.style.color = '#000000'
                        // div.style.padding = '0 10px'
                        div.style.backgroundColor = '#ffffff'
                        // div.style.height = '860px'
                        // div.style.width = '560px'
                        textArea.style.backgroundColor = '#ffffff'
                        textArea.style.display = 'none'
                        textArea.parentElement.append(div)
                      })
                    },
                });
        
                const imgData = canvas.toDataURL('image/png');

                const blob = await (await fetch(imgData)).blob(); 
        
                // const buffer = dataUriToBuffer(imageUrl) as any; 

                const originalFileName = `preview_${consultationNoteId.slice(0, 20)}_${Math.floor(Math.random() * (100000 - 1 + 1) + 1)}.png`; 
        
                const newFile = new File([blob], originalFileName, { type: 'image/png' });
        
                const formData = new FormData() as any; 
        
                formData.append('consultationId', consultationId); 
                formData.append('securityPin', securityPin); 
                formData.append('file', newFile); 

                dispatch(setIsCurrentlySaving(false)); 
        
                const res = await uploadNotePreview(formData, consultationNoteId) as any; 

            }; 

            const pdfDoc = await PDFDocument.create();            
    
            const page = pdfDoc.addPage([620, 877]);

            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            const { width, height } = page.getSize(); 

            const formattedDescription = response.data.consultationNote.description.replaceAll('\n\n', '\n \n');
    
            page.drawText(formattedDescription, {
                x: 40,
                y: height - 50,
                size: 12,
                font: helveticaFont,
                maxWidth: 540, 
                color: rgb(0, 0, 0),
            }); 
    
            const pdfBytes = await pdfDoc.save();
    
            const originalFileName = `note_${consultationNoteId.slice(0, 20)}_${Math.floor(Math.random() * (100000 - 1 + 1) + 1)}.pdf`; 
    
            const newFile = new File([pdfBytes], originalFileName, { type: "application/pdf" });
    
            const formData = new FormData() as any; 
    
            formData.append('file', newFile); 
            formData.append('consultationNoteId', consultationNoteId); 
            formData.append('title', title); 
    
            const res = await saveConsultationNote(formData) as any;
    
            if (res.status === 200) {
    
                dispatch(setIsCurrentlySaving(false)); 
    
                dispatch(resetConsultationNote()); 

                if (type === 'template') { 

                    dispatch(setIsNewTemplateCreated(true)); 

                } else { 

                    dispatch(setIsNewNoteCreated(true)); 
    
                }; 
    
                navigate(`/dashboard/consultation/${consultationId}`); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'saveConsultationNote') { 

            if (isSavingEnabled) { 

                handleSaveNote(); 

            }; 

        }; 

    },[mode]); 

    // -- 

    useEffect(() => { 

        if (!titleError) {
            
            if (isTextModified) { 

                dispatch(setIsSavingEnabled(true)); 

            } else if (title && (title !== originalTitle)) { 

                (textComponent?.value) ? dispatch(setIsSavingEnabled(true)) : dispatch(setIsSavingEnabled(false));  

            } else { 

                dispatch(setIsSavingEnabled(false)); 

            }; 

        } else { 

            dispatch(setIsSavingEnabled(false)); 

        }; 

    },[isTextModified, titleError, textComponent?.value]); 

    // -- 

    return (

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
        }}>

            {isCurrentlySaving && <CircularProgress size={15} />}
        
            <Box
                onClick={handleSaveNote}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    padding: 1, 
                    fontSize: 12, 
                    marginLeft: 3, 
                    borderRadius: 1, 
                    marginRight: 2, 
                    height: 30,
                    color: colors?.text, 
                    cursor: isSavingEnabled ? 'pointer' : 'default', 
                    backgroundColor: isSavingEnabled ? colors?.navbarToolEnabled : colors?.navbarToolDisabled, 
                    '&:hover': { backgroundColor: isSavingEnabled ? colors?.navbarToolEnabledHover : colors?.navbarToolDisabled }
                }}
            >

                <Box>{(type === 'template') ? t('saveTemplate') : t('saveConsultationNote')}</Box>

                <DownloadIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

            </Box>

            <Box
                onClick={handleQuit}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    padding: 1, 
                    fontSize: 12, 
                    marginLeft: 1, 
                    borderRadius: 1, 
                    marginRight: 2, 
                    height: 30,
                    color: colors?.text, 
                    cursor: 'pointer', 
                    backgroundColor: colors?.consultationQuit, 
                    '&:hover': { backgroundColor: colors?.consultationQuitHover }
                }}
            >

                <LogoutIcon sx={{ fontSize: 16 }} /> 

            </Box>

        </Box>

  );

};

export default SavingOptions;