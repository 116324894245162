import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    icon: React.ReactNode; 
    title: string; 
    handler: any; 
    extraIcon?: React.ReactNode; 
}; 

const SidebarRow: React.FC<Props> = ({ icon, title, handler, extraIcon }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

        <Box
            onClick={handler}
            sx={{ 
                display: 'flex',
                cursor: 'pointer', 
                flexDirection: 'row', 
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 1, 
                width: '95%',
                borderRadius: 2, 
                padding: 1, 
                fontSize: 14, 
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}
        >
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >

                {icon}

                <Box sx={{ marginLeft: 1}}>{t(title)}</Box>

            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >

                {extraIcon}

            </Box>



        </Box>

  );

};

export default SidebarRow;