import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Button from '../../../../general/inputs/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const GenerateNote: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const createNote = async () => { 


    }; 

    return (

      <Box 
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '95%',
          bottom: '5%', 
          position: 'absolute'
      }}>

        <Button 
          title='generateNote'
          icon={<AutoAwesomeIcon sx={{ color: 'white', fontSize: 18 }} />}
          triggerFunction={createNote}
        />

      </Box>

  );

};

export default GenerateNote;