import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { any } from 'prop-types';

// ---------------
 
interface DoctorsAccessState {
  groups: any[],
  selectedGroup: any, 
  isRefreshGroups: boolean, 
  initialRendering: boolean, 
}

// ---------------

const initialState: DoctorsAccessState = {
    groups: [], 
    selectedGroup: null, 
    isRefreshGroups: false, 
    initialRendering: true, 
};

// ---------------


export const groupsAccessSlice = createSlice({

  name: 'groupsAccess',

  initialState,

  reducers: {

    setGroups: (state, action: PayloadAction<any>) => { 

        state.groups = action.payload; 

    },
    setSelectedGroup: (state, action: PayloadAction<any>) => { 

      state.selectedGroup = action.payload; 

    },
    setIsRefreshGroups: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshGroups = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetGroupsAccess: (state) => { 
        
      state.groups = []; 
      state.selectedGroup = null;
      state.isRefreshGroups = false;
      state.initialRendering = true; 

    } 

  },

}); 

// ---------------

export const { 
  setGroups, 
  setSelectedGroup, 
  setIsRefreshGroups, 
  setInitialRendering, 
  resetGroupsAccess,
} = groupsAccessSlice.actions;

export default groupsAccessSlice.reducer;