import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setIsOptionsListOpened, setLanguage, setMode } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import { editConsultationNote } from '../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../redux/features/general/notification';

interface Props { 
  children: React.ReactNode; 
  action: string; 
  iconColor: string; 
  isNavigation?: boolean; 
  customRightComponent?: any; 
}

const OptionRow: React.FC<Props> = ({ children, action, iconColor, isNavigation, customRightComponent }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const { i18n } = useTranslation();

    const { consultationId, consultationNoteId, securityPin } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const noteFields = useAppSelector(state => state.noteMicrophone.noteFields); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const mode = useAppSelector(state => state.noteMicrophone.mode); 

    // -- 

    const handleOption = async () => { 

      if (action === 'camera') { 

        navigate(`/camera/${consultationId}/${securityPin}`); 

        dispatch(setIsOptionsListOpened(false)); 

      } else if (action === 'startAutoComplete') { 

        const content = { 
          consultationNoteId: consultationNoteId, 
          mode: 'autoComplete', 
          securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setMode('autoComplete')); 

        dispatch(setIsOptionsListOpened(false)); 

      } else if (action === 'stopAutoComplete') { 

        const content = { 
          consultationNoteId: consultationNoteId, 
          mode: 'speaking', 
          securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setMode('speaking')); 

      } else if (action === 'saveConsultationNote') { 

        const content = { 
          consultationNoteId: consultationNoteId, 
          mode: 'saveConsultationNote', 
          securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setIsOptionsListOpened(false)); 

      } else if (action === 'resetText') { 

        const content = { 
          consultationNoteId: consultationNoteId, 
          mode: 'resetText', 
          securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setNotificationMessage(t('textReset'))); 

      } else if (action === 'language') { 

        (language === 'en-US') && dispatch(setLanguage('fr-FR')); 
        (language === 'fr-FR') && dispatch(setLanguage('en-US')); 

      }; 

    }; 

    // -- 

    return (

      <Box 
        onClick={handleOption}
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100vw',
            paddingLeft: 2, 
            paddingRight: 2, 
            height: 70, 
            backgroundColor: colors?.container, 
        }}>

        <Box
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
          }}
        >

          <Box
            sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: 35, 
              height: 35,
              color: 'white', 
              backgroundColor: iconColor, 
              borderRadius: '50%', 
            }}
          >
            {children}
          </Box>

          <Box 
            sx={{ 
              marginLeft: 2, 
            }}
          >{t(action)}
          </Box>

        </Box>

        {isNavigation ? 
        
        <Box
          sx={{ 
            width: 25, 
            fontSize: 25,
            color: colors?.text
          }}
        >
          <ChevronRightIcon /> 

        </Box>:
        <Box
          sx={{ 
            color: colors?.text
          }}
        >{customRightComponent}
        </Box>}

      </Box>

  );

};

export default OptionRow;