import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConsultationNoteState {
    redirectTo: string; 
    groupAccessList: any[], 
}; 

const initialState: ConsultationNoteState = {
    redirectTo: '',
    groupAccessList: [], 
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setRedirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload;
    },
    setGroupAccessList: (state, action: PayloadAction<any>) => {
      state.groupAccessList = action.payload;
    },
    resetLogin: (state) => { 
        
      state.redirectTo = '';

    } 
  },
});

export const { 
  setRedirectTo, 
  setGroupAccessList, 
  resetLogin } = loginSlice.actions;

export default loginSlice.reducer;