import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField, setErroredCategory, setErroredField, setEditedPatientChange, removeErroredCategory } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setLanguagePreference, setPrimaryDoctor, setAdvancedDirectives, setNotes, setSection, setCompletedField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputHeader from './items/InputHeader';
import BottomSpace from './items/BottomSpace';
import InputRow from './items/InputRow';
import PublicIcon from '@mui/icons-material/Public';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import NotesIcon from '@mui/icons-material/Notes';

// Styles 

import iconStyle from './styles/iconStyle';
import iconBoxStyle from './styles/iconBoxStyle';
import inputPropsStyle from './styles/inputPropsStyle';
import textFieldStyle from './styles/textFieldStyle';

// Options 

import langOptions from './options/langOptions';

const ExtraInformation: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const editedPatient = useAppSelector(state => state.createPatient.editedPatient); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);
    const nextSection = useAppSelector((state) => state.createPatient.nextSection);

    const languagePreference  = useAppSelector((state) => state.createPatient.languagePreference ); 
    const primaryDoctor = useAppSelector((state) => state.createPatient.primaryDoctor); 
    const advancedDirectives = useAppSelector((state) => state.createPatient.advancedDirectives); 
    const notes = useAppSelector((state) => state.createPatient.notes); 

    const [languagePreferenceError, setLanguagePreferenceError] = useState(''); 
    const [primaryDoctorError, setPrimaryDoctorError] = useState(''); 
    const [advancedDirectivesError, setAdvancedDirectivesError] = useState(''); 
    const [notesError, setNotesError] = useState(''); 

    const [isLangPreferenceFocused, setIslangPreferenceFocused] = useState(false); 

    const languagePreferenceRef = useRef() as any;
    const primaryDoctorRef = useRef() as any;
    const advancedDirectivesRef = useRef() as any;
    const notesRef = useRef() as any;

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'languagePreference') { 

            dispatch(setLanguagePreference({ _id: input, stringDisplayed: input })); 

            dispatch(removeErroredField('languagePreference'));

        } else if (section === 'primaryDoctor') { 

            dispatch(setPrimaryDoctor(input)); 

            dispatch(removeErroredField('primaryDoctor'));

        } else if (section === 'advancedDirectives') { 

            dispatch(setAdvancedDirectives(input)); 

            dispatch(removeErroredField('advancedDirectives'));

        } else if (section === 'notes') { 

            dispatch(setNotes(input)); 

            dispatch(removeErroredField('notes'));

        };

        dispatch(setEditedPatientChange(true)); 

    };

    // -- 

    const confirmInput = (key: string, field: string) => {

        if (key == 'Enter') { 

            if (field === 'languagePreference') { 

                primaryDoctorRef.current.focus(); 

            } else if (field === 'primaryDoctor') { 

                notesRef.current.focus(); 
    
            } else if (field === 'notes') { 
    
                if (section !== 'confirmation') { 

                    dispatch(setSection(nextSection)); 

                };
    
            };

        } else if (key == 'Backspace') { 

            if (field === 'languagePreference') { 

                dispatch(setLanguagePreference(null)); 

            }; 

        }; 

    };

    // -- 

    useEffect(() => { 

        if (erroredFields.length > 0) { 

            setLanguagePreferenceError('');
            setPrimaryDoctorError('');
            setPrimaryDoctorError('');
            setNotesError('');

            const fields = ['languagePreference', 'primaryDoctor', 'advancedDirectives', 'notes'];

            const sectionErrors = erroredFields.filter((item: any) => { 

                if (fields.includes(item.field)) { 

                        return item; 
                }; 

            }); 

            (sectionErrors?.length > 0) ? dispatch(setErroredCategory('extraInformation')) : dispatch(removeErroredCategory('extraInformation')); 

            erroredFields.forEach((item: any) => { 

                if (item.field === 'languagePreference') { 

                    setLanguagePreferenceError(item.error); 

                } else if (item.field === 'primaryDoctor') { 

                    setPrimaryDoctorError(item.error); 

                } else if (item.field === 'advancedDirectives') { 

                    setAdvancedDirectivesError(item.error); 

                } else if (item.field === 'notes') { 

                    setNotesError(item.error); 

                };

            });

        }; 

    },[erroredFields]); 

    // --

    // -- 

    useEffect(() => { 

        if (document.activeElement === languagePreferenceRef.current) { 

            setIslangPreferenceFocused(true); 

        } else { 

            setIslangPreferenceFocused(false); 

        }; 

    },[document.activeElement, languagePreferenceRef]);

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: section === 'confirmation' ? 'auto' : '95%',
        }}>

            <InputHeader title={'languagePreference'} /> 

            <InputRow 
                icon={<PublicIcon sx={iconStyle} /> } 
                iconColor='rgb(45, 207, 196)' 
                dataList={langOptions} 
                value={languagePreference}
                action='languagePreference' 
                isFocused={isLangPreferenceFocused} 
                error={languagePreferenceError}>

                <TextField
                    value={languagePreference ? languagePreference?.stringDisplayed : ''}
                    placeholder={String(t('pressSelectToContinue'))}
                    onChange={(e) => handleInput(e.target.value, 'languagePreference')}
                    variant="standard"
                    autoComplete='off'
                    autoFocus
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'languagePreference')}
                    inputRef={languagePreferenceRef}
                />
            </InputRow>

            <InputHeader title={'primaryDoctor'} /> 

            <InputRow 
                icon={<AssignmentIndIcon sx={iconStyle} /> } 
                value={primaryDoctor}
                iconColor='rgb(207, 137, 45)' 
                error={primaryDoctorError}>

                <TextField
                    value={primaryDoctor}
                    placeholder={String(t('primaryDoctor'))}
                    onChange={(e) => handleInput(e.target.value, 'primaryDoctor')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'primaryDoctor')}
                    inputRef={primaryDoctorRef}
                />
            </InputRow>

            {/* <InputHeader title={'advancedDirectives'} />  */}

            {/* <InputRow icon={<SpeakerNotesIcon sx={iconBoxStyle} /> } iconColor='rgb(45, 207, 62)' isMultiline>
                <TextField
                    value={advancedDirectives}
                    placeholder={String(t('advancedDirectives'))}
                    onChange={(e) => handleInput(e.target.value, 'advancedDirectives')}
                    error={advancedDirectivesError ? true : false}
                    helperText={t(advancedDirectivesError)}
                    variant="standard"
                    autoComplete='off'
                    multiline
                    rows={5}
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'advancedDirectives')}
                    inputRef={advancedDirectivesRef}
                />
            </InputRow> */}

            { !editedPatient && <InputHeader title={'notes'} />} 

            { !editedPatient && 
            
                <InputRow 
                    icon={<NotesIcon sx={iconBoxStyle} /> } 
                    iconColor='rgb(188, 45, 207)' 
                    isMultiline 
                    value={notes}
                    error={notesError}>

                    <TextField
                        value={notes}
                        placeholder={String(t('notes'))}
                        onChange={(e) => handleInput(e.target.value, 'notes')}
                        variant="standard"
                        multiline
                        rows={5}
                        autoComplete='off'
                        InputProps={inputPropsStyle}
                        sx={textFieldStyle}
                        onKeyDown={(e) => confirmInput(e.key, 'notes')}
                        inputRef={notesRef}
                    />
                </InputRow>}

            <BottomSpace /> 
            
        </Box>
    );
};

export default ExtraInformation;