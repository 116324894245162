import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Utils 

import SettingsRow from './SettingsRow';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { setIsNavigationDropdownOpened } from '../../../../redux/features/general/navigation';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import moment from 'moment';
import { setCurrentTime } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';

type Props = {
    children?: React.ReactNode
};

const Settings: React.FC<Props> = ({children}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 
    const location = useLocation();

    const colors = useAppSelector(state => state.theme.colors);
    const groupAccess = useAppSelector(state => state.group.groupAccess); 

    const isNavigationDropdownOpened = useAppSelector(state => state.navigation.isNavigationDropdownOpened);

    const [timeLeft, setTimeLeft] = useState(2); 

    const [navbarTime, setNavbarTime] = useState(''); 

    // -- 

    const handleDropdown = () => { 

        dispatch(setIsNavigationDropdownOpened(!isNavigationDropdownOpened)); 

    }; 

    // -- 

    const handleLockScreen = () => { 

        localStorage.setItem('isScreenLocked', 'on');

        navigate('/lock/screen'); 

    }; 

    // -- 

    useEffect(() => {

        dispatch(setIsNavigationDropdownOpened(false)); 

    },[location.pathname]); 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 

                setNavbarTime(moment.utc(date).format('LLLL')); 

                setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    
    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space',
                position: 'absolute', 
                right: '2vw', 
            }}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 2,
                    fontSize: 12, 
                    color: 'rgb(150, 150, 150)'
                }}
            >{navbarTime}
            </Box>

            <ShieldMoonIcon 
                onClick={handleLockScreen}
                sx={{ 
                    color: 'rgb(100, 100, 100)',
                    marginRight: 2,
                    cursor: 'pointer',
                    '&:hover': { color: colors?.mainHover }
                }} 
            /> 

            <AccountCircleIcon 
                onClick={handleDropdown}
                sx={{ 
                    cursor: 'pointer', 
                    color: isNavigationDropdownOpened ? colors?.main : 'rgb(100, 100, 100)',
                    '&:hover': { color: colors?.mainHover }
                }} />
            
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'space-evenly',
                        width: 200,
                        height: isNavigationDropdownOpened ? 200 : 0,
                        top: 30, 
                        right: 0, 
                        position: 'absolute',
                        backgroundColor: colors?.fileExplorerDropdown,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                        borderRadius: 4,
                        zIndex: 10000000, 
                        transition: 'height 0.3s',
                    }}
                >

                    {isNavigationDropdownOpened && 
                    
                    <>

                        <SettingsRow section={'dashboard'} >
                            <DashboardOutlinedIcon /> 
                        </SettingsRow>

                        {groupAccess?.fullAccess && 
                        <SettingsRow section={'group'} customTitle={'groupPortal'}>
                            <BubbleChartOutlinedIcon /> 
                        </SettingsRow>}

                        <SettingsRow section={'support'} >
                            <SupportAgentOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'legal'} >
                            <PolicyOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'settings'} >
                            <SettingsOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'logout'} >
                            <LogoutOutlinedIcon /> 
                        </SettingsRow>

                    </>}

                </Box>

        </Box>

    );
};

export default Settings;