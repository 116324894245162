import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface GroupAccessState {
  groupAccessList: any[],
  selectedGroupAccess: any, 
  isRefreshGroupAccessList: boolean, 
  initialRendering: boolean,
}

// ---------------

const initialState: GroupAccessState = {
    groupAccessList: [], 
    selectedGroupAccess: null, 
    isRefreshGroupAccessList: false, 
    initialRendering: true, 
};

// ---------------


export const groupAccessSlice = createSlice({

  name: 'groupAccess',

  initialState,

  reducers: {

    setGroupAccessList: (state, action: PayloadAction<any>) => { 

        state.groupAccessList = action.payload; 

    },
    setSelectedGroupAccess: (state, action: PayloadAction<any>) => { 

      state.selectedGroupAccess = action.payload; 

    },
    setIsRefreshGroupAccessList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshGroupAccessList = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetDoctorsAccess: (state) => { 
        
      state.groupAccessList = []; 
      state.selectedGroupAccess = null;
      state.isRefreshGroupAccessList = false;
      state.initialRendering = true; 
    } 

  },

}); 

// ---------------

export const { 
  setGroupAccessList, 
  setSelectedGroupAccess, 
  setIsRefreshGroupAccessList, 
  setInitialRendering, 
  resetDoctorsAccess } = groupAccessSlice.actions;

export default groupAccessSlice.reducer;