import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from './../../../redux/hooks';

// Redux 

import { setDashboardSection } from '../../../redux/features/general/navigation';

// Components 

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';


type Props = {
    children?: React.ReactNode;
    key: any,
    item: any, 
    index: number,
    srcUrl: string; 
    hidden?: boolean,
    sidebarWidth: number; 
  };


const SidebarItem: React.FC<Props> = ({ children, item, index, srcUrl, hidden, sidebarWidth }) => {

    const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.navigation.section); 

    const [iconUrl, setIconUrl] = useState(''); 

    // -- 

    const changeSection = (section: any) => { 

        if (section) { 

            dispatch(setDashboardSection(section)); 

        };

    }; 

    // -- 

    useEffect(() => { 

      item.text === 'docs' && setIconUrl(require('../../../assets/icons/navigation/documents.png')); 
      item.text === 'patients' && setIconUrl(require('../../../assets/icons/navigation/patients.png')); 
      item.text === 'templates' && setIconUrl(require('../../../assets/icons/navigation/templates.png')); 
      item.text === 'charting' && setIconUrl(require('../../../assets/icons/navigation/charting.png')); 
      item.text === 'theme' && setIconUrl(require('../../../assets/icons/navigation/theme.png')); 
      item.text === 'account' && setIconUrl(require('../../../assets/icons/navigation/account.png')); 
      item.text === 'help' && setIconUrl(require('../../../assets/icons/navigation/help.png')); 
      item.text === 'history' && setIconUrl(require('../../../assets/icons/navigation/history.png')); 
      item.text === 'hackers' && setIconUrl(require('../../../assets/icons/navigation/hackers.png')); 
      item.text === 'doctors' && setIconUrl(require('../../../assets/icons/navigation/doctors.png')); 
      item.text === 'tickets' && setIconUrl(require('../../../assets/icons/navigation/tickets.png')); 
      item.text === 'data' && setIconUrl(require('../../../assets/icons/navigation/data.png')); 
      item.text === 'group' && setIconUrl(require('../../../assets/icons/navigation/group.png')); 
      item.text === 'groups' && setIconUrl(require('../../../assets/icons/navigation/groups.png')); 
      item.text === 'create' && setIconUrl(require('../../../assets/icons/navigation/add.png')); 

    },[item]); 

    // -- 

    return (

        <>

        { !item?.hidden && 
        <Box
          onClick={() => changeSection(item.section)} 
          key={index}
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '75%', 
            height: item.type === 'item' ? 100 : 10,
            borderRadius: 4,
            marginTop: 1, 
            cursor: item.type === 'header' ? 'auto' : 'pointer',
            backgroundColor: section === item.section ? colors?.sidebarSelectedItemBackground : 'transparent', 
            color: section === item.section ? colors?.sidebarItemSelectedSection : colors?.sidebarUnselectedItem,
            '&:hover': { backgroundColor: section === item.section ? colors?.sidebarSelectedItemBackground : colors?.sidebarItemHover }
          }}
        >

          { item.type === 'item' && 
            <Box
                component="img"
                sx={{
                    width: 35,
                    paddingTop: 1, 
                }}
                src={iconUrl}
            />}

          { (item.type === 'item' && ((window?.innerWidth * 0.05) >= 50)) && 
          <Box
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: '100%', 
              fontSize: 11,
              paddingLeft: 2, 
              paddingRight: 2, 
              color: section === item.section ? colors?.highlightedText: colors?.text
            }}
          >{t(item.text)}</Box>}

        </Box>}

        </>

    );

};

export default SidebarItem;