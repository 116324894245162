import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateGroupAccessState {
  firstName: string,
  lastName: string,
  email: string,
  type: string, 
  securityPin: string; 
  isCreateAccountOpened: boolean; 
}

// ---------------

const initialState: CreateGroupAccessState = {
    firstName: '', 
    lastName: '',
    email: '',
    type: '', 
    securityPin: '', 
    isCreateAccountOpened: false, 
};

// ---------------


export const createGroupAccessSlice = createSlice({

  name: 'createGroupAccess',

  initialState,

  reducers: {

    setFirstName: (state, action: PayloadAction<string>) => {

        state.firstName = action.payload; 
    },
    setLastName: (state, action: PayloadAction<string>) => { 
        
        state.lastName = action.payload;  
    },
    setEmail: (state, action: PayloadAction<string>) => { 
        
        state.email = action.payload; 
    },
    setType: (state, action: PayloadAction<string>) => { 
      state.type = action.payload; 
    },
    setSecurityPin: (state, action: PayloadAction<string>) => { 
        
      state.securityPin = action.payload; 
    }, 
    setIsCreateGroupAccessOpened: (state, action: PayloadAction<boolean>) => { 
      state.isCreateAccountOpened = action.payload; 
    },  
    resetCreateGroupAccess: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.type = ''; 
        state.securityPin = ''; 
        state.isCreateAccountOpened = false; 
    } 

  },

}); 

// ---------------

export const { 
    setFirstName, 
    setLastName, 
    setEmail, 
    setType, 
    setSecurityPin, 
    setIsCreateGroupAccessOpened, 
    resetCreateGroupAccess } = createGroupAccessSlice.actions;

export default createGroupAccessSlice.reducer;