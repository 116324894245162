import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

interface Props { 
    option: any
}; 

const OptionRow: React.FC<Props> = ({ option }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);


    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%',
            minHeight: 40, 
            borderRadius: 3, 
            color: colors?.text,
            zIndex: 15,
            '&:hover': { backgroundColor: colors?.patientSectionHover }
        }}>

            <Box sx={{ marginLeft: 2}}>{option}</Box>

        </Box>

    );

};

export default OptionRow;