import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Microphone: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          width: '50%',
          height: '15%',
          borderRadius: 2,  
          top: '1%', 
          left: 0, 
          position: 'absolute', 
          backgroundColor: colors?.container,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}>

          <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                width: 60,
                height: 60,
                marginLeft: 3, 
                borderRadius: '50%', 
                borderStyle: 'solid',
                borderWidth: 1, 
                borderColor: colors?.main,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            }}
        >
            <MicNoneIcon sx={{ color: colors?.main, fontSize: 40 }} />

        </Box>

        <Box
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            color: 'rgb(150, 150, 150)', 
            marginLeft: 2 
          }}
        >{t('startRecordingInfo')}
        </Box>

        <MoreVertIcon 
          sx={{ 
            color: colors?.main,
            marginRight: 3,
            right: 0,  
            fontSize: 30, 
            position: 'absolute', 
          }}
        />


      </Box>

  );

};

export default Microphone;