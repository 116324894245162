import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const Statements: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [text, setText] = useState(''); 

    // --

    const onTextChange = (e: any) => { 

        setText(e.target.value); 

    }; 

    // --

    const onKeyChange = () => { 


    }; 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '95%',
            borderRadius: 2,  
            padding: 0.5,
            backgroundColor: colors?.container,
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '100%',
                    color: colors?.text,
                    marginTop: 2, 
                    marginBottom: 1, 
                    fontWeight: '200'
                }}
            >{t('additionalStatements')}

            </Box>

            <TextField
                id="note-additional-statements"
                variant="standard"
                value={text}
                autoFocus
                autoComplete='off'
                multiline={true}
                sx={{ 
                    width: '100%',
                    height: 150, 
                    maxHeight: '15vh', 
                    borderStyle: 'solid',
                    borderColor: 'rgb(180, 180, 180)', 
                    borderRadius: 3, 
                    borderWidth: 1,
                    padding: 1, 
                }}
                inputProps={{ 
                    style: { 
                        textAlign: 'left',
                        textDecorationLine: 'none',
                        fontSize: 16, 
                        color: colors?.text,
                        fontWeight: 'normal', 
                        fontStyle: 'normal', 
                        overflow: 'none'
                    }
                }}
                InputProps={{
                    disableUnderline: true, 
                }}
                onChange={onTextChange}
                onKeyDown={onKeyChange} 
            />

    </Box>

  );

};

export default Statements;