import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// -- MUI
import Box from '@mui/material/Box';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { TextField, Button } from '@mui/material';

// -- Routes
import { requestAuthCode, resendAuthCode, verifyAuthCode } from '../../../routes/general/authentication';

// -- Redux 
import { setNotificationMessage } from '../../../redux/features/general/notification';
import { setAccountType, setAccountInfo } from '../../../redux/features/general/meta';
import { setGroupAccessList } from '../../../redux/features/auth/login';

const Auth: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { groupAccessId, consultationNoteId } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [email, setEmail] = useState(''); 
    const [showResendCode, setShowResendCode] = useState(false); 
    const [allowSubmit, setAllowSubmit] = useState(false); 
    const [isCodeSent, setIsCodeSent] = useState(false); 
    const [error, setError] = useState(false); 
    const [errorMessage, setErrorMessage] = useState(''); 
    const [loginAttemptId, setLoginAttemptId] = useState(''); 
    const [verificationCode, setVerificationCode] = useState(''); 


    const goToSupport = () => { 
        navigate('/hacker/login');
    };

    // -- 

    const handleLogin = async () => { 

        if (!isCodeSent) { 

            const res = await requestAuthCode(email, 'doctor') as any; 

            if (res.data.status === 200) { 
    
                setLoginAttemptId(res.data.loginAttemptId); 
                setIsCodeSent(true);
                dispatch(setNotificationMessage(t('authCodeSent')));

                setError(false); 
                setErrorMessage(''); 

            } else { 

                dispatch(setNotificationMessage(t('invalidEmail')));
                setError(true); 
                setErrorMessage(String(t('invalidEmail'))); 

            }; 

        } else if (isCodeSent) { 

            const res = await verifyAuthCode(email, verificationCode, 'doctor', loginAttemptId, groupAccessId) as any; 

            if (res.data.status === 200) { 

                localStorage.setItem('isScreenLocked', 'off');

                const { token } = res.data;
                document.cookie = `jwt=${token}; path=/`;
                dispatch(setAccountType(res.data.userType)); 
                dispatch(setAccountInfo(res.data.account)); 
                setError(false); 
                setErrorMessage(''); 

                if (consultationNoteId) { 

                    navigate(`/consultation/note/mic/${consultationNoteId}`); 

                } else { 

                    navigate('/dashboard'); 

                }; 

            } else if (res.data.status === 202) {

                localStorage.setItem('isScreenLocked', 'off');

                const { token } = res.data;
                document.cookie = `jwt=${token}; path=/`;
                dispatch(setAccountType(res.data.userType)); 
                dispatch(setAccountInfo(res.data.account)); 

                dispatch(setGroupAccessList(res.data.groupAccessList)); 

                navigate('/login/group/auth/select'); 
                
            } else if (res.data.status === 401) { 

                if ((res.data.error === 'userNotFound') || (res.data.error === 'invalidLoginAttemptId')) { 

                    dispatch(setNotificationMessage(t('verificationCodeNotFound')));
                    setErrorMessage(String(t('verificationCodeNotFound'))); 
                    setError(true); 

                } else if (res.data.error === 'invalidCode') {

                    dispatch(setNotificationMessage(t('verificationCodeNotFound')));
                    setErrorMessage(String(t('verificationCodeNotFound'))); 
                    setError(true); 

                } else if (res.data.error === 'accountBlocked') { 

                    dispatch(setNotificationMessage(t('accountBlocked')));
                    setErrorMessage(String(t('accountBlocked'))); 
                    setError(true); 

                }; 

            } else { 

                dispatch(setNotificationMessage(t('verificationCodeNotFound')));
                setErrorMessage(String(t('verificationCodeNotFound'))); 
                setError(true); 

            }; 
        }; 
        setShowResendCode(true); 
    }; 

    // -- 

    const handleResendCode = async () => { 

        const res = await resendAuthCode(email, 'doctor', loginAttemptId) as any; 

        if (res.data.status === 200) { 

            setIsCodeSent(true);
            dispatch(setNotificationMessage(t('authCodeSent')));
            setError(false); 
            setErrorMessage(''); 

        } else if (res.data.status === 401) { 

            if ((res.data.error === 'userNotFound') || (res.data.error === 'invalidLoginAttemptId')) { 

                dispatch(setNotificationMessage(t('verificationCodeNotFound')));
                setErrorMessage(String(t('verificationCodeNotFound'))); 
                setError(true); 

            } else if (res.data.error === 'invalidCode') {

                dispatch(setNotificationMessage(t('verificationCodeNotFound')));
                setErrorMessage(String(t('verificationCodeNotFound'))); 
                setError(true); 

            } else if (res.data.error === 'accountBlocked') { 

                dispatch(setNotificationMessage(t('accountBlocked')));
                setErrorMessage(String(t('accountBlocked'))); 
                setError(true); 

            }; 

        } else { 

            dispatch(setNotificationMessage(t('invalidEmail')));
            setError(true); 
            setErrorMessage(String(t('invalidEmail'))); 

        }; 
        setShowResendCode(true); 
    }; 

    const handleInput = (key: string) => { 
        if (key === 'Enter') { 
            handleLogin(); 
            setShowResendCode(true); 
        }; 
    };

    const goBackToEmail = () => { 
        setIsCodeSent(false); 
        setVerificationCode(''); 
    }; 

    useEffect(() => { 
        if (email.length > 3 && email.includes('@')) { 
            setAllowSubmit(true); 
        } else { 
            setAllowSubmit(false); 
        }; 
    }, [email]); 

    useEffect(() => { 
        setShowResendCode(false); 
    }, [email, verificationCode]);

    // -- 

    useEffect(() => { 

        if (verificationCode?.length === 6) {

            handleLogin(); 

        }; 

    },[verificationCode]); 

    // -- 

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '20vw',
            marginTop: '5vh',
            height: '60vh', 
            right: '20vw',
            borderRadius: 3, 
            backgroundColor: 'rgba(62, 87, 104, 0.2)',
            zIndex: 5,
            position: 'absolute'
        }}>

            {/* Header */}
            { isCodeSent ? 
                <Box 
                    onClick={() => goBackToEmail()}
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'rgb(204, 204, 204)',
                        marginTop: '5vh',
                        height: '15vh',
                        flexShrink: 3,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'rgb(254, 254, 254)',
                        },
                    }}
                >   
                    <ArrowCircleLeftIcon sx={{ marginRight: 1 }}/>
                    <Box>{t('backToEmail')}</Box>
                </Box> :
                <Box sx={{ 
                    color: 'rgb(204, 204, 204)',
                    marginTop: '5vh',
                    height: '15vh',
                    flexShrink: 3
                }}>
                    {t('loginToContinue')}
                </Box>
            }

            {/* Email or Verification Code */}
            { !isCodeSent && 
                <TextField 
                    sx={{ width: '60%', marginBottom: '2vh', fontSize: 10, alignSelf: 'center' }} 
                    onChange={(e) => setEmail(e.target.value)}
                    id="standard-basic" 
                    label={t('email')} 
                    value={email}
                    variant="standard" 
                    error={error}
                    helperText={errorMessage}
                    autoFocus
                    onKeyDown={(e) => handleInput(e.key)}
                />
            }

            { isCodeSent && 
                <TextField 
                    sx={{ 
                        width: '60%', 
                        height: 60, 
                        fontSize: 18, 
                        alignSelf: 'center',
                        color: colors?.text,
                        borderBottomColor: 'black',
                        borderColor: 'rgb(10,197,207)',
                        borderStyle: 'solid',
                        borderRadius: 1, 
                        borderWidth: 1, 
                        marginBottom: 4
                    }} 
                    inputProps={{ 
                        style: { 
                            textAlign: 'center',
                            height: 50, 
                            fontSize: 20, 
                            textDecoration: 'none',
                            color: 'white',
                        }
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    id="standard-basic" 
                    // label={t('verificationCode')} 
                    value={verificationCode}
                    variant="standard" 
                    error={error}
                    helperText={errorMessage}
                    autoFocus
                    onKeyDown={(e) => handleInput(e.key)}
                />
            }

            {/* Resend Code */}
            { showResendCode ? 
                <Box
                    onClick={() => handleResendCode()}
                    sx={{ 
                        color: 'rgb(204, 204, 204)',
                        height: '8vh',
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#05E5D3',
                        },
                    }}>
                    {t('resendCode')}
                </Box> :
                <Box
                    sx={{ 
                        color: 'rgb(204, 204, 204)',
                        height: '8vh',
                    }}>
                </Box>
            }

            {/* Login Button */}
            { allowSubmit ? 
                <Button type="submit" onClick={() => handleLogin()} variant="contained" sx={{ width: '60%' }}>
                    {t('loginButton')}
                </Button> : 
                <Button 
                    disabled={true}
                    sx={{ 
                        width: '60%',
                        backgroundColor: 'transparent',
                        borderColor: 'lightblue',
                    }}
                    variant="outlined">
                    {t('loginButton')}
                </Button>
            }

            {/* Footer */}
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                color: 'rgb(120, 120, 120)',
                fontSize: 15,
                marginTop: '5vh',
                marginBottom: '2vh',
            }}>
                <Box sx={{ marginRight: 1 }}>{t('needHelp')}</Box>
                <Box
                    onClick={() => goToSupport()}
                    sx={{ 
                        color: 'rgb(9,109,108)', 
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#05E5D3',
                        },
                    }}>
                    {t('support')}
                </Box>.
            </Box>
        </Box>

    );
};

export default Auth;