import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Components 

import SubmitButton from './SubmitButton';
// import BackButton from './formItems/BackButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SecurityIcon from '@mui/icons-material/Security';
import FormField from '../../../general/inputs/FormField';
import { setFirstName, setLastName, setEmail, setSecurityPin } from '../../../../redux/features/doctor/group/createGroupAccess';

const CreateGroupAccess: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);  

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '95vh',
        width: '95vw',
        position: 'absolute',
        bottom: 0,
        right: 0, 
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        backgroundColor: colors?.interface,
        color: colors?.text, 
    }}>

        {/* <BackButton />  */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: 600,
            scrollbarWidth: 'none',
            backgroundColor: colors?.interface,
            borderRadius: 4,
            paddingTop: 2, 
            marginTop: 3
          }}
        >

          <FormField 
              icon={<AccountCircleIcon sx={{ color: colors?.lightBlue }} />}
              section={'firstName'}
              isFocused
              dispatchFunction={setFirstName}
          />

          <FormField 
              icon={<AccountCircleIcon sx={{ color: colors?.lastName }} />}
              section={'lastName'}
              dispatchFunction={setLastName}
          />

          <FormField 
              icon={<MarkEmailReadIcon sx={{ color: colors?.email }} />}
              section={'email'}
              dispatchFunction={setEmail}
          />

          <FormField 
              icon={<SecurityIcon sx={{ color: colors?.purple }} />}
              section={'securityPin'}
              helperText={'pinInfo'}
              dispatchFunction={setSecurityPin}
          />

          <SubmitButton />

        </Box>

    </div>

  );

};

export default CreateGroupAccess;