import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { updateDoctorPreferences } from '../../../routes/doctor/patients';
import { setNotificationMessage } from '../../../redux/features/general/notification';
import { setIsRefreshDoctorPreferences } from '../../../redux/features/doctor/Account/preferences';
import { setOpenPopup, setPopupAction } from '../../../redux/features/general/popup';

const SecurityPin: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const securityPin = useAppSelector(state => state.doctorPreferences.securityPin);

    const [newSecurityPin, setNewSecurityPin] = useState(''); 
    const [mode, setMode] = useState('initial'); 

    // -- 

    const textFieldStyle = { 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly', 
        width: 100, 
        height: 50, 
        fontSize: 18, 
        marginLeft: 4,
        alignSelf: 'center',
        color: colors?.text,
        borderBottomColor: 'black',
        borderColor: 'rgb(10,197,207)',
        borderStyle: 'solid',
        borderRadius: 1, 
        borderWidth: 1, 
    }; 

    // -- 

    const updateSecurityPin = async () => { 
  
        const content = { 
          securityPin: newSecurityPin
        }; 
    
        const res = await updateDoctorPreferences(content); 

        if (res?.data.status === 200) { 

            dispatch(setNotificationMessage(t('securityPinUpdated'))); 
            dispatch(setIsRefreshDoctorPreferences(true)); 

            setNewSecurityPin(''); 

            setMode('initial');

        }; 
    
    };

    // -- 

    const handleSecurityPin = (e: any) => { 

        const reg = new RegExp('^[0-9]+$'); 

        if ((!reg.test(e.target.value) || (e.target.value.length > 4)) && e.target.value) { 
            return; 
        }; 

        setNewSecurityPin(e.target.value); 

    }; 

    // -- 

    const handleSubmit = (e: any) => { 

        if ((e.key === 'Enter') && (newSecurityPin?.length === 4)) { 

            updateSecurityPin(); 
    
        }; 

    }; 

    // -- 

    const handlePinCreation = () => { 

        setMode('editPin'); 

    }; 

    // -- 

    const handlePinUpdate = () => { 

        setMode('editPin'); 

        setNewSecurityPin(securityPin);

    }; 

    // -- 

    const handleQuit = () => { 

        setMode('initial'); 
        setNewSecurityPin(''); 

    }; 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          width: '100%',
          paddingLeft: 10, 
          marginTop: 10, 
      }}>

        <Box
            component="img"
            sx={{
                width: 25,
            }}
            src={require('../../../assets/icons/settings/security-pin.png')}
        />

        {((mode === 'initial') && !securityPin) &&
        
        <Box
            onClick={handlePinCreation}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                padding: 1, 
                fontSize: 12, 
                marginLeft: 2, 
                borderRadius: 1, 
                marginRight: 2, 
                height: 30,
                color: 'white', 
                cursor: 'pointer', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: colors?.navbarToolEnabled, 
                '&:hover': { backgroundColor: colors?.navbarToolEnabledHover }
            }}
        >
            <Box>{t('createPin')}</Box>

        </Box>}

        {((mode === 'initial') && securityPin) &&
        
        <Box
            onClick={handlePinUpdate}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                padding: 1, 
                fontSize: 12, 
                marginLeft: 2, 
                borderRadius: 1, 
                marginRight: 2, 
                height: 30,
                color: 'white', 
                cursor: 'pointer', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: colors?.settingsPinUpdate, 
                '&:hover': { backgroundColor: colors?.settingsPinUpdateHover }
            }}
        >
            <Box>{t('updatePin')}</Box>

        </Box>}

        {(mode === 'editPin') && 

        <TextField 
            sx={textFieldStyle} 
            inputProps={{ 
                style: { 
                    textAlign: 'center',
                    height: 40, 
                    width: 100, 
                    fontSize: 20, 
                    textDecoration: 'none',
                    color: colors?.text,
                }
            }}
            InputProps={{ disableUnderline: true }}
            onChange={handleSecurityPin}
            onKeyDown={handleSubmit}
            autoComplete='off'
            id="settings-pin" 
            value={newSecurityPin}
            variant="standard" 
            autoFocus
        />}

        {(mode === 'editPin') && 

        <Box sx={{ 
            marginLeft: 2, 
            fontSize: 15, 
            color: 'grey' 
        }}>{t('pressEnterToConfirm')}</Box>}

        {(mode === 'editPin') && 
        <Box
            onClick={handleQuit}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                padding: 1, 
                fontSize: 12, 
                marginLeft: 3, 
                borderRadius: 1, 
                marginRight: 2, 
                height: 30,
                color: colors?.text, 
                cursor: 'pointer', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: colors?.consultationQuit, 
                '&:hover': { backgroundColor: colors?.consultationQuitHover }
            }}
        >
            <Box>{t('cancel')}</Box>

        </Box>}

        {(mode === 'initial' && securityPin) && 
        <Box sx={{ 
            marginLeft: 2, 
            fontSize: 15, 
            color: 'grey' 
        }}>{t('lockScreenInfo')}</Box>}

      </Box>

  );

};

export default SecurityPin;


