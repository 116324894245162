import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { getConsultation } from '../../../routes/doctor/consultation';
import { setConsultationId, setCurrentTime, setIsRefreshContent } from '../../../redux/features/doctor/Dashboard/consultation/consultation';
import Sidebar from './sidebar/Sidebar';
import moment from 'moment';
import Note from './note/Note';

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationId } = useParams() as any; 
    

    const colors = useAppSelector(state => state.theme.colors); 
    const consultation = useAppSelector((state) => state.consultation.consultationId);
    

    const [timeLeft, setTimeLeft] = useState(2);

    // -- 

    window.onbeforeunload = function (e: any) {

      if (consultation) { 
  
        // For IE and Firefox
  
        if (e) {
          e.returnValue;
        }; 
      
        // For Safari
        return e.returnValue;
  
      };
  
    };

    // --

    const handleConsultation = async () => { 
  
      const res = await getConsultation(consultationId) as any; 
  
      if (res.data.status === 200) { 
  
        if (res.data.consultation.status === 'pending') { 
    
          dispatch(setConsultationId(res.data.consultation._id)); 
          dispatch(setIsRefreshContent(true)); 
  
        } else { 
  
          navigate('/dashboard'); 
  
          window.location.reload(); 
  
        }; 
  
      } else { 
  
        navigate('/dashboard'); 
  
        window.location.reload(); 
  
      }; 
  
    }; 

    // -- 
  
    useEffect(() => { 
  
      if (consultationId) { 
  
        handleConsultation(); 
  
      }; 
  
    },[consultationId]); 
  
    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

              if (consultationId) { 

                const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 

                dispatch(setCurrentTime(moment.utc(date).format('LLLL'))); 

              };

              setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100vw',
          height: '95vh',
          position: 'absolute', 
          bottom: 0, 
          backgroundColor: colors?.interface  
      }}>

        <Sidebar />

        <Note />

      </Box>

  );

};

export default Container;