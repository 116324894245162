import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import BoxView from './BoxView';
import { getDeletedData } from '../../../../../../routes/doctor/files';
import { setIsRefreshTrashBin, setSelectedData, setTrashFiles } from '../../../../../../redux/features/doctor/Dashboard/documents/trash';
import RowView from './RowView';


const List: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const folderView = useAppSelector(state => state.fileExplorer.folderView);  

    const trashFiles = useAppSelector(state => state.trash.trashFiles); 
    const isRefreshTrashBin = useAppSelector(state => state.trash.isRefreshTrashBin); 

    // -- 

    const handleDeletedData = async () => { 

        const res = await getDeletedData() as any; 
        
        if (res.data.status === 200) { 

            dispatch(setTrashFiles(res.data.deletedData)); 

        }; 

    }; 

    // -- 

    const handleDeletedDataSelection = (e: any, item: any) => { 

        dispatch(setSelectedData(item)); 

    }; 

    // -- 

    useEffect(() => { 

        if (isRefreshTrashBin) { 

            handleDeletedData(); 

            dispatch(setIsRefreshTrashBin(false)); 

        }; 

    },[isRefreshTrashBin]); 

    // -- 

    useEffect(() => { 

        handleDeletedData(); 

    },[]); 

    // --

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: 'auto', 
            minHeight: 500, 
            marginTop: 1, 
            overflow: 'scroll',
            scrollbarWidth: 'none', 
        }}>

            { trashFiles?.length >= 1 && 

            <Box 
                
                sx={{ 
                    width: '100%',
                    height: '100%',
                }}>

                    <MuiList dense disablePadding>
                            
                            {trashFiles?.map((item, index) => (

                                <ListItem 
                                    onClick={(e) => handleDeletedDataSelection(e, item)} 
                                    button 
                                    key={index} 
                                    disablePadding>

                                    <RowView 
                                        _id={item._id}
                                        title={item.title}
                                        path={item.path} 
                                        updatedAt={item.updatedAt}
                                    />

                                </ListItem>

                            ))}

                    </MuiList>

                {/* { (folderView === 'row') && 
                
                <MuiList dense disablePadding>
        
                    {trashFiles?.map((item, index) => (
            
                        <ListItem 
                            onClick={(e) => handleDeletedDataSelection(e, item)} 
                            button 
                            key={index} 
                            disablePadding>

                            <RowView 
                                _id={item._id}
                                title={item.title}
                                path={item.path} 
                                updatedAt={item.updatedAt}
                            />

                        </ListItem>

                    ))}
        
                </MuiList>} */}

                {/* { (folderView === 'box') && 

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            overflowWrap: 'normal',
                            width: '100%',
                            height: '100%', 
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            transition: 'width 0.3s',
                        }}
                    >

                        {trashFiles?.map((item, index) => (
                            
                            <Box 
                                onClick={(e) => handleDeletedDataSelection(e, item)} 
                                key={index} 
                            >

                                <BoxView 
                                    index={index}
                                    item={item}
                                    type={'file'}
                                />

                            </Box>

                        ))}

                    </Box>} */}

            </Box>}

            {(trashFiles?.length === 0) &&   
          
            <Box
              sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  height: '100%',
              }}>
          
              <Box
                  sx={{
                      width: 252,
                      height: 214, 
                      marginTop: '5%',
                  }}
                  component="img"
                  alt="Solutions Medca Inc."
                  src={require('../../../../../../assets/logos/empty_list.png')}
              />

              <Box sx={{ 
                  color: colors?.text,
                  fontSize: 12,
                  marginBottom: '20%',
              }}>{t('emptyFolder')}</Box>

            </Box>}
      

          </Box>

    );
};

export default List;