import React from 'react';
import Box from '@mui/material/Box';

const Background: React.FC = () => {

  return (
    <Box
      component="img"
      sx={{
        height: '100vh',
        width: '100vw',
        position: 'absolute'
      }}
      alt="Login Background"
      src={require('../../../assets/logos/login_background.jpeg')}
    />
  );
};

export default Background;