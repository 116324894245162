import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface InitialState {
  doctor: any,
  group: any, 
}

// ---------------

const initialState: InitialState = {
  doctor: null,
  group: null, 
};

// ---------------


export const doctorAccountSlice = createSlice({

  name: 'doctorAccount',

  initialState,

  reducers: {

    setDoctorAccount: (state, action: PayloadAction<any>) => {
        state.doctor = action.payload
    },
    setGroup: (state, action: PayloadAction<any>) => {
      state.group = action.payload
    },
    setResetDoctorAccount: (state) => { 
        
      state.doctor = null; 
      state.group = null; 

    } 
  },

}); 

// ---------------

export const { 
  setDoctorAccount, 
  setGroup, 
  setResetDoctorAccount, 
} = doctorAccountSlice.actions;


export default doctorAccountSlice.reducer;