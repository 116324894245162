import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 
 
import Box from '@mui/material/Box';
import Title from './Title';
import FieldRow from './FieldRow';
import Info from './Info';


const FieldsDropdown: React.FC = () => {

    const colors = useAppSelector(state => state.theme.colors);

    const noteFields = useAppSelector(state => state.consultationNote.noteFields); 
    const newNoteFieldTitle = useAppSelector(state => state.consultationNote.newNoteFieldTitle); 

    // -- 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 400,
                maxWidth: ((window?.innerWidth - 560) / 2) - 100, 
                height: 'auto',
                top: '15vh', 
                right: '3vw', 
                position: 'absolute',
                borderRadius: 4,
                zIndex: 10000, 
                transition: 'width 0.3s',
            }}
        >

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '100%', 
                    cursor: 'pointer',
                }}
            >

                <Title />

                <Info />

            </Box>

        </Box>

    );

}; 

export default FieldsDropdown; 