import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Components 

import SubmitButton from './formItems/SubmitButton';
import BackButton from './formItems/BackButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SecurityIcon from '@mui/icons-material/Security';
import GroupSelection from './formItems/GroupSelection';
import FormField from '../../general/inputs/FormField';
import { setEmail, setFirstName, setLastName, setSecurityPin } from '../../../redux/features/hacker/users/createDoctor';


const CreateDoctor: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);  

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        color: colors?.text, 
    }}>

        <BackButton /> 

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: 600,
            scrollbarWidth: 'none',
            backgroundColor: colors?.interface,
            borderRadius: 4,
            paddingTop: 2, 
            marginTop: 3
          }}
        >

          <GroupSelection />

          <FormField 
              icon={<AccountCircleIcon sx={{ color: colors?.lightBlue }} />}
              section={'firstName'}
              isFocused
              dispatchFunction={setFirstName}
          />

          <FormField 
              icon={<AccountCircleIcon sx={{ color: colors?.lastName }} />}
              section={'lastName'}
              dispatchFunction={setLastName}
          />

          <FormField 
              icon={<MarkEmailReadIcon sx={{ color: colors?.email }} />}
              section={'email'}
              dispatchFunction={setEmail}
          />

          <FormField 
              icon={<SecurityIcon sx={{ color: colors?.purple }} />}
              section={'securityPin'}
              helperText={'pinInfo'}
              dispatchFunction={setSecurityPin}
          />

          <SubmitButton />

        </Box>

    </div>

  );

};

export default CreateDoctor;