import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import BackButton from './BackButton';
import Box from '@mui/material/Box';
import Language from './Language';
import Theme from './Theme'; 

const Preferences: React.FC = () => {

  const { t } = useTranslation();
  const accountInfo = useAppSelector((state) => state.meta.accountInfo);

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '95vh',
        width: '95vw',
        bottom: 0,
        right: 0, 
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        position: 'absolute', 
    }}>

        <Language /> 

        {/* <Theme />  */}

    </Box>

  );

};

export default Preferences;