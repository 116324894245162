import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface DoctorsAccessState {
  groupAccess: any,
}

// ---------------

const initialState: DoctorsAccessState = {
  groupAccess: null, 
};

// ---------------


export const groupSlice = createSlice({

  name: 'group',

  initialState,

  reducers: {

    setGroupAccess: (state, action: PayloadAction<any>) => { 

        state.groupAccess = action.payload; 

    },
    resetGroup: (state) => { 
        
      state.groupAccess = null; 

    } 

  },

}); 

// ---------------

export const { 
  setGroupAccess, 
  resetGroup 
} = groupSlice.actions;

export default groupSlice.reducer;