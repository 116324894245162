import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components

import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import getDaysUntilDeletion from '../../../../../../util/dashboard/doctor/files/getDaysUntilDeletion';
import Dropdown from './Dropdown';

interface Props { 
    _id: string; 
    title: string; 
    path: string; 
    updatedAt: string; 
}; 

const RowView: React.FC<Props> = ({_id, title, path, updatedAt }) => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const selectedData = useAppSelector((state) => state.trash.selectedData);  

    const [isDropdownOpened, setIsDropdownOpened] = useState(false); 

    const [formattedPath, setFormattedPath] = useState(''); 

    // -- 

    const handleDropdown = () => { 

        setIsDropdownOpened(!isDropdownOpened); 

    }; 

    // -- 

    useEffect(() => { 

        if (path.length > 20) { 

            setFormattedPath(`.. ${path.slice(-20)}`); 

        } else { 

            setFormattedPath(path); 
            
        };

    },[path]); 

    // -- 

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                fontSize: 12, 
                height: 50,
                cursor: 'pointer',
                backgroundColor: (selectedData?._id == _id) ? colors?.sectionHover : 'transparent',
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}>
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>

                    <Box component="img" sx={{ width: 20, marginLeft: 1 }} src={require('../../../../../../assets/icons/pdf-thumbnail.png')} />

                </div>
                
                <div style={{ 
                    width: '30%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{title}</div>

                <div style={{ 
                    width: '30%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{formattedPath}</div>

                <div style={{ 
                    width: '20%', 
                    color: colors?.red, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{`${getDaysUntilDeletion(updatedAt)} ${t('days')}`}</div>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    width: '10%', 
                }}>

                    <MoreVertIcon onMouseDown={(e) => handleDropdown()}  sx={{ color: colors?.text, fontSize: 20 }} />

                </div>
    
            </Box>

            { isDropdownOpened && <Dropdown />}

        </>

    );
};

export default RowView;