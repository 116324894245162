import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = (t) => [
    { 
        type: 'item', 
        section: 'preferences', 
        text: 'theme', 
        iconName: 'contrast', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-preferences' 
    },
    { 
        type: 'item', 
        section: 'group', 
        text: 'group', 
        iconName: 'contrast', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-group' 
    },
    // { type: 'item', section: 'notifications', text: t('alerts'), iconName: 'notifications', iconColor: currentTheme?.sidebarIconColor, key: 'i-notifications'}, 
    { 
        type: 'item', 
        section: 'account', 
        text: 'account', 
        iconName: 'admin_panel_settings', 
        iconColor: currentTheme?.sidebarIconColor,
        key:'i-account'
    },
];

export default menuItems; 