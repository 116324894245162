import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setHackerDataSection } from '../../../../redux/features/hacker/data/hackerData';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    title: string,
    section: string, 
}; 

const NavItem: React.FC<Props> = ({ title, section }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const dataSection = useAppSelector(state => state.hackerData.section); 

    // -- 

    const changeSection = () => { 

        dispatch(setHackerDataSection(section)); 

    }; 

    // -- 

    return (

        <Box 
            onClick={changeSection}
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                padding: 2,
                marginLeft: 2,
                marginRight: 2, 
                fontSize: 20, 
                color: colors?.text,
                cursor: 'pointer', 
                '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.3)'}
            }}>

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'space-evenly', 
                        height: 40, 
                    }}>{title}</Box>

                <Box
                    sx={{ 
                        display: 'flex',
                        width: 150, 
                        height: 2, 
                        backgroundColor: dataSection === section ? 'rgb(45, 207, 196)': 'transparent', 
                    }}
                >

                </Box>


        </Box>

  );

};

export default NavItem;