import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { setIsOptionsListOpened } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import OptionRow from './OptionRow';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PublicIcon from '@mui/icons-material/Public';

const Options: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const { i18n } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 

    const noteFields = useAppSelector(state => state.noteMicrophone.noteFields); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const mode = useAppSelector(state => state.noteMicrophone.mode); 

    const [languageTitle, setLanguageTitle] = useState(''); 

    // -- 

    const handleQuitOptions = () => { 

        dispatch(setIsOptionsListOpened(false));

    }; 

    // -- 

    useEffect(() => { 

        if (language) { 

            if (language === 'en-US') { 

                setLanguageTitle('English'); 

            } else if (language === 'fr-FR') { 

                setLanguageTitle('Français'); 

            };

        }; 

    },[language]); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100vw',
          height: '100vh', 
          position: 'absolute', 
          backgroundColor: colors?.container, 
          overflowY: 'scroll', 
      }}>

        <Box
            onClick={handleQuitOptions}
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                width: '100%', 
                paddingLeft: 2,  
                marginTop: 2, 
                marginBottom: 2, 
                color: colors?.text,
                height: 50, 
            }}
        >
            <ArrowBackIosNewIcon /> 

            <Box sx={{ marginLeft: 2 }}>{'Microphone'}</Box>

        </Box>

        <OptionRow action='camera' iconColor={colors?.lightBlue} isNavigation>
            <CameraAltIcon /> 
        </OptionRow>

        {((noteFields?.length > 0) && (mode !== 'autoComplete')) &&
        <OptionRow action='startAutoComplete' iconColor={colors?.green}>
            <PlayArrowIcon /> 
        </OptionRow>}

        {(mode === 'autoComplete') &&
        <OptionRow action='stopAutoComplete' iconColor={colors?.microphoneEnabled}>
            <StopIcon /> 
        </OptionRow>}

        <OptionRow action='saveConsultationNote' iconColor={colors?.createPatient}>
            <SaveIcon /> 
        </OptionRow>

        <OptionRow action='resetText' iconColor={colors?.orange}>
            <RotateLeftIcon /> 
        </OptionRow>

        <OptionRow action='language' iconColor={colors?.purple} customRightComponent={languageTitle}>
            <PublicIcon /> 
        </OptionRow>

      </Box>

  );

};

export default Options;