import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import GroupList from './GroupList';

const Group: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            height: '95vh',
            width: '95vw',
            bottom: 0,
            right: 0, 
            overflowX: 'hidden',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            position: 'absolute', 
        }}
      >
            <GroupList />

      </Box>

  );

};

export default Group;