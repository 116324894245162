import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DayPicker } from "react-day-picker";
import Box from '@mui/material/Box';
import "react-day-picker/style.css";
import { setDateOfBirth, setErroredField, setIsDateOfBirthSelectorOpened } from '../../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import moment from 'moment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DateSelector: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const dateOfBirth = useAppSelector((state) => state.createPatient.dateOfBirth); 

    const [date, setDate] = useState('') as any; 

    // -- 

    const quitCalendar = () => { 

        dispatch(setIsDateOfBirthSelectorOpened(false)); 

    };

    // --

    useEffect(() => { 

        if (date) { 

            dispatch(setDateOfBirth(moment(date).format('YYYY-MM-DD'))); 
            
            dispatch(setIsDateOfBirthSelectorOpened(false)); 

        }; 

    },[date]); 

    // -- 

    return (

        <Box
            sx={{
                backgroundColor: colors?.container,
                borderRadius: 3, 
                borderWidth: 1, 
                borderColor: colors?.lightBlue, 
                borderStyle: 'solid',
                position: 'absolute', 
                zIndex: 5000, 
            }}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    padding: 10,  
                    borderRadius: 3, 
                    backgroundColor: 'rgba(60,179,179, 0.2)',
                }}
            >

                <DayPicker
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    captionLayout="dropdown"
                />

            </Box>

            <Box
                onClick={quitCalendar}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    position: 'absolute', 
                    right: 5, 
                    top: 5,
                    fontSize: 30, 
                    cursor: 'pointer', 
                    '&:hover': { color: colors?.red }
                }}
            >
                <HighlightOffIcon sx={{ fontSize: 30 }}/> 
            </Box>



        </Box>
  );

};

export default DateSelector;