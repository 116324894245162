import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import moment from 'moment';

// Icons 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import getStatusColor from '../../../../util/style/getStatusColor';
import { getGroupMemberInfo } from '../../../../routes/doctor/account';

type Props = {
  item: any; 
};


const GroupAccessRow: React.FC<Props> = ({ item }) => {

  const colors = useAppSelector(state => state.theme.colors); 
  const accountInfo = useAppSelector(state => state.meta.accountInfo); 

  const [account, setAccount] = useState(null) as any; 

  const handleGroupMemberInfo = async () => { 

    const res = await getGroupMemberInfo(item._id) as any; 

    if (res.data.status === 200) { 

      setAccount(res.data.doctor); 

    }; 

  }; 

  // -- 

  useEffect(() => { 

    if (item) { 

      handleGroupMemberInfo(); 

    }; 

  },[item]); 

  // -- 

  return (

    <Box 
      sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
      '&:hover': { backgroundColor: colors?.sectionHover, cursor: 'default' }
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '18%',
                marginLeft: '2%',  
                textAlign: 'left',
                overflow: 'hidden',
            }}>{account?.firstName} {account?.lastName}</div>

            <div style={{ 
                width: '15%', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: getStatusColor(item?.status), 
                    color: 'white', 
                }}>
                    {item?.status}
                </strong>
            </div>

            <div style={{ 
                width: '25%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{account?.email}</div>

            <div style={{ 
                width: '20%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{account?.type ? account?.type : 'Doctor'}</div>


            <div style={{ 
                width: '15%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{moment(account?.lastActive).format('LL')}</div>

            <div style={{ 
                width: '5%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                <Box
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    '&:hover': { backgroundColor: 'rgba(45, 153, 207, 0.6)'}
                  }}
                >
                  <MoreVertIcon></MoreVertIcon>
                </Box>

            </div>
            
    </Box>

  );

};

export default GroupAccessRow;