import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


// Icons 

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props { 
    icon?: React.ReactNode, 
    title?: any,
    description?: any, 
    href?: any
    customTextColor?: string
}

const DescriptionBox: React.FC<Props> = ({ icon, title, description, href, customTextColor }) => {

    const { t } = useTranslation();
    
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors);

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: 'auto',
                width: '25%',
                color: customTextColor ? customTextColor : colors?.text,
            }}
        > 

        {icon}

        <Box
            sx={{
                fontSize: 18, 
                marginBottom: 2,
            }}
        >{title}</Box>

        <Box>{description}</Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 3,
                cursor: 'pointer',
                color: colors?.legalLearMore,
                '&:hover': {
                    color: colors?.legalLearnMoreHover,
                },
            }}>
             <a 
                style={{ 
                    textDecoration: 'none', 
                    marginRight: 2,
                    color: colors?.main 
                }} 
                target="_blank" 
                href={href}
            >{t('learnMore')}</a>
            <ArrowForwardIcon sx={{ color: colors?.main }} />
        </Box>

        </Box>
  );
};

export default DescriptionBox;