import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// -- 

export const getGroupMemberInfo = async (groupAccessId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/account/group/member/${groupAccessId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving a doctor info..', error);
    };

};

// -- 

export const getGroupList = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/list`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error GET group list.', error);
    };

};

// -- 

export const getGroupAccess = async (groupAccessId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/access/${groupAccessId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error GET group access.', error);
    };

};

// -- 

export const createGroupAccess = async (firstName: string, lastName: string, email: string, securityPin: string) => {

    try {

        const content = { 
            firstName: firstName, 
            lastName: lastName, 
            email: email,
            securityPin: securityPin
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/access`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a new account:', error);
    };

};

// -- 

export const updateGroupAccess = async (groupAccessId: string) => { 

    try {

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/access/${groupAccessId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error PUT group access.', error);
    };

};

// -- 

export const deleteGroupAccess = async (groupAccessId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/access/${groupAccessId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error DELETE group access.', error);
    };

};

// --

export const getGroupAccessWithDoctorId = async (doctorId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/group/access/doctor/${doctorId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error GET group access with doctor Id.', error);
    };

};

// -- 



