import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getAdminConfig = () => { 

    const adminJwt = Cookies.get('adminJwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${adminJwt}`,
        },
    }; 

    return config; 

}; 

// -- 

export const requestAuthCode = async (email: string, userType: string) => {

    try {

        const content = { 
            email: email,
            userType: userType,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/request-code`, content);

        return res; 

    } catch (error) {

      console.error('Error requesting code:', error);

    }; 
};

// -- 

export const verifyAuthCode = async (email: string, verificationCode: string, userType: string, loginAttemptId: any, groupAccessId?: any) => {

    try {

        const content = { 
            email: email,
            verificationCode: verificationCode,
            userType: userType,
            loginAttemptId: loginAttemptId, 
            groupAccessId: groupAccessId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/verify-code`, content);

        return res; 

    } catch (error) {
        console.error('Error verifying the code'); 
    }
};

// -- 

export const resendAuthCode = async (email: string, userType: string, loginAttemptId: any) => {

    try {

        const content = { 
            email: email,
            userType: userType,
            loginAttemptId: loginAttemptId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/resend-code`, content);

        return res; 

    } catch (error) {

      console.error('Error resending code:', error);

    }; 
};

// -- 

export const verifyUserAccess = async (type: string) => { 

    try {

        const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/account`, 
            ((type === 'doctor') || (type === 'admin')) ?  getConfig() : getAdminConfig()) as any; 

        if (res.status === 200) { 

            return res; 
              
        }; 
        
    } catch (error) {
        console.error('Error fetching the teams:', error);
    };

};

// -- 

export const acceptAccessRequest = async (patientAccessRequestId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/patient/access/request/${patientAccessRequestId}`);

        return res; 

    } catch (error) {
        console.error('Error requesting a patient access.', error);
    };

}; 

// -- 

export const requestAccessToConsultation = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/access/${consultationId}`);

        return res; 

    } catch (error) {
        console.error('Error requesting access to a consultation.', error);
    };

}; 

// -- 

export const accessConsultation = async (consultationId: any, pin: string) => { 

    try {

        const content = { 
            pin: pin 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/access/${consultationId}`, content);

        return res; 

    } catch (error) {
        console.error('Error when trying to access a consultation.', error);
    };

}; 

// -- 

export const verifySecurityPin = async (securityPin: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/account/verify/pin/${securityPin}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Failed to verify the user security pin.', error);
    };

}; 

// -- 

export const getGroupInfoWithId = async (groupId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/group/info/${groupId}`);

        return res; 

    } catch (error) {
        console.error('Error retrieving group info', error);
    };

}; 

// -- 

export const selectGroupAccess = async (groupId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/group/selection/${groupId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error selection a group at authentication.', error);
    };

}; 