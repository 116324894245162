
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setPatientsList } from '../../../../../../redux/features/template/template';

// Routes

import { getPatientAccessList } from '../../../../../../routes/doctor/patients';

// Components

import PatientRow from './PatientRow';

const PatientsList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const patientsList = useAppSelector(state => state.template.patientsList); 
    const filteredPatientsList = useAppSelector(state => state.template.filteredPatientsList); 
    const patientSearchQuery = useAppSelector(state => state.template.patientSearchQuery); 

    // -- 

    const handlePatientsList = async () => { 

        const res = await getPatientAccessList() as any; 

        if (res.data.status === 200) { 

            dispatch(setPatientsList(res.data.patientAccessList)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        handlePatientsList(); 

    },[]); 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                height: '80%', 
                marginTop: 1,
            }}
        >

        { patientsList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                alignItems: 'center', 
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>
        
            {((patientsList?.length > 0) && !patientSearchQuery) && patientsList?.map((item, index) => (
                <PatientRow
                    key={index}
                    _id={item._id}
                    patientId={item.patientId}
                />
            ))}

            {((filteredPatientsList?.length > 0) && patientSearchQuery) && filteredPatientsList?.map((item, index) => (
                <PatientRow
                    key={index}
                    _id={item._id}
                    patientId={item.patientId}
                />
            ))}
            
            </Box>}

          </Box>

    );

};

export default PatientsList;