import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { setDashboardSection } from '../../../redux/features/general/navigation';

// Components 

import GroupHeader from './GroupHeader';
import LinearProgress from '@mui/material/LinearProgress';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

// import Dropdown from './Dropdown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Popup from '../../general/Popup';
import { getGroupList } from '../../../routes/doctor/account';
import GroupRow from './GroupRow';
import Dropdown from './Dropdown';
import { setGroupAccessList, setInitialRendering, setIsRefreshGroupAccessList, setSelectedGroupAccess } from '../../../redux/features/doctor/group/groupAccess';

const GroupList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);  

    const isRefreshGroupAccessList = useAppSelector(state => state.groupAccess.isRefreshGroupAccessList);  
    const selectedGroupAccess = useAppSelector(state => state.groupAccess.selectedGroupAccess);  
    const groupAccessList = useAppSelector(state => state.groupAccess.groupAccessList);  
    const initialRendering = useAppSelector(state => state.groupAccess.initialRendering);  

    const [postionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const getDoctorsList = async () => { 

      const res = await getGroupList() as any; 

      if (res.data.status === 200) { 

        dispatch(setGroupAccessList(res.data.groupAccessList)); 

      };

    }; 

    // -- 

    const handleSelection = (e: any, groupAccess: any) => { 

      if (selectedGroupAccess) { 

        if (selectedGroupAccess?._id == groupAccess?._id) { 

          dispatch(setSelectedGroupAccess(null)); 

          return; 
  
        }; 

      }; 

      setPositionX(e.pageX);
      setPositionY(e.pageY); 

      dispatch(setSelectedGroupAccess(groupAccess)); 

    }; 

    // -- 

    const changeSection = () => { 

      // dispatch(setDashboardSection('createDoctor')); 

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getDoctorsList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

      if (isRefreshGroupAccessList) { 

        getDoctorsList(); 

        dispatch(setIsRefreshGroupAccessList(false)); 

      }; 

    },[isRefreshGroupAccessList]); 

    // -- 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              width: '95vw',
              text: colors?.text, 
              backgroundColor: colors?.container,
            }}>            
            
            <GroupHeader />

            { groupAccessList?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {groupAccessList?.map((item, index) => (
        
                    <ListItem sx={{ color: colors?.text }} onClick={(e) => handleSelection(e, item)} button key={index} disablePadding>

                        <GroupRow item={item} />

                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {groupAccessList?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly', 
              height: '100%',
              width: '80%', 
          }}>

              <Box
                  sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}>

                  <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                  <Box>Retrieving Group...</Box>

              </Box>

          </Box>}

          { selectedGroupAccess && <Dropdown positionX={postionX - 20} positionY={positionY - 30} />}
        
          </Box>

        </Box>
    
      );

};

export default GroupList;