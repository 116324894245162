import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    title: string; 
    icon?: React.ReactNode; 
    triggerFunction: any; 
    extraStyle?: any; 
}; 

const Button: React.FC<Props> = ({ title, icon, triggerFunction, extraStyle }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

        <Box
            onClick={triggerFunction}
            sx={[{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                padding: 1, 
                fontSize: 14, 
                borderRadius: 1, 
                marginTop: 3, 
                paddingLeft: 2,
                paddingRight: 2, 
                height: 30,
                color: 'white', 
                cursor: 'pointer', 
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                backgroundColor: colors?.main, 
                '&:hover': { backgroundColor: colors?.mainHover }
            }, extraStyle]}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        minHeight: '100%',
                        marginRight: 0.25
                    }}
                >{icon}
                </Box>
                
                <Box 
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        minHeight: '100%',
                        marginLeft: 0.25
                }}>{t(title)}</Box>

            </Box>

        </Box>

  );

};

export default Button;