import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { editConsultationNote } from '../../../routes/doctor/consultation';
import { setIsFieldsListOpened, setIsOptionsListOpened, setMode } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

const ActionButton: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationId, consultationNoteId, securityPin } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const noteFields = useAppSelector(state => state.noteMicrophone.noteFields); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const mode = useAppSelector(state => state.noteMicrophone.mode); 

    // -- 

    const manageAutoComplete = async () => { 

        if ((noteFields?.length > 0) && (mode !== 'autoComplete')) { 

            const content = { 
                consultationNoteId: consultationNoteId, 
                mode: 'autoComplete', 
                securityPin: securityPin, 
              }; 
      
            await editConsultationNote(content) as any; 
    
            dispatch(setMode('autoComplete')); 

            dispatch(setIsFieldsListOpened(false)); 

        } else { 

            const content = { 
                consultationNoteId: consultationNoteId, 
                mode: 'speaking', 
                securityPin: securityPin, 
            }; 

            await editConsultationNote(content) as any; 

            dispatch(setMode('speaking')); 

        }; 

    }; 

    // -- 

    return (

      <Box 
        onClick={manageAutoComplete}
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          bottom: 20, 
          position: 'absolute', 
          minWidth: 200,
          height: 50, 
          borderRadius: 10, 
          color: 'white', 
          paddingLeft: 2, 
          paddingRight: 2, 
          backgroundColor: ((noteFields?.length > 0) && (mode !== 'autoComplete')) ? colors?.green : colors?.red
      }}>

        {((noteFields?.length > 0) && (mode !== 'autoComplete')) ? <PlayArrowIcon /> : <StopIcon />}

        <Box sx={{ marginLeft: 2 }} >{((noteFields?.length > 0) && (mode !== 'autoComplete')) ? t('startAutoComplete') : t('stopAutoComplete')}
        </Box>


      </Box>

  );

};

export default ActionButton;