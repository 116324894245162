import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

const Logo: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box 
        component="img"
        sx={{
            height: 25,
            width: 'auto',
            position: 'absolute',
            bottom: '5vh',
        }}
        alt="Solutions Medca Inc."
        src={require('../../../assets/logos/medca_logo_blue_white.png')}
        >
      </Box>

  );

};

export default Logo;