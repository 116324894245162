import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateDoctorState {
  firstName: string,
  lastName: string,
  email: string,
  type: string, 
  securityPin: string; 
  groupId: string; 
  groupName: string; 
}

// ---------------

const initialState: CreateDoctorState = {
    firstName: '', 
    lastName: '',
    email: '',
    type: '', 
    securityPin: '', 
    groupId: '',
    groupName: '', 
};

// ---------------


export const createDoctorSlice = createSlice({

  name: 'createDoctor',

  initialState,

  reducers: {

    setFirstName: (state, action: PayloadAction<string>) => {

        state.firstName = action.payload; 
    },
    setLastName: (state, action: PayloadAction<string>) => { 
        
        state.lastName = action.payload;  
    },
    setEmail: (state, action: PayloadAction<string>) => { 
        
        state.email = action.payload; 
    },
    setType: (state, action: PayloadAction<string>) => { 
      state.type = action.payload; 
    },
    setSecurityPin: (state, action: PayloadAction<string>) => { 
        
      state.securityPin = action.payload; 
    }, 
    setGroupId: (state, action: PayloadAction<string>) => { 
        
      state.groupId = action.payload; 
    }, 
    setGroupName: (state, action: PayloadAction<string>) => { 
        
      state.groupName = action.payload; 
    }, 
    resetCreateDoctor: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.type = ''; 
        state.securityPin = ''; 
        state.groupId = ''; 
        state.groupName = ''; 
    } 

  },

}); 

// ---------------

export const { 
    setFirstName, 
    setLastName, 
    setEmail, 
    setType, 
    setSecurityPin, 
    setGroupId, 
    setGroupName, 
    resetCreateDoctor,
   } = createDoctorSlice.actions;

export default createDoctorSlice.reducer;