import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Box } from '@mui/material';
import { useTheme } from '../themeContext';
import { isMobile } from 'react-device-detect';

// -- Components 

import Notification from '../components/general/Notification';
import Container from '../components/groupSelection/Container';

const GroupSelection: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-evenly', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText
      }}
    >

      <Container/>

      <Notification />

    </Box>
  );
};

export default GroupSelection;