import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { getNumberOfDoctorsByDate } from '../../../../routes/hacker/users';

// Components 

import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts/LineChart';
import EventLimiter from '../../../../util/events/EventLimiter';
import CircularProgress from '@mui/material/CircularProgress';
import { setDoctorsChartData, setDoctorsChartXAxis } from '../../../../redux/features/hacker/data/hackerData';


const DoctorsChart: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const data = useAppSelector(state => state.hackerData.data); 
    const doctorsChartData = useAppSelector(state => state.hackerData.doctorsChartData); 
    const doctorsChartXAxis = useAppSelector(state => state.hackerData.doctorsChartXAxis); 

    const [allow, setAllow] = useState(false); 

    // -- 

    const getDate = async (date: any) => {

        const res = await getNumberOfDoctorsByDate(date) as any;  

        if (res.data.status === 200) { 

            dispatch(setDoctorsChartData(res.data.accountsTotal)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (data && doctorsChartData) { 

            if ((data?.xAxis.length === 14) && (doctorsChartData?.length === 0)) { 

                data?.xAxis.map((item, index) => { 
    
                    dispatch(setDoctorsChartXAxis(item.slice(0, 5))); 
    
                }); 
    
                data?.xAxis.forEach(async (date) => { 
    
                    try {
            
                        await EventLimiter.schedule(() => getDate(date));
                
                    } catch (err) {
                        console.log(err);
                    };
            
                });
    
            }; 

        };

    },[data, doctorsChartData]); 

    // -- 

    useEffect(() => { 

        if ((doctorsChartData?.length === 14) && (doctorsChartXAxis?.length === 14)) { 

            setAllow(true); 

        }; 

    },[doctorsChartData, doctorsChartXAxis]); 

    // --

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            marginTop: 8,
        }}>

            {  (data && allow) ? 
             <LineChart
                width={window.innerWidth * 0.7}
                height={300}
                series={[
                { data: doctorsChartData, label: 'Doctors', color: 'rgb(45, 207, 196)' },
                ]}
                xAxis={[{ 
                    scaleType: 'point', 
                    data: doctorsChartXAxis, 
                    labelStyle: { 
                        color: colors?.text, 
                    } 
                }]}
            />:
            <CircularProgress size={60}/>
            }

        </Box>

  );

};

export default DoctorsChart;