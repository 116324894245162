import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface Props { 
    icon: any; 
    section: string; 
    helperText?: string; 
    placeholder?: string; 
    isFocused?: boolean; 
    width?: number; 
    dispatchFunction: any; 
}

const FormField: React.FC<Props> = ({ icon, section, helperText, placeholder, isFocused, width, dispatchFunction }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [text, setText] = useState(''); 

    // -- 

    const handleChange = (e: any) => { 

        setText(e.target.value); 

        dispatch(dispatchFunction(e.target.value)); 

    }; 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '100%', 
            marginTop: 2, 
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 2, 
                    width: width ? width : '80%', 
                }}
            >
                {icon}

                <Box
                    sx={{ 
                        marginLeft: 1
                    }}
                >{t(section)}
                </Box>

            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%', 
                    padding: 1, 
                    borderColor: 'rgb(138, 133, 133)', 
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 2, 
                    
                }}
            >

                <TextField
                    id="create-account-form"
                    placeholder={placeholder}
                    variant="standard"
                    value={text}
                    autoFocus={isFocused}
                    InputProps={{
                        style: {fontSize: 16, color: colors?.text },
                        disableUnderline: true, 
                    }}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '80%',
                        borderBottomColor: colors?.searchIcon
                    }}
                />

            </Box>

            { helperText && 
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%',
                    marginBottom: 1,
                    marginTop: 0.5,  
                    fontSize: 10, 
                }}
            >
                {t(helperText)}
            </Box>}

        </Box>

  );

};

export default FormField;