import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NoteMicrophoneState {
    consultationId: string; 
    consultationNoteId: string; 
    mode: string; 
    securityPin: string; 
    isOptionsListOpened: boolean; 
    isSettingsListOpened: boolean; 
    isFieldsListOpened: boolean; 
    language: string; 
    noteFields: any[], 
    selectedNoteField: any; 
    isListening: boolean; 
    isMicWarningOpened: boolean; 
    currentAudioFile: File | null; 
}; 

const initialState: NoteMicrophoneState = {
    consultationId: '', 
    consultationNoteId: '',
    mode: '', 
    securityPin: '', 
    isOptionsListOpened: false, 
    isSettingsListOpened: false, 
    isFieldsListOpened: false, 
    language: 'en-CA', 
    noteFields: [], 
    selectedNoteField: null, 
    isListening: false, 
    isMicWarningOpened: false, 
    currentAudioFile: null, 
};

const noteMicrophoneSlice = createSlice({
  name: 'noteMicrophone',
  initialState,
  reducers: {
    setConsultationId: (state, action: PayloadAction<string>) => {
      state.consultationId = action.payload;
    },
    setConsultationNoteId: (state, action: PayloadAction<string>) => {
      state.consultationNoteId = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload;
    },
    setIsOptionsListOpened: (state, action: PayloadAction<boolean>) => {
      state.isOptionsListOpened = action.payload;
    }, 
    setIsSettingsListOpened: (state, action: PayloadAction<boolean>) => {
      state.isSettingsListOpened = action.payload;
    }, 
    setIsFieldsListOpened: (state, action: PayloadAction<boolean>) => {
      state.isFieldsListOpened = action.payload;
    }, 
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }, 
    setNoteFields: (state, action: PayloadAction<any>) => {
      state.noteFields = action.payload;
    }, 
    setSelectedNoteField: (state, action: PayloadAction<any>) => {
      state.selectedNoteField = action.payload;
    }, 
    setIsListening: (state, action: PayloadAction<boolean>) => {
      state.isListening = action.payload;
    }, 
    setIsMicWarningOpened: (state, action: PayloadAction<boolean>) => {
      state.isMicWarningOpened = action.payload;
    }, 
    setCurrentAudioFile: (state, action: PayloadAction<File>) => {
      state.currentAudioFile = action.payload;
    },  
    resetConsultationNote: (state) => { 
        
      state.consultationId = ''; 
      state.consultationNoteId = '';
      state.mode = ''; 
      state.securityPin = ''; 
      state.isOptionsListOpened = false; 
      state.isSettingsListOpened = false; 
      state.isFieldsListOpened = false; 
      state.language = 'en-CA'; 
      state.noteFields = []; 
      state.selectedNoteField = null; 
      state.isListening = false;
      state.isMicWarningOpened = false; 
      state.currentAudioFile = null; 

    } 
  },
});

export const { 
  setConsultationId, 
  setConsultationNoteId, 
  setMode,
  setSecurityPin,  
  setIsOptionsListOpened, 
  setIsSettingsListOpened, 
  setIsFieldsListOpened, 
  setLanguage, 
  setNoteFields,
  setSelectedNoteField, 
  setIsListening,
  setIsMicWarningOpened, 
  setCurrentAudioFile, 
  resetConsultationNote } = noteMicrophoneSlice.actions;

export default noteMicrophoneSlice.reducer;