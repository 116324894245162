import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Action from '../../actions/Action';

// Icons 

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const FileActions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            zIndex: 10,
            right: '25%',
            height: 30, 
            position: 'absolute', 
            backgroundColor: colors?.actionBackground,
        }}>

            <Action title={'viewFile'} iconColor={colors?.viewFileIcon} action={'viewFile'} type='default' hideLabel>
                <RemoveRedEyeIcon /> 
            </Action>

            <Action title={'renameFile'} iconColor={colors?.renameFileIcon} action={'renameFile'} type='default' hideLabel>
                <DriveFileRenameOutlineIcon /> 
            </Action>

            <Action title={'moveTo'} iconColor={colors?.moveToIcon} action={'moveFile'} type='default' hideLabel>
                <DriveFileMoveIcon /> 
            </Action>

            { selectedFile?.relatedPatient &&

            <Action title={'patientInfo'} iconColor={colors?.patientInfoIcon} action={'patientInfo'} type='default' hideLabel>
                <AccountCircleIcon /> 
            </Action>}

            <Action title={'deleteFile'} iconColor={colors?.deleteIcon} action={'deleteFile'} type='default' hideLabel>
                <DeleteIcon /> 
            </Action>

        </Box>

    );

};

export default FileActions;