import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicRecorder from 'mic-recorder-to-mp3'; 
import { pcmEncode, downsampleBuffer } from '../../../../util/audio/awsUtils'; 
import { createPresignedURL } from '../../../../util/audio/awsSignature';
import { fromUtf8, toUtf8 } from "@smithy/util-utf8";
import getUserMedia from 'get-user-media-promise';
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';

import { EventStreamCodec } from '@smithy/eventstream-codec'; 
import { default as MicrophoneStream } from 'microphone-stream'; // collect microphone input as a stream of raw bytes



// Components 

import Box from '@mui/material/Box';
import MicNoneIcon from '@mui/icons-material/MicNone';
import PauseIcon from '@mui/icons-material/Pause';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { editConsultationNote, getAutoComplete } from '../../../../routes/doctor/consultation';
import { setCurrentAudioFile, setIsListening, setSelectedNoteField } from '../../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

// -- 

const Microphone: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    let socket;

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationNoteId = useAppSelector(state => state.noteMicrophone.consultationNoteId); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const securityPin = useAppSelector(state => state.noteMicrophone.securityPin); 
    const mode = useAppSelector(state => state.noteMicrophone.mode); 
    const selectedNoteField = useAppSelector(state => state.noteMicrophone.selectedNoteField); 
    const isListening = useAppSelector(state => state.noteMicrophone.isListening); 
    const currentAudioFile = useAppSelector(state => state.noteMicrophone.currentAudioFile); 

    const [timeLeft, setTimeLeft] = useState(0); 

    const [languageCode, setLanguageCode] = useState(''); 
    const [region, setRegion] = useState('us-east-1'); 
    const [sampleRate, setSampleRate] = useState(0); 
    const [inputSampleRate, setInputSampleRate] = useState(0); 
    const [transcription, setTranscription] = useState(''); 
    const [socketError, setSocketError] = useState(false); 
    const [transcribeException, setTranscribeException] = useState(false); 
    let micStream; 

    const [recorder, setRecorder] = useState(
        new MicRecorder({ bitRate: 128 })
    );

    document?.getElementById('startRecordButton')?.addEventListener("click", (e: any) => {
    
        try {

            if (isListening) { 

                micStream?.stop(); 

                dispatch(setIsListening(false)); 

            } else { 

                dispatch(setIsListening(true)); 
        
                getUserMedia({ video: false, audio: true })
                .then(function(stream) {
                    streamAudioToWebSocket(stream); 
                }).catch(function(error) {
                  console.log(error);
                });
            

            };

        } catch (err) {
            console.log("Error. ", err);
        };
    
    });
    
    // -- 

    const handleMicrophone = async () => { 

        return; 


    }; 

    // -- 

    let streamAudioToWebSocket = function (userMediaStream: any) {

        // // get Buffers (Essentially a Uint8Array DataView of the same Float32 values)
        // micStream.on('data', function(chunk) {
        //     // Optionally convert the Buffer back into a Float32Array
        //     // (This actually just creates a new DataView - the underlying audio data is not copied or modified.)
        //     const raw = MicrophoneStream.toRaw(chunk);
        //     //...

        //     // note: if you set options.objectMode=true, the `data` event will output AudioBuffers instead of Buffers
        // });

        //let's get the mic input from the browser, via the microphone-stream module

        micStream = new MicrophoneStream();
    
        micStream.on("format", function(data) {
            setInputSampleRate(data.sampleRate);
        });
    
        micStream.setStream(userMediaStream);
    
        // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
        // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html

        let url = createPresignedUrl();
    
        //open up our WebSocket connection
        socket = new WebSocket(url);
        socket.binaryType = "arraybuffer";
    
        let sampleRate = 0;
    
        // when we get audio data from the mic, send it to the WebSocket if possible
        socket.onopen = function() {
            
            micStream.on('data', function(rawAudioChunk) {
                // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
                let binary = convertAudioToBinaryMessage(rawAudioChunk);
    
                if (socket.readyState === socket.OPEN)
                    socket.send(binary);
            }
        )};
    
        // handle messages, errors, and close events
        wireSocketEvents();

    }; 

    function wireSocketEvents() {
        // handle inbound messages from Amazon Transcribe
        socket.onmessage = function (message) {

            //convert the binary event stream message to JSON

            const codec = new EventStreamCodec(toUtf8, fromUtf8) as any; 
            
            // To encode a JS object into binary-packed event stream message:
            const encodedMessage = codec.encode({
                headers: { ":message-type": "event", /* other headers */ },
                body: Buffer.from(JSON.stringify(message?.data), "utf8"),
            });
            
            // To decode binary data back into a JS object:

            let messageWrapper = codec.decode(encodedMessage);

            // let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));

            let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
            if (messageWrapper.headers[":message-type"].value === "event") {
                handleEventStreamMessage(messageBody);
            }
            else {
                setTranscribeException(true); 
                showError(messageBody.Message);
                toggleStartStop();
            }
        };
    
        socket.onerror = function () {
            setSocketError(true); 
            showError('WebSocket connection error. Try again.');
            toggleStartStop();
        };
        
        socket.onclose = function (closeEvent) {
            micStream.stop();
            
            // the close event immediately follows the error event; only handle one.
            if (!socketError && !transcribeException) {
                if (closeEvent.code != 1000) {
                    showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
                }
                toggleStartStop();
            }
        };
    };

    let closeSocket = function () {

        micStream.stop();

        dispatch(setIsListening(false)); 

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(''));

        const codec = new EventStreamCodec(toUtf8, fromUtf8) as any; 
        
        // To encode a JS object into binary-packed event stream message:
        const encodedMessage = codec.encode({
            headers: { ":message-type": "event", /* other headers */ },
            body: Buffer.from(JSON.stringify(emptyMessage), "utf8"),
        });
        
        // let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        socket.send(encodedMessage);

        // if (socket.readyState === socket.OPEN) {

        //     micStream.stop();

        //     // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        //     let emptyMessage = getAudioEventMessage(Buffer.from(''));

        //     const codec = new EventStreamCodec(toUtf8, fromUtf8) as any; 
            
        //     // To encode a JS object into binary-packed event stream message:
        //     const encodedMessage = codec.encode({
        //         headers: { ":message-type": "event", /* other headers */ },
        //         body: Buffer.from(JSON.stringify(emptyMessage), "utf8"),
        //     });
            
        //     // let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        //     socket.send(encodedMessage);
        // };

    };

    function toggleStartStop(disableStart = false) {

        // There in toggleStartStop(); 

        closeSocket(); 

        // Do something 

        // $('#start-button').prop('disabled', disableStart);
        // $('#stop-button').attr("disabled", !disableStart);
    }
    
    function showError(message) {
        // Do something 
    }
    
    function getAudioEventMessage(buffer) {
        // wrap the audio data in a JSON envelope
        return {
            headers: {
                ':message-type': {
                    type: 'string',
                    value: 'event'
                },
                ':event-type': {
                    type: 'string',
                    value: 'AudioEvent'
                }
            },
            body: buffer
        };
    }

    // -- 

    let handleEventStreamMessage = function (messageJson) {

        let results = messageJson.Transcript.Results;
    
        if (results.length > 0) {
            if (results[0].Alternatives.length > 0) {
                let transcript = results[0].Alternatives[0].Transcript;

                console.log(transcript); 
    
                // fix encoding for accented characters
                transcript = decodeURIComponent(escape(transcript));

                // update the textarea with the latest result
                // $('#transcript').val(transcription + transcript + "\n");
    
                // if this transcript segment is final, add it to the overall transcription
                if (!results[0].IsPartial) {

                    //scroll the textarea down
                    // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

                    handleNewText(transcript); 

                    setTranscription(`${transcription}${transcript}\n`); 

                }; 
            }
        }
    }    
    
    // -- 

    function convertAudioToBinaryMessage(audioChunk: any) {

        let raw = MicrophoneStream.toRaw(audioChunk);
    
        if (raw == null) { 
            return;
        };
            
    
        // downsample and convert the raw audio bytes to PCM

        let downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
        let pcmEncodedBuffer = pcmEncode(downsampledBuffer);
    
        // add the right JSON headers and structure to the message
        let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));
    
        //convert the JSON object + headers into a binary event stream message

        const codec = new EventStreamCodec(toUtf8, fromUtf8) as any; 
        
        // To encode a JS object into binary-packed event stream message:
        const encodedMessage = codec.encode({
            headers: { ":message-type": "event", /* other headers */ },
            body: Buffer.from(JSON.stringify(audioEventMessage), "utf8"),
        });

        // let binary = eventStreamMarshaller.marshall(audioEventMessage);
    
        return encodedMessage;
    }

    // -- 

    function createPresignedUrl() {
        let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";
    
        // get a preauthenticated URL that we can use to establish our WebSocket
        return createPresignedURL(
            'GET',
            endpoint,
            '/stream-transcription-websocket',
            'transcribe',
            CryptoJS.SHA256('').toString(CryptoJS.enc.Hex),
            // crypto.createHash('sha256').update('', 'utf8').digest('hex'), 
            {
                key: '',
                secret: '',
                // 'sessionToken': accountInfo?._id, // Needed !!!! 
                protocol: 'wss',
                expires: 15,
                region: region,
                query: "language-code=" + languageCode + "&media-encoding=pcm&sample-rate=" + sampleRate
            }
        );
    }

    // -- 

    const handleNewText = async (newText?: string) => { 

        if (mode === 'autoComplete') { 


        } else { 

            const content = { 
                consultationNoteId, 
                newDescription: newText ? newText : transcript,
                mode: 'speaking',
                securityPin: securityPin, 
            }; 

            await editConsultationNote(content) as any; 

        }; 

        resetTranscript(); 

    }; 

    // --

    useEffect(() => { 

        if (!isListening && transcript && (mode !== 'autoComplete')) { 

            handleNewText(); 

        }; 

    },[isListening]); 

    // -- 
  
    useEffect(()=>{
  
        let myInterval = setInterval(() => {
  
            if (timeLeft > 0) {
  
                setTimeLeft(timeLeft - 1);
  
            }; 
  
            if (timeLeft === 1) { 
  
                SpeechRecognition.stopListening(); 

                if (mode === 'autoComplete') { 

                    handleNewText(); 

                }; 

            }; 
  
            if (timeLeft === 0) {
  
                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 
  
        }, 500); 
  
        return ()=> {
            clearInterval(myInterval);
        };
  
    },[timeLeft]);

    // -- 

    return (

        <Box
            // onClick={handleMicrophone}
            id='startRecordButton'
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                borderRadius: '50%', 
                width: 100, 
                height: 100,
                backgroundColor: isListening ? colors?.microphoneEnabled : colors?.microphoneDisabled,
            }}
        >

        {((mode === 'autoComplete') && isListening) ? 
            <CheckOutlinedIcon sx={{ fontSize: 50, color: colors?.microphoneIcon }} />:
            <MicNoneIcon sx={{ fontSize: 50, color: colors?.microphoneIcon }} />}

        </Box>

  );

};

export default Microphone;