import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components

import TextField from '@mui/material/TextField';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { setAction, setFieldSearchQuery, setIsExistingNoteFieldTitle, setNewNoteFieldSelectionStart, setNewNoteFieldTitle } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';


const Title: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const noteFields = useAppSelector((state) => state.consultationNote.noteFields);
    const newNoteFieldTitle = useAppSelector((state) => state.consultationNote.newNoteFieldTitle);
    const isExistingNoteFieldTitle = useAppSelector((state) => state.consultationNote.isExistingNoteFieldTitle);

    const [text, setText] = useState(''); 

    // -- 

    const handleText = (e: any) => { 

        setText(e.target.value); 

        dispatch(setFieldSearchQuery(e.target.value)); 

    }; 

    // -- 

    const handleKey = (e: any) => { 

        if (e.key === 'Enter') { 

            dispatch(setAction('addNewNoteField')); 
        }; 

    }; 

    // -- 

    const handleQuit = () => { 

        dispatch(setAction('resetNoteFieldCreation')); 

    }; 

    // -- 

    const handleSave = () => { 

        dispatch(setAction('addNewNoteField')); 

    }; 

    // -- 

    useEffect(() => { 

        if (newNoteFieldTitle) { 

            const existingList = noteFields?.filter((noteField) => noteField.title === newNoteFieldTitle); 

            if (existingList.length > 0) { 

                dispatch(setIsExistingNoteFieldTitle(true)); 

            } else { 

                dispatch(setIsExistingNoteFieldTitle(false)); 

            };  

        }; 

    },[newNoteFieldTitle]); 

    // -- 

    return (
                
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between', 
                width: '100%', 
                height: 40,
                fontSize: 12,  
                backgroundColor: colors?.fileExplorerDropdown,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                borderRadius: 4, 
            }}
        >

            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10
                }}
            >

                <TextFieldsIcon sx={{ fontSize: 18, color: colors?.lightBlue }}/>

                <TextField
                    id="note-attributes-search"
                    placeholder={String(t('fieldTitle'))}
                    variant="standard"
                    value={text}
                    InputProps={{
                        style: { 
                            marginLeft: 5, 
                            fontSize: 12, 
                            color: colors?.text 
                        },
                        disableUnderline: true, 
                    }}
                    onChange={handleText}
                    onKeyDown={handleKey}
                    autoComplete='off'
                    sx={{
                        marginLeft: 1,
                        fontSize: 12,
                        width: 200, 
                        borderBottomColor: colors?.searchIcon
                    }}
                />

            </Box>

            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10
                }}
            >

                {(!isExistingNoteFieldTitle && newNoteFieldTitle) && 
                    <CheckCircleIcon 
                        onClick={handleSave}
                        sx={{ fontSize: 25, marginLeft: 1, color: colors?.green }}/>}

                {(!isExistingNoteFieldTitle && !newNoteFieldTitle) && <CheckCircleIcon sx={{ fontSize: 25, marginLeft: 1, color: colors?.grey }}/>}
                {isExistingNoteFieldTitle && <PublishedWithChangesIcon sx={{ fontSize: 25, marginLeft: 1, color: colors?.lightBlue }}/>}

                <HighlightOffIcon 
                    onClick={handleQuit}
                    sx={{ 
                        fontSize: 25, 
                        marginLeft: 1, 
                        marginRight: 2, 
                        color: colors?.consultaitonNoteFieldQuit, 
                        '&:hover': { color: colors?.red }
                    }}
                />

            </Box>

    </Box>

    );
};

export default Title;