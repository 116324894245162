import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { setIsFieldsListOpened } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import FieldRow from './FieldRow';
import ActionButton from './ActionButton';

const Fields: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const { i18n } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 

    const noteFields = useAppSelector(state => state.noteMicrophone.noteFields); 

    // -- 

    const handleQuitFields = () =>  {

        dispatch(setIsFieldsListOpened(false)); 

    }; 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100vw',
          height: '100vh', 
          position: 'absolute', 
          backgroundColor: colors?.container, 
          overflowY: 'scroll', 
      }}>

        <Box
            onClick={handleQuitFields}
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                width: '100%', 
                paddingLeft: 2,  
                marginTop: 2, 
                marginBottom: 2, 
                color: colors?.text,
                height: 50, 
            }}
        >
            <ArrowBackIosNewIcon /> 

            <Box sx={{ marginLeft: 2 }}>{'Microphone'}</Box>

        </Box>

        
        {(noteFields?.length > 0) && noteFields?.map((item, index) => (
                
            <FieldRow 
                key={index}
                index={index}
                noteField={item}
            />

        ))}

        {(noteFields?.length === 0) && 

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '100%', 
                    marginTop: 5, 
                    color: 'grey'
                }}
            >{t('noFieldDetected')}
            </Box>}

        {(noteFields?.length > 0) && <ActionButton />}

      </Box>

  );

};

export default Fields;