import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import SelectPatient from './selectPatient/SelectPatient';
import Start from './start/Start';

const Consultation: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.consultation.section); 

  // --

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '95vw',
      height: '95vh', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: colors?.interface,
      transition: 'left 0.3s',
      overflow: 'hidden'
    }}>

      {/* <SelectPatient /> */}

      <Start />

    </Box>
  );
};

export default Consultation;