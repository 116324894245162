import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Box } from '@mui/material';

// -- Components 

import Notification from '../components/general/Notification';
import Container from '../components/group/Container';
import NavBar from '../components/general/navBar/NavBar';
import Settings from '../components/doctor/navigation/navBar/Settings'; 
import { setSideBarMenu } from '../redux/features/general/navigation';
import menuItems from '../util/navigation/sidebarMenu/group';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/general/navigation/Sidebar';

const Group: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const { t } = useTranslation(); 

  const colors = useAppSelector(state => state.theme.colors);

  // -- 

  useEffect(() => { 

    dispatch(setSideBarMenu(menuItems(t))); 

  }, [t]); 

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText
      }}
    >

      <NavBar>
          <Settings /> 
      </NavBar>

      <Sidebar />

      <Container/>

      <Notification />

    </Box>
  );
};

export default Group;