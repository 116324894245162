import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { getGroupInfoWithId } from '../../../routes/general/authentication';

interface Props { 
    item: any; 
    index: number; 
}; 

const AccessRow: React.FC<Props> = ({ item, index }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [groupName, setGroupName] = useState(''); 

    // -- 

    const handleGroupInfo = async () => { 

        const res = await getGroupInfoWithId(item.groupId) as any;  

        if (res.data.status === 200) { 

            setGroupName(res.data.group.name); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (item) { 

            handleGroupInfo(); 
            
        }; 

    },[item]); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          width: '100%',
          height: 80, 
          backgroundColor: (index === 0) ? 'rgba(62, 87, 104, 0.3)' : 'transparent', 
          '&:hover': { backgroundColor: 'rgba(62, 87, 104, 0.6)' }
      }}>

        <Box
           sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: 60, 
            height: 60, 
            marginLeft: 2, 
            borderRadius: '50%', 
            backgroundColor: 'rgba(10,124,225, 0.4)',
           }} 
        >
            <Box component="img" sx={{ width: 30 }} src={require('../../../assets/icons/auth/group-icon.png')} />

        </Box>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                alignItems: 'center', 
                marginLeft: 2, 
                fontSize: 16, 
                color: 'rgb(216, 207, 207)'
            }}
        >{groupName}

        </Box>
        
      </Box>

  );

};

export default AccessRow;