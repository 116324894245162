import { StarsTwoTone } from '@mui/icons-material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface InitialState {
  language: string,
  theme: string, 
  securityPin: string, 
  notifications: { 
    disabled:  boolean, 
    securityAlerts:  boolean, 
    accessRequests:  boolean, 
    newPatientFile:  boolean, 
    newPatientNote:  boolean, 
    support:  boolean, 
    insurers:  boolean,
  },
  isRefreshDoctorPreferences: boolean, 
  isEditedDoctorPreferences: boolean, 
}

// ---------------

const initialState: InitialState = {
  language: '',
  theme: '', 
  securityPin: '',
  notifications: { 
    disabled: false, 
    securityAlerts: false, 
    accessRequests: false, 
    newPatientFile: false, 
    newPatientNote: false, 
    support: false, 
    insurers: false
  },
  isRefreshDoctorPreferences: false, 
  isEditedDoctorPreferences: false, 
};

// ---------------


export const doctorPreferencesSlice = createSlice({

  name: 'doctorPreferences',

  initialState,

  reducers: {

    setLanguage: (state, action: PayloadAction<string>) => {
        state.language = action.payload
    },
    setTheme: (state, action: PayloadAction<string>) => {
        state.theme = action.payload
    },
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload
    },
    setNotifications: (state, action: PayloadAction<any>) => {

        state.notifications.disabled= action.payload.disabled
        state.notifications.securityAlerts = action.payload.securityAlerts
        state.notifications.accessRequests = action.payload.accessRequests
        state.notifications.newPatientFile = action.payload.newPatientFile
        state.notifications.newPatientNote = action.payload.newPatientNote
        state.notifications.support = action.payload.support
        state.notifications.insurers = action.payload.insurers
    },
    setSecurityAlerts: (state, action: PayloadAction<boolean>) => {
        state.notifications.securityAlerts = action.payload
    },
    setAccessRequests: (state, action: PayloadAction<boolean>) => {
        state.notifications.accessRequests = action.payload
    },
    setNewPatientFile: (state, action: PayloadAction<boolean>) => {
        state.notifications.newPatientFile = action.payload
    },
    setNewPatientNote: (state, action: PayloadAction<boolean>) => {
        state.notifications.newPatientNote = action.payload
    },
    setSupport: (state, action: PayloadAction<boolean>) => {
        state.notifications.support = action.payload
    },
    setInsurers: (state, action: PayloadAction<boolean>) => {
        state.notifications.insurers = action.payload
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {

        if (action.payload) { 

            state.notifications.disabled= true; 

        } else { 

            state.notifications.disabled = false; 
            state.notifications.securityAlerts = false; 
            state.notifications.accessRequests = false; 
            state.notifications.newPatientFile = false; 
            state.notifications.newPatientNote = false; 
            state.notifications.support = false; 
            state.notifications.insurers = false; 

        }; 

    }, 
    setIsRefreshDoctorPreferences: (state, action: PayloadAction<boolean>) => {
        state.isRefreshDoctorPreferences = action.payload
    },
    setIsEditedDoctorPreferences: (state, action: PayloadAction<boolean>) => {
      state.isEditedDoctorPreferences = action.payload
    },
    resetDoctorPreferences: (state) => { 
        
      state.language = ''; 
      state.theme = ''; 
      state.securityPin = ''; 

      state.notifications.disabled = false; 
      state.notifications.securityAlerts = false; 
      state.notifications.accessRequests = false; 
      state.notifications.newPatientFile = false; 
      state.notifications.newPatientNote = false; 
      state.notifications.support = false; 
      state.notifications.insurers = false; 


    }, 
  },

}); 

// ---------------

export const { 
    setLanguage, 
    setTheme, 
    setSecurityPin, 
    setNotifications, 
    setDisabled, 
    setSecurityAlerts,
    setAccessRequests,
    setNewPatientFile,
    setNewPatientNote,
    setSupport,
    setInsurers, 
    setIsRefreshDoctorPreferences, 
    setIsEditedDoctorPreferences,
    resetDoctorPreferences } = doctorPreferencesSlice.actions;


export default doctorPreferencesSlice.reducer;