import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import getStatusColor from '../../../util/style/getStatusColor';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

const Info: React.FC = () => {

  const navigate = useNavigate(); 

  const accountInfo = useAppSelector(state => state.meta.accountInfo);
  const group = useAppSelector(state => state.doctorAccount.group); 
  const colors = useAppSelector(state => state.theme.colors);

  // -- 

  return (

    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
    }}>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 1,
                height: 50, 
                marginLeft: 10, 
                marginTop: 5,
                color: colors?.text,
            }}
        >
            <AccountCircleIcon sx={{ color: colors?.settingsAccountIcon}}/> 
            <Box sx={{ marginLeft: 2 }}>{accountInfo?.firstName} {accountInfo?.lastName}</Box>

        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 1,
                height: 50, 
                marginLeft: 10, 
                color: colors?.text,
            }}
        >
            <EmailIcon sx={{ color: colors?.settingsEmailIcon }}/> 
            <Box sx={{ marginLeft: 2 }}>{accountInfo?.email}</Box>

        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 1,
                height: 50, 
                marginLeft: 10, 
                color: colors?.text,
            }}
        >
            <HomeRepairServiceIcon sx={{ color: colors?.darkBlue }}/> 
            <Box sx={{ marginLeft: 2 }}>{group?.name}</Box>

        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 1,
                height: 50, 
                marginLeft: 10, 
                color: 'white',
            }}
        >
            <VerifiedUserIcon sx={{ color: colors?.settingsStatusIcon}}/> 
            <Box 
                sx={{ 
                    marginLeft: 2 

                }}>
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: getStatusColor(accountInfo?.accountStatus), 
                }}>
                    {accountInfo?.accountStatus}
                </strong>
            </Box>

        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 1,
                height: 50, 
                marginLeft: 10, 
                color: colors?.text,
            }}
        >
            <BrowserUpdatedIcon sx={{ color: colors?.settingsSoftwareVersion }}/> 
            <Box sx={{ marginLeft: 2 }}>{accountInfo?.version}</Box>

        </Box>



    </Box>

  );

};

export default Info;