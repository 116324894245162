import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import NoteRow from './NoteRow';
import { getAttachedFiles, getConsultation, getConsultationImages, getConsultationNotes, getConsultations } from '../../../../../../routes/doctor/consultation';
import { setAttachedFiles, setConsultationId, setImages, setNotes, setSection, setSelectedAttachedFile, setSelectedConsultation, setSelectedConsultationNote } from '../../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';

import ConsultationRow from './sections/ConsultationRow';
import CategoryRow from './sections/CategoryRow';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import FolderIcon from '@mui/icons-material/Folder';
import NoteRow from '../../../../../consultation/dashboard/sections/notes/NoteRow';
import { setConsultations, setIsRefreshConsultations, setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import FileRow from '../../../../../consultation/dashboard/sections/files/FileRow';
import ImageRow from '../../../../../consultation/dashboard/sections/images/ImageRow';
import { setIsImageViewerOpened, setSelectedImage } from '../../../../../../redux/features/doctor/Dashboard/documents/imageViewer';
import BoxView from './view/BoxView';
import { setIsNoteViewerOpened, setSelectedNote } from '../../../../../../redux/features/doctor/Dashboard/documents/noteViewer';
import { setIsFileViewerOpened, setSelectedFile } from '../../../../../../redux/features/doctor/Dashboard/documents/fileViewer';


const List: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.savedConsultation.consultationId); 
    const section = useAppSelector(state => state.savedConsultation.section); 
    const patientId = useAppSelector(state => state.savedConsultation.patientId);  
    const notes = useAppSelector(state => state.savedConsultation.notes);  
    const attachedFiles = useAppSelector(state => state.savedConsultation.attachedFiles);  
    const images = useAppSelector(state => state.savedConsultation.images);  
    const consultations = useAppSelector(state => state.fileExplorer.consultations); 
    const isRefreshConsultations = useAppSelector(state => state.fileExplorer.isRefreshConsultations); 
    const folderView = useAppSelector(state => state.fileExplorer.folderView);  

    const [numberOfItems, setNumberOfItems] = useState(0); 

    const [numberOfNotes, setNumberOfNotes] = useState(0); 
    const [numberOfFiles, setNumberOfFiles] = useState(0); 
    const [numberOfImages, setNumberOfImages] = useState(0); 

    // -- 

    const handleConsultations = async () => { 

        const res = await getConsultations(patientId) as any; 
        
        if (res.data.status === 200) { 

            dispatch(setConsultations(res.data.consultations)); 
            setNumberOfItems(res.data.consultations.length); 

        }; 

    }; 

    // - 

    const handleConsultation = async () => { 

        const res = await getConsultation(consultationId) as any; 
        
        if (res.data.status === 200) { 

            setNumberOfNotes(res.data.numberOfNotes); 
            setNumberOfFiles(res.data.numberOfFiles); 
            setNumberOfImages(res.data.numberOfImages); 

        }; 

    }; 

    // -- 

    const handleConsultationNotesList = async () => { 

        const res = await getConsultationNotes(consultationId) as any; 

        if (res.data.status === 200) { 

            dispatch(setNotes(res.data.consultationNotes)); 
            setNumberOfItems(res.data.consultationNotes.length); 

        }; 

    }; 

    // -- 

    const handleAttachedFiles = async () => { 

        const res = await getAttachedFiles(consultationId) as any; 

        if (res.data.status === 200) { 
            
            dispatch(setAttachedFiles(res.data.attachedFiles)); 
            setNumberOfItems(res.data.attachedFiles.length); 

        }; 

    }; 

    const handleImages = async () => { 

        const res = await getConsultationImages(consultationId) as any; 

        if (res.data.status === 200) { 

            dispatch(setImages(res.data.images)); 
            setNumberOfItems(res.data.images.length); 

        }; 

    }; 

    // -- 

    const handleSelection = async (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedConsultation(item)); 
            dispatch(setConsultationId(item._id)); 
            dispatch(setSection('categories')); 

        }; 

    }; 

    // -- 

    const handleCategorySelection = (e: any, category: string) => { 

        if (e.detail === 2) { 

            dispatch(setSection(category)); 

        }; 

    }; 

    // -- 

    const handleNoteSelection = (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setIsNoteViewerOpened(true)); 
            dispatch(setSelectedNote(item)); 

            dispatch(setSelectedConsultationNote(item)); 

            dispatch(setMode('viewNote')); 

        };

    }; 

    // -- 

    const handleFileSelection = (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedFile(item)); 
            dispatch(setIsFileViewerOpened(true)); 

        };

    }; 

    // -- 

    const handleImageSelection = (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedImage(item)); 
            dispatch(setIsImageViewerOpened(true)); 

        };

    }; 


    // -- 

    useEffect(() => { 

        if (section === 'consultations') { 

            handleConsultations(); 

        } else if (section === 'categories') { 

            handleConsultation(); 

        } else if (section === 'notes') { 

            handleConsultationNotesList(); 

        } else if (section === 'files') { 

            handleAttachedFiles(); 

        } else if (section === 'images') { 

            handleImages(); 

        }; 

    },[section]);

    // -- 

    useEffect(() => { 

        if (isRefreshConsultations) { 

            handleConsultations(); 

            dispatch(setIsRefreshConsultations(false)); 

        }; 

    },[isRefreshConsultations]); 

    // --

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: 'auto', 
            overflow: 'scroll',
            scrollbarWidth: 'none', 
        }}>

            { consultations?.length >= 1 && 

            <Box 
                
                sx={{ 
                    width: '100%',
                    height: '100%',
                }}>

                { ((section === 'consultations') && (folderView === 'row')) && 
                
                    <MuiList dense disablePadding>
            
                        {consultations?.map((item, index) => (
                
                            <ListItem 
                                onClick={(e) => handleSelection(e, item)} 
                                button 
                                key={index} 
                                disablePadding>

                                <ConsultationRow 
                                    doctorId={item.doctorId}
                                    updatedAt={new Date(item.updatedAt)}
                                />

                            </ListItem>

                        ))}
            
                    </MuiList>}

                    { ((section === 'consultations') && (folderView === 'box')) && 

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                                overflowWrap: 'normal',
                                width: '100%',
                                height: '100%', 
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                scrollbarWidth: 'none',
                                transition: 'width 0.3s',
                            }}
                        >

                        {consultations?.map((item, index) => (
                                
                                <Box 
                                    onClick={(e) => handleSelection(e, item)} 
                                    key={index} 
                                >

                                    <BoxView 
                                        index={index}
                                        item={item}
                                        type={'consultation'}
                                    />

                                </Box>

                            ))}

                        </Box>}

                    

                { ((section === 'categories') && (folderView === 'row')) && 
                
                <MuiList dense disablePadding>
        
                    <ListItem>
                        <CategoryRow category='notes' numberOfItems={numberOfNotes}>
                            <Box component="img" sx={{ width: 20, marginLeft: 1 }} src={require('../../../../../../assets/icons/note-thumbnail.png')} />
                        </CategoryRow>
                    </ListItem>

                    <ListItem>
                        <CategoryRow category='files' numberOfItems={numberOfFiles}>
                            <Box component="img" sx={{ width: 20, marginLeft: 1 }} src={require('../../../../../../assets/icons/pdf-thumbnail.png')} />
                        </CategoryRow>
                    </ListItem>

                    <ListItem>
                        <CategoryRow category='images' numberOfItems={numberOfImages}>
                            <Box component="img" sx={{ width: 20, marginLeft: 1 }} src={require('../../../../../../assets/icons/image-thumbnail.png')} />
                        </CategoryRow>
                    </ListItem>

                </MuiList>}

                { ((section === 'categories') && (folderView === 'box')) && 

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            overflowWrap: 'normal',
                            width: '100%',
                            height: '100%', 
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            transition: 'width 0.3s',
                        }}
                    >

                        <Box onClick={(e: any) => handleCategorySelection(e, 'notes')} >
                            <BoxView type={'notes'} numberOfItems={numberOfNotes} />
                        </Box>

                        <Box onClick={(e: any) => handleCategorySelection(e, 'files')} >
                            <BoxView type={'attachedFiles'} numberOfItems={numberOfFiles}/>
                        </Box>

                        <Box onClick={(e: any) => handleCategorySelection(e, 'images')}>
                            <BoxView type={'images'} numberOfItems={numberOfImages}/>
                        </Box>

                    </Box>}



                { ((section === 'notes') && (folderView === 'row')) && 
                
                <MuiList dense disablePadding>
        
                    {notes?.map((item, index) => (
            
                        <ListItem 
                            onClick={(e) => handleNoteSelection(e, item)} 
                            button 
                            key={index} 
                            disablePadding>

                            <NoteRow 
                                title={item.title}
                                updatedAt={item.updatedAt}
                            />

                        </ListItem>

                    ))}
        
                </MuiList>}

                { ((section === 'notes') && (folderView === 'box')) && 

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            overflowWrap: 'normal',
                            width: '100%',
                            height: '100%', 
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            transition: 'width 0.3s',
                        }}
                    >

                        {notes?.map((item, index) => (
                            
                            <Box 
                                onClick={(e) => handleNoteSelection(e, item)} 
                                key={index} 
                            >

                                <BoxView 
                                    index={index}
                                    item={item}
                                    type={'note'}
                                />

                            </Box>

                        ))}

                    </Box>}



                { ((section === 'files') && (folderView === 'row')) && 
                
                <MuiList dense disablePadding>
        
                    {attachedFiles?.map((item, index) => (
            
                        <ListItem 
                            onClick={(e) => handleFileSelection(e, item)} 
                            button 
                            key={index} 
                            disablePadding>

                            <FileRow 
                                title={item.title}
                                updatedAt={item.updatedAt}
                            />

                        </ListItem>

                    ))}
        
                </MuiList>}

                { ((section === 'files') && (folderView === 'box')) && 

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            overflowWrap: 'normal',
                            width: '100%',
                            height: '100%', 
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            transition: 'width 0.3s',
                        }}
                    >

                        {attachedFiles?.map((item, index) => (
                            
                            <Box 
                                onClick={(e) => handleFileSelection(e, item)} 
                                key={index} 
                            >

                                <BoxView 
                                    index={index}
                                    item={item}
                                    type={'file'}
                                />

                            </Box>

                        ))}

                    </Box>}


                { ((section === 'images') && (folderView === 'row')) && 
                
                <MuiList dense disablePadding>
        
                    {images?.map((item, index) => (
            
                        <ListItem 
                            onClick={(e) => handleImageSelection(e, item)} 
                            button 
                            key={index} 
                            disablePadding>

                            <ImageRow 
                                title={item.title}
                                updatedAt={item.updatedAt}
                            />

                        </ListItem>

                    ))}
        
                </MuiList>}


                { ((section === 'images') && (folderView === 'box')) && 

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            overflowWrap: 'normal',
                            width: '100%',
                            height: '100%', 
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            transition: 'width 0.3s',
                        }}
                    >

                        {images?.map((item, index) => (
                            
                            <Box 
                                onClick={(e) => handleImageSelection(e, item)} 
                                key={index} 
                            >

                                <BoxView 
                                    index={index}
                                    item={item}
                                    type={'image'}
                                />

                            </Box>

                        ))}

                    </Box>}

            </Box>}

            {(numberOfItems === 0) &&   
          
            <Box
              sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  height: '100%',
              }}>
          
              <Box
                  sx={{
                      width: 252,
                      height: 214, 
                      marginTop: '5%',
                  }}
                  component="img"
                  alt="Solutions Medca Inc."
                  src={require('../../../../../../assets/logos/empty_list.png')}
              />

              <Box sx={{ 
                  color: colors?.text,
                  fontSize: 12,
                  marginBottom: '20%',
              }}>{t('emptyFolder')}</Box>

            </Box>}
      

          </Box>

    );
};

export default List;