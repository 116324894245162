import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import SidebarRow from './SidebarRow';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Settings: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const currentTime = useAppSelector(state => state.consultation.currentTime); 

    // --

    const goToSettings = () => { 


    }; 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          position: 'absolute',
          bottom: 0, 
          fontSize: 14 
      }}>

        <SidebarRow 
            title='patientConsent'
            icon={<PersonPinOutlinedIcon sx={{ color: colors?.main }} />}
            handler={goToSettings}
            extraIcon={<ToggleOffOutlinedIcon sx={{ color: colors?.main }} />}
        />

        <SidebarRow 
            title='language'
            icon={<PublicOutlinedIcon sx={{ color: colors?.main }} />}
            handler={goToSettings}
            extraIcon={<Box sx={{ color: 'rgb(150, 150, 150)' }}>{'en-US'}</Box>}
        />

        <SidebarRow 
            title='microphone'
            icon={<MicNoneOutlinedIcon sx={{ color: colors?.main }} />}
            handler={goToSettings}
            extraIcon={<Box sx={{ color: 'rgb(150, 150, 150)' }}>{'Default'}</Box>}
        />

        <SidebarRow 
            title='settings'
            icon={<SettingsOutlinedIcon sx={{ color: colors?.main }} />}
            handler={goToSettings}
            extraIcon={<KeyboardArrowRightIcon sx={{ color: colors?.main }} />}
        />

      </Box>

  );

};

export default Settings;