import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { verifySecurityPin } from '../../../routes/general/authentication';

const Auth: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [error, setError] = useState(false) as any; 

    const [pin0, setPin0] = useState(''); 
    const [pin1, setPin1] = useState(''); 
    const [pin2, setPin2] = useState(''); 
    const [pin3, setPin3] = useState(''); 

    const pin0Ref = useRef() as any; 
    const pin1Ref = useRef() as any; 
    const pin2Ref = useRef() as any;  
    const pin3Ref = useRef() as any; 

    // -- 

    const textFieldStyle = { 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly', 
        width: 60, 
        height: 60, 
        fontSize: 18, 
        marginLeft: 0.25,
        marginRight: 0.25, 
        alignSelf: 'center',
        color: colors?.text,
        borderBottomColor: 'black',
        borderColor: 'rgb(10,197,207)',
        borderStyle: 'solid',
        borderRadius: 3, 
        borderWidth: 1, 
    }; 

    // -- 

    const handleSecurityPin = (number: any, order: number) => { 

        const reg = new RegExp('^[0-9\-/]+$'); 

        if (((number.length > 1) || !reg.test(number)) && number) { 
            return; 
        }; 

        order === 0 && setPin0(number); 
        order === 1 && setPin1(number); 
        order === 2 && setPin2(number); 
        order === 3 && setPin3(number); 

        if (number) { 
    
            order === 0 && pin1Ref.current.focus(); 
            order === 1 && pin2Ref.current.focus(); 
            order === 2 && pin3Ref.current.focus(); 

        } else  {

            order === 1 && pin0Ref.current.focus(); 
            order === 2 && pin1Ref.current.focus(); 
            order === 3 && pin2Ref.current.focus(); 

        };

        setError(false); 

    }; 

    // -- 

    const handleVerifySecurityPin = async () => { 

        const res = await verifySecurityPin(`${pin0}${pin1}${pin2}${pin3}`) as any; 

        if (res?.data?.status === 200) { 

            const previousLocation = localStorage.getItem('lockScreenRedirect'); 
            localStorage.setItem('isScreenLocked', 'off');

            previousLocation ? navigate(previousLocation) : navigate('/dashboard'); 

            localStorage.removeItem('lockScreenRedirect'); 

        } else if (res?.data?.status === 403) { 

            setError(true); 

        } else { 

            navigate('/login'); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (pin0 && pin1 && pin2 && pin3) { 

            handleVerifySecurityPin(); 

        }; 

    },[pin0, pin1, pin2, pin3]); 

    // -- 

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '30vw',
            height: '50vh', 
            top: '25vh', 
            borderRadius: 3, 
            backgroundColor: 'rgba(62, 87, 104, 0.2)',
            zIndex: 5,
            position: 'absolute',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>

            <Box sx={{ 
                color: 'rgb(204, 204, 204)',
                marginTop: '5vh',
                height: '10vh',
                flexShrink: 3,
                fontSize: 20, 
            }}>
                {t('securityPin')}
            </Box>

            {error && 
            <Box sx={{ 
                marginTop: 1,
                flexShrink: 3,
                fontSize: 15,
                color: colors?.red 
            }}>
                {t('invalidPin')}
            </Box>}

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '70%',
                    justifyContent: 'space-evenly', 
                    marginTop: 4, 
                }}
            >

                <TextField 
                    sx={textFieldStyle} 
                    inputProps={{ 
                        style: { 
                            textAlign: 'center',
                            height: 50, 
                            width: 50, 
                            fontSize: 20, 
                            textDecoration: 'none',
                            color: 'white',
                        }
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => handleSecurityPin(e.target.value, 0)}
                    autoComplete='off'
                    id="pin-0" 
                    value={pin0}
                    variant="standard" 
                    autoFocus
                    type='password'
                    inputRef={pin0Ref}
                />

                <TextField 
                    sx={textFieldStyle} 
                    inputProps={{ 
                        style: { 
                            textAlign: 'center',
                            height: 50, 
                            width: 50, 
                            fontSize: 20, 
                            textDecoration: 'none',
                            color: 'white',
                        }
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => handleSecurityPin(e.target.value, 1)}
                    autoComplete='off'
                    id="pin-1" 
                    value={pin1}
                    variant="standard" 
                    type='password'
                    inputRef={pin1Ref}
                />

                <TextField 
                    sx={textFieldStyle} 
                    inputProps={{ 
                        style: { 
                            textAlign: 'center',
                            height: 50, 
                            width: 50, 
                            fontSize: 20, 
                            textDecoration: 'none',
                            color: 'white',
                        }
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => handleSecurityPin(e.target.value, 2)}
                    autoComplete='off'
                    id="pin-2" 
                    value={pin2}
                    variant="standard" 
                    type='password'
                    inputRef={pin2Ref}
                />

                <TextField 
                    sx={textFieldStyle} 
                    inputProps={{ 
                        style: { 
                            textAlign: 'center',
                            height: 50, 
                            width: 50, 
                            fontSize: 20, 
                            textDecoration: 'none',
                            color: 'white',
                        }
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => handleSecurityPin(e.target.value, 3)}
                    autoComplete='off'
                    id="pin-3" 
                    value={pin3}
                    variant="standard" 
                    type='password'
                    inputRef={pin3Ref}
                />


            </Box>

            <Box
                component="img"
                sx={{
                    height: 70,
                    width: 70,
                    opacity: 0.3,
                    bottom: 20,
                    position: 'absolute'
                }}
                alt="Login Background"
                src={require('../../../assets/icons/auth/moonlight.png')}
            />

        </Box>

  );

};

export default Auth;