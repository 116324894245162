import React, { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AccessRow from './AccessRow';
import { selectGroupAccess } from '../../../routes/general/authentication';
import clearCookies from '../../../util/storage/clearCookies';

const AccessList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors);
    const accountInfo = useAppSelector(state => state.meta.accountInfo);  

    const groupAccessList = useAppSelector(state => state.login.groupAccessList); 

    // -- 

    const handleSelection = async (e: any, item: any) => { 

        const res = await selectGroupAccess(item.groupId) as any; 

        clearCookies(); 

        if (res.data.status == 200) { 

            const { token } = res.data;

            document.cookie = `jwt=${token}; path=/`;

            navigate('/dashboard'); 

        } else { 

            navigate('/login'); 

        }; 

    }; 

    // --

    useEffect(() => { 

        if (groupAccessList) { 

            if (groupAccessList?.length === 0) { 

                navigate('/login'); 

                window.location.reload();

            }; 

        }; 

    },[groupAccessList]); 
     
    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '50%',
          height: '60%',
          position: 'absolute', 
          top: '20vh',  
          borderRadius: 3, 
          zIndex: 5,
          backgroundColor: 'rgba(62, 87, 104, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}>

        { groupAccessList?.length >= 1 && 
        <Box sx={{ 
            overflow: 'auto',
            alignItems: 'center', 
            width: '90%',
            marginTop: '2vh'
         }}>
    
            <List dense sx={{ borderRadius: 3 }}>
    
            {groupAccessList?.map((item, index) => (
    
                <ListItem sx={{ width: '100%', color: colors?.text, alignItems: 'center' }} onClick={(e) => handleSelection(e, item)} button key={index} disablePadding>

                    <AccessRow item={item} index={index} />

                </ListItem>
            ))}
    
            </List>
    
        </Box>}


      </Box>

  );

};

export default AccessList;