import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import InfoBox from './InfoBox';
import AddIcon from '@mui/icons-material/Add';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { createConsultation } from '../../../../../../routes/doctor/consultation';
import { setConsultationId } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';

const Info: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // --

    const handleConsultationCreation = async () => { 

        const res = await createConsultation() as any; 

        if (res?.data.status === 200) { 

            console.log(res.data); 

            dispatch(setConsultationId(res.data.consultation._id)); 

            navigate(`/consultation/${res.data.consultation._id}`);

        }; 

    }; 

    // --

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '50%%',
          height: 'auto', 
          borderStyle: 'solid',
          borderColor: 'rgba(184, 184, 184, 0.5)', 
          borderWidth: 0.3,
          color: colors?.text,
          borderRadius: 2, 
          padding: 6, 
          fontSize: 14, 
      }}>

        <Box
            sx={{ 
                fontWeight: 'bold',
                fontSize: 18
            }}
        >{t('startConsultation')}
        </Box>

        <Box
            sx={{ 
                marginTop: 2,
                marginBottom: 3, 
            }}
        >{t('startConsultationInfo')}</Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 3,
                marginBottom: 3,  
                width: '90%', 
            }}
        >

            <InfoBox 
                icon={<AddIcon sx={{ color: colors?.main }} />}
                title={'create'}
                description={'createConsultationInfo'}
            />

            <InfoBox 
                icon={<GraphicEqIcon sx={{ color: colors?.main }} />}
                title={'discuss'}
                description={'discussConsultationInfo'}
            />

            <InfoBox 
                icon={<AutoAwesomeIcon sx={{ color: colors?.main }} />}
                title={'complete'}
                description={'completeConsultationInfo'}
            />

        </Box>

        <Box
            onClick={handleConsultationCreation}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                padding: 1, 
                fontSize: 16, 
                borderRadius: 1, 
                marginTop: 3, 
                paddingLeft: 2,
                paddingRight: 2, 
                height: 40,
                color: 'white', 
                cursor: 'pointer', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: colors?.main, 
                '&:hover': { backgroundColor: colors?.mainHover }
            }}
        >

            <AddIcon sx={{ color: 'white', marginRight: 1 }} />

            <Box>{t('createConsultation')}</Box>

        </Box>

      </Box>

  );

};

export default Info;