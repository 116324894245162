import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import moment from 'moment';
import Button from '../../../../../general/inputs/Button';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [createdAt, setCreatedAt] = useState(''); 

    const saveNote = async () => { 


    }; 

    // -- 

    useEffect(() => { 

        const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 

        setCreatedAt(moment.utc(date).format('LT')); 

    },[]); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto',
          top: '1%', 
          position: 'absolute', 
          backgroundColor: colors?.container,
      }}>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                width: '95%',
                marginTop: 1, 
                color: colors?.main,
            }}
        >

            <Box>

                <DownloadOutlinedIcon sx={{ marginRight: 2 }} />

                <LocalPrintshopOutlinedIcon sx={{ marginRight: 2 }} />

                <ContentCopyOutlinedIcon sx={{ marginRight: 2, fontSize: 23 }} />

                <DeleteOutlineOutlinedIcon sx={{ marginRight: 2 }} />
            
            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    height: '100%',
                }}
            >                
            
                <ShareOutlinedIcon sx={{ marginRight: 2 }} /> 

                <Button 
                    title='save'
                    triggerFunction={saveNote}
                    extraStyle={{ marginTop: 0 }}
                />

            </Box>

        </Box>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                width: '95%',
                marginTop: 2, 
                color: colors?.main
            }}
        >

            <Box 
                sx={{ 
                    color: colors?.text
                }}
            >Note Title</Box>

            <Box
                sx={{ 
                    fontSize: 14, 
                    color: 'rgb(150, 150, 150)'
                }}
            >{createdAt}

            </Box>

        </Box>

      </Box>

  );

};

export default Header;