import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setFile, setIsFileUploaded } from '../../../../redux/features/doctor/Dashboard/documents/uploadFile';


// Icons 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { attachFileToConsultation } from '../../../../routes/doctor/consultation';
import { setIsNewFileAttached } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../redux/features/general/popup';

const UploadFileButton: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    const fileInput = document.getElementById("consultation-file-upload") as any; 

    const [uploadedFile, setUploadedFile] = useState(null); 

    // --

    fileInput?.addEventListener("change", () => {

        setUploadedFile(fileInput.files[0]);

    });

    // -- 

    const handleUpload = async () => { 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('uploadFile')); 

        const formData = new FormData() as any; 

        formData.append('file', uploadedFile); 
        formData.append('consultationId', consultationId); 

        const res = await attachFileToConsultation(formData) as any;  

        dispatch(setClosePopup()); 

        if (res.status === 200) { 

            fileInput.value = null; 

            dispatch(setIsNewFileAttached(true)); 

        }; 
    }; 

    // -- 

    useEffect(() => { 

        if (uploadedFile) { 

            handleUpload(); 

        }; 

    },[uploadedFile]); 

    // -- 

    return (

        <Button
            component="label"
            sx={{ 
                all: 'initial',                    
                width: 100, 
                height: 30,
                position: 'absolute', 
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'transparent'},
                zIndex: 1}}
            >
                <input
                    id="consultation-file-upload"
                    type="file"
                    accept="application/pdf"
                    hidden
                />
        </Button>

    );

};

export default UploadFileButton;