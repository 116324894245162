import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import Navigation from './navigation/Navigation';
import Sections from './sections/Sections';

const Sidebar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          width: 'auto', 
          height: '90vh',
          bottom: 0, 
          left: 0, 
          zIndex: 5000, 
          position: 'absolute',
          backgroundColor: 'rgb(255, 255, 255)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}>

        <Navigation /> 

        <Sections />

      </Box>

  );

};

export default Sidebar;