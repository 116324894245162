import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';

// Icons 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import getStatusColor from '../../../util/style/getStatusColor';
import { getGroup, getGroupsByDoctor } from '../../../routes/hacker/users';
import { useAppSelector } from '../../../redux/hooks';

type Props = {
  _id: string; 
  firstName?: string,
  lastName?: string,
  email: string,
  team?: string,
  lastActive?: string, 
  accountStatus?: string, 
  groupId: string; 
  version: string; 
};


const DoctorRow: React.FC<Props> = ({_id, firstName, lastName, accountStatus, email, team, lastActive, groupId, version }) => {

  const colors = useAppSelector(state => state.theme.colors); 
  const selectedDoctor = useAppSelector((state) => state.doctorsAccess.selectedDoctor); 

  const [groupsNumber, setGroupsNumber] = useState(0); 
  const [latestGroup, setLatestGroup] = useState(''); 

  // -- 

  const handleGroup = async () => { 

    const res = await getGroupsByDoctor(_id) as any; 

    if (res?.data.status === 200) { 

      setGroupsNumber(res.data.groupAccessList.length);

      const result = await getGroup(res.data.groupAccessList[0].groupId) as any;
      
      if (result?.data.status === 200) { 

        setLatestGroup(result.data.group.name);

      }; 

    }; 

  }; 

  // -- 

  useEffect(() => { 

    handleGroup(); 

  },[groupId]); 

  return (

    <Box 
      sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
      backgroundColor: (selectedDoctor?._id == _id) ? colors?.sectionHover : 'transparent', 
      '&:hover': { backgroundColor: colors?.sectionHover }
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '13%',
                marginLeft: '2%',  
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            <div style={{ 
                width: '20%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{email}</div>

            <div style={{ 
                width: '25%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{latestGroup}{groupsNumber > 1 ? ` (${(groupsNumber)})` : ''}</div>

            <div style={{ 
                width: '15%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{lastActive ? moment.utc(lastActive).format('LL') : '--'}</div>

            <div style={{ 
                width: '10%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{version}</div>

            <div style={{ 
                width: '10%', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: getStatusColor(accountStatus), 
                    color: 'white', 
                }}>
                    {accountStatus}
                </strong>
            </div>

            <div style={{ 
                width: '5%', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                <Box
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    '&:hover': { backgroundColor: 'rgba(45, 153, 207, 0.6)'}
                  }}
                >
                  <MoreVertIcon></MoreVertIcon>
                </Box>

            </div>


    </Box>

  );

};

export default DoctorRow;