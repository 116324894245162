import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Routes 

import { getGroupList } from '../../../../routes/doctor/account';
import { setDashboardSection } from '../../../../redux/features/general/navigation';
import { setGroupAccessList, setInitialRendering, setSelectedGroupAccess, setIsRefreshGroupAccessList } from '../../../../redux/features/doctor/group/groupAccess';

// Components 

import Legend from './Legend';
import GroupAccessRow from './GroupAccessRow';
import LinearProgress from '@mui/material/LinearProgress';

// import Dropdown from './Dropdown';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const GroupAccessList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);  

    const isRefreshGroupAccessList = useAppSelector(state => state.groupAccess.isRefreshGroupAccessList);  
    const selectedGroupAccess = useAppSelector(state => state.groupAccess.selectedGroupAccess);  
    const groupAccessList = useAppSelector(state => state.groupAccess.groupAccessList);  
    const initialRendering = useAppSelector(state => state.groupAccess.initialRendering);  
    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    const [postionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const getGroupAccessList = async () => { 

      const res = await getGroupList() as any; 

      if (res.data.status === 200) { 

        dispatch(setGroupAccessList(res.data.groupAccessList)); 

      };

    }; 

    // -- 

    const handleSelection = (e: any, groupAccess: any) => { 

      if (selectedGroupAccess) { 

        if (selectedGroupAccess?._id == groupAccess?._id) { 

          dispatch(setSelectedGroupAccess(null)); 

          return; 
  
        }; 

      }; 

      setPositionX(e.pageX);
      setPositionY(e.pageY); 

      dispatch(setSelectedGroupAccess(groupAccess)); 

    }; 

    // -- 

    const changeSection = () => { 

      dispatch(setDashboardSection('createGroupAccess')); 

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getGroupAccessList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

      if (isRefreshGroupAccessList) { 

        getGroupAccessList(); 

        dispatch(setIsRefreshGroupAccessList(false)); 

      }; 

    },[isRefreshGroupAccessList]); 

    // -- 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              width: '95vw',
              position: 'absolute',
              bottom: 0,
              right: 0, 
              text: colors?.text, 
              backgroundColor: colors?.container,
            }}>            
            
            <Legend />

            { groupAccessList?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {groupAccessList?.map((item, index) => (
        
                    <ListItem sx={{ color: colors?.text }} onClick={(e) => handleSelection(e, item)} button key={index}>

                        <GroupAccessRow item={item} /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {groupAccessList?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly', 
              height: '100%',
              width: '80%', 
          }}>

              <Box
                  sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}>

                  <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                  <Box>{t('retrievingGroupAccess')}</Box>

              </Box>

          </Box>}

          {/* { selectedGroupAccess && <Dropdown positionX={postionX} positionY={positionY - 30} />} */}

          <AddCircleIcon 
            onClick={changeSection}
            sx={{ 
              width: 70,
              height: 70, 
              color: 'rgb(45, 207, 196)', 
              position: 'absolute', 
              bottom: 30, 
              right: 30, 
              cursor: 'pointer',
              '&:hover' : { color: 'rgb(65, 227, 216)'}
            }}
          />
        
            </Box>


        </Box>
    
      );

};

export default GroupAccessList;