import genderOptions from '../../components/doctor/dashboard/patients/createPatient/categories/options/genderOptions';

export const validateGenderInput = (input: string) => { 

    const selectedGender = genderOptions.filter((item: any) => { 

        if (item.stringDisplayed == input) { 
            return item;    
        }; 
    }); 

    if (selectedGender?.length > 0) { 
        return true; 
    } else { 
        return false; 
    };

}; 

// -- 

export const validateBirthDateInput = (date: string) => { 

    const inputDate = new Date(date); 

    const today = new Date();

    return (inputDate.getTime() < today.getTime()) ? true : false; 

}; 