import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Action from '../Action';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';

const FileDropdown: React.FC = () => {

    const { t } = useTranslation(); 

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                cursor: 'pointer',
            }}
        >

            <Action title={'viewFile'} action={'viewFile'} type='dropdown'>
                <RemoveRedEyeOutlinedIcon /> 
            </Action>

            <Action title={'renameFile'} action={'renameFile'} type='dropdown'>
                <DriveFileRenameOutlineIcon /> 
            </Action>

            <Action title={'moveTo'} action={'moveFile'} type='dropdown'>
                <DriveFileMoveOutlinedIcon /> 
            </Action>

            { selectedFile?.relatedPatient && 
            <Action title={'detachPatient'} action={'detachFileFromPatient'} type='dropdown'>
                <PersonRemoveOutlinedIcon /> 
            </Action>}
            

            <Action title={'deleteFile'} action={'deleteFile'} type='dropdown'>
                <DeleteOutlineIcon /> 
            </Action>

        </Box>

    )

}; 

export default FileDropdown; 