import React, { useState, useEffect, useRef  } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux
import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, setErroredField, removeErroredField, setSectionSelected, setEditedPatientChange, setErroredCategory, removeErroredCategory } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setFirstName, setLastName, setGender, setDateOfBirth, setSection, setCompletedField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputHeader from './items/InputHeader';
import BottomSpace from './items/BottomSpace';
import InputRow from './items/InputRow';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

// Styles 

import iconStyle from './styles/iconStyle';
import inputPropsStyle from './styles/inputPropsStyle';
import textFieldStyle from './styles/textFieldStyle';

// Options 

import genderOptions from './options/genderOptions';

// Utils 

import getFormattedDateOfBirth from '../../../../../../util/dashboard/doctor/patient/getFormattedDateOfBirth';
import { validateBirthDateInput, validateGenderInput } from '../../../../../../util/patients/inputValidation';

const PatientInfo: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const nextSection = useAppSelector((state) => state.createPatient.nextSection); 

    const sectionSelected = useAppSelector((state) => state.createPatient.sectionSelected)

    const firstName = useAppSelector((state) => state.createPatient.firstName); 
    const lastName = useAppSelector((state) => state.createPatient.lastName); 
    const gender = useAppSelector((state) => state.createPatient.gender); 
    const dateOfBirth = useAppSelector((state) => state.createPatient.dateOfBirth); 

    const [firstNameError, setFirstNameError] = useState(''); 
    const [lastNameError, setLastNameError] = useState(''); 
    const [genderError, setGenderError] = useState(''); 
    const [dateOfBirthError, setDateOfBirthError] = useState('');

    const [isGenderFocused, setIsGenderFocused] = useState(false); 


    const firstNameRef = useRef() as any; 
    const lastNameRef = useRef() as any; 
    const genderRef = useRef() as any;  
    const dateOfBirthRef = useRef() as any; 

    // Handle input and error validation

    const handleInput = (input: string, field: string) => { 

        if (field === 'firstName') { 

            dispatch(setFirstName(input)); 

        } else if (field === 'lastName') { 

            dispatch(setLastName(input)); 

        } else if (field === 'gender') { 

            (validateGenderInput(input)) ? 
                dispatch(setGender({ _id: input, stringDisplayed: input })) : dispatch(setErroredField({ field: 'gender', error: 'invalidInput' }));

        } else if (field === 'dateOfBirth') { 

            const reg = new RegExp('^[0-9\-/]+$'); 

            if (reg.test(input)) { 

                (validateBirthDateInput(input)) ? 
                    dispatch(setDateOfBirth(getFormattedDateOfBirth(input))) : dispatch(setErroredField({ field: 'dateOfBirth', error: 'invalidDate' }));

            } else { 

                dispatch(setErroredField({ field: 'dateOfBirth', error: 'invalidDate' }));

            }; 

        };

        dispatch(setEditedPatientChange(true)); 
        
    };

    // -- 

    const confirmInput = (key: string, field: string) => { 

        if (key == 'Enter') { 

            if (field === 'firstName') { 

                lastNameRef.current.focus(); 

                !firstName && dispatch(setErroredField({ field: 'firstName', error: 'requiredField' })); 
                
            } else if (field === 'lastName') { 

                genderRef.current.focus(); 

                !lastName && dispatch(setErroredField({ field: 'lastName', error: 'requiredField' }));
                
            } else if (field === 'gender') { 

                !editedPatient && dateOfBirthRef.current.focus(); 

                !gender && dispatch(setErroredField({ field: 'gender', error: 'requiredField' }));
               
            } else if (field === 'dateOfBirth') { 

                !dateOfBirth && dispatch(setErroredField({ field: 'dateOfBirth', error: 'requiredField' }));

                if (section !== 'confirmation') { 

                    dispatch(setSection(nextSection)); 

                };
                
            }; 

        };

    };
    
    // -- 

    useEffect(() => { 

        setFirstNameError('');
        setLastNameError('');
        setGenderError('');
        setDateOfBirthError('');

        if (erroredFields.length > 0) { 

            const fields = ['firstName', 'lastName', 'gender', 'dateOfBirth'];

            const sectionErrors = erroredFields.filter((item: any) => { 

                if (fields.includes(item.field)) { 

                        return item; 
                }; 

            }); 

            (sectionErrors?.length > 0) ? dispatch(setErroredCategory('patientInfo')) : dispatch(removeErroredCategory('patientInfo')); 

            erroredFields.forEach((item: any) => { 

                if (item.field === 'firstName') { 

                    setFirstNameError(item.error); 

                } else if (item.field === 'lastName') { 

                    setLastNameError(item.error); 

                } else if (item.field === 'gender') { 

                    setGenderError(item.error); 

                } else if (item.field === 'dateOfBirth') { 

                    setDateOfBirthError(item.error); 

                };

                if (fields.includes(item.field) && (item.error === 'requiredField')) { 

                    dispatch(setErroredCategory('patientInfo')); 

                }; 

            });

        }; 

    },[erroredFields]); 

    // -- 

    useEffect(() => { 

        if (document.activeElement === genderRef.current) { 

            setIsGenderFocused(true); 

        } else { 

            setIsGenderFocused(false); 

        }; 

    },[document.activeElement, genderRef]);

    // -- 

    useEffect(() => { 

        if (dateOfBirth) { 

            const inputDate = new Date(dateOfBirth); 

            const today = new Date(); 

            if (inputDate.getTime() > today.getTime()) { 

                dispatch(setErroredField({ field: 'dateOfBirth', error: 'invalidDate' }));

            }; 

        }; 

    },[dateOfBirth]); 

    // -- 

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: section === 'confirmation' ? 'auto' : '95%',
        }}>

            <InputHeader title={'firstName'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/firstName.png')} />} 
                iconColor='rgb(45, 207, 196)' 
                required 
                isDisabled={editedPatient ? true : false}
                error={firstNameError} 
                value={firstName}>

                <TextField
                    value={firstName}
                    autoFocus={editedPatient ? false: true}
                    placeholder={String(t('firstName'))}
                    onChange={(e) => handleInput(e.target.value, 'firstName')}
                    variant="standard"
                    autoComplete='off'
                    disabled={editedPatient ? true : false}
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'firstName')}
                    inputRef={firstNameRef}
                />
            </InputRow>


            <InputHeader title={'lastName'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/lastName.png')} />} 
                iconColor='rgb(207, 137, 45)' 
                required 
                isDisabled={editedPatient ? true : false}
                error={lastNameError} 
                value={lastName}>

                <TextField
                    value={lastName}
                    placeholder={String(t('lastName'))}
                    onChange={(e) => handleInput(e.target.value, 'lastName')}
                    variant="standard"
                    autoComplete='off'
                    disabled={editedPatient ? true : false}
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'lastName')}
                    inputRef={lastNameRef}
                />
            </InputRow>

            <InputHeader title={'sex'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/sex.png')} />} 
                iconColor='rgb(188, 45, 207)' 
                value={gender}
                required 
                dataList={genderOptions} 
                isFocused={isGenderFocused} 
                action={'gender'} 
                error={genderError}>

                <TextField
                    value={gender ? gender?.stringDisplayed : ''}
                    placeholder={String(t('pressSelectToContinue'))}
                    onChange={(e) => handleInput(e.target.value, 'gender')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'gender')}
                    inputRef={genderRef}
                />
            </InputRow>

            <InputHeader title={'dateOfBirth'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/dateOfBirth.png')} />} 
                iconColor='rgb(45, 207, 62)'
                required 
                isDisabled={editedPatient ? true : false}
                value={dateOfBirth}
                isCustomSelection
                action={'dateOfBirth'}
                error={dateOfBirthError}>

                <TextField
                    value={dateOfBirth}
                    placeholder={String(t('pressSelectToContinue'))}
                    onChange={(e) => handleInput(e.target.value, 'dateOfBirth')}
                    variant="standard"
                    autoComplete='off'
                    disabled={editedPatient ? true : false}
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'dateOfBirth')}
                    inputRef={dateOfBirthRef}
                />
            </InputRow>

            <BottomSpace /> 

        </Box>
    );
};

export default PatientInfo;
