import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setDashboardSection } from '../../../../redux/features/general/navigation';
import { setPatients, setIsRefreshPatientsList, setSelectedPatient, setInitialRendering } from '../../../../redux/features/hacker/users/patientsAccess';

// Routes 

import { getPatients } from '../../../../routes/hacker/users';


// Components 

import Legend from './Legend';
import PatientRow from './PatientRow';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dropdown from './Dropdown';
import LinearProgress from '@mui/material/LinearProgress';

const PatientsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);  

    const isRefreshPatientsList = useAppSelector(state => state.patientsAccess.isRefreshPatientsList);  
    const selectedPatient = useAppSelector(state => state.patientsAccess.selectedPatient);  
    const patients = useAppSelector(state => state.patientsAccess.patients);  
    const initialRendering = useAppSelector(state => state.patientsAccess.initialRendering);  

    const [postionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const getPatientsList = async () => { 

      const res = await getPatients() as any; 

      if (res.data.status === 200) { 

        dispatch(setPatients(res.data.patients)); 

      };

    }; 

    // -- 

    const handleSelection = (e: any, patient: any) => { 

      if (selectedPatient) { 

        if (selectedPatient?._id == patient?._id) { 

          dispatch(setSelectedPatient(null)); 

          return; 
  
        }; 

      }; 

      setPositionX(e.pageX);
      setPositionY(e.pageY); 

      dispatch(setSelectedPatient(patient)); 

    }; 

    // -- 

    const changeSection = () => { 

      dispatch(setDashboardSection('createPatient')); 

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getPatientsList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

      if (isRefreshPatientsList) { 

        getPatientsList(); 

        dispatch(setIsRefreshPatientsList(false)); 

      }; 


    },[isRefreshPatientsList]); 

    // -- 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              width: '95vw',
              overflow: 'hidden', 
              text: colors?.text, 
              backgroundColor: colors?.container,
            }}>            
            
            <Legend />

            { patients?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {patients?.map((item, index) => (
        
                    <ListItem sx={{ color: colors?.text }} onClick={(e) => handleSelection(e, item)} button key={index}>

                        <PatientRow 
                          firstName={item.firstName}
                          lastName={item.lastName}
                          type={item.accountStatus}
                          email={item?.contactInfo?.email}
                          createdAt={moment.utc(item.createdAt).format('LL')}
                          accountStatus={item.accountStatus} 
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {patients?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly', 
              height: '100%',
              width: '80%', 
          }}>

              <Box
                  sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}>

                  <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                  <Box>Retrieving Patients...</Box>

              </Box>

          </Box>}

          { selectedPatient && <Dropdown positionX={postionX} positionY={positionY - 30} />}

          <AddCircleIcon 
            onClick={changeSection}
            sx={{ 
              width: 70,
              height: 70, 
              color: 'rgb(45, 207, 196)', 
              position: 'absolute', 
              bottom: 30, 
              right: 30, 
              cursor: 'pointer',
              '&:hover' : { color: 'rgb(65, 227, 216)'}
            }}
          />
        
            </Box>

        </Box>
    
      );

};

export default PatientsList;