import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Components 

import SubmitButton from './SubmitButton';
import Access from './Access';
import BackButton from './BackButton';
import FormField from '../../../general/inputs/FormField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { setEmail, setFirstName, setLastName } from '../../../../redux/features/hacker/users/createHacker';


const CreateHacker: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors); 

  return (

    <div style={{ 
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      color: colors?.text, 
  }}>

      <BackButton /> 

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: 600,
          scrollbarWidth: 'none',
          backgroundColor: colors?.interface,
          borderRadius: 4,
          paddingTop: 2, 
          marginBottom: 3, 
          marginTop: 3
        }}
      >

        <FormField 
          icon={<AccountCircleIcon sx={{ color: colors?.firstName }} />}
          section={'firstName'}
          isFocused
          dispatchFunction={setFirstName}
        />

        <FormField 
          icon={<AccountCircleIcon sx={{ color: colors?.lastName }} />}
          section={'lastName'}
          isFocused
          dispatchFunction={setLastName}
        />

        <FormField 
          icon={<MarkEmailReadIcon sx={{ color: colors?.email }} />}
          section={'email'}
          isFocused
          dispatchFunction={setEmail}
        />

        <Access /> 

        <SubmitButton />  

        </Box>

    </div>

  );

};

export default CreateHacker;