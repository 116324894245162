import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Redux 

import { setGender, setLanguagePreference } from '../../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components

import OptionRow from './OptionRow';


type Props = {
    dataList: any[], 
    action: any, 
};

const OptionsList: React.FC<Props> = ({ dataList, action }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);


    // -- 

    const handleSelection = (item: any) => { 

        if (action === 'gender') {

            dispatch(setGender(item)); 

        } else if (action === 'languagePreference') { 

            dispatch(setLanguagePreference(item)); 

        }; 

    }; 

    // -- 

    return (

        <Box sx={{ 
            width: '100%', 
            backgroundColor: colors?.createPatientOptionsList,
            borderRadius: 3, 
            fontSize: 12,
            position: 'relative',
            marginBottom: 3, 
            zIndex: 20,
            left: 0,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',  
        }}>

            { dataList?.length >= 1 && 

            <Box 
                
                sx={{ 
                width: '100%',
                height: 'auto',
                scrollbarWidth: 'none' }}>

                <List dense disablePadding>

                {dataList?.map((item, index) => (

                    <ListItem onClick={() => handleSelection(item)} button key={index} disablePadding>

                        <OptionRow option={item.stringDisplayed} />

                    </ListItem>

                ))}

                </List>

            </Box>}
                    
        </Box>
  );
};

export default OptionsList;