import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import { setIsOptionsListOpened, setIsSettingsListOpened } from '../../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

const Settings: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // --

    const handleSettings = () => { 

      dispatch(setIsOptionsListOpened(true)); 

    }; 

    // -- 

    return (

      <Box 
        onClick={handleSettings}
      sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: 50,
            height: 50,
            borderRadius: '50%', 
            overflow: 'hidden',  
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors?.avatarBorder,
            backgroundColor: colors?.microphoneOption
      }}>

        <SettingsIcon sx={{ color: colors?.microphoneOptionIcon }} /> 

    </Box>


  );

};

export default Settings;