import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setIsFieldsListOpened, setIsOptionsListOpened, setLanguage, setMode, setSelectedNoteField } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import { editConsultationNote } from '../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../redux/features/general/notification';

interface Props { 
  index: number; 
  noteField: any; 
}

const FieldRow: React.FC<Props> = ({ index, noteField }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const { i18n } = useTranslation();

    const { consultationId, consultationNoteId, securityPin } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // -- 

    const handleField = async () => { 

        dispatch(setSelectedNoteField(noteField));  

        const content = { 
            consultationNoteId: consultationNoteId, 
            mode: 'autoComplete', 
            securityPin: securityPin, 
          }; 
  
        await editConsultationNote(content) as any; 

        dispatch(setMode('autoComplete')); 

        dispatch(setIsFieldsListOpened(false)); 

    }; 

    // -- 

    return (

      <Box 
        onClick={handleField}
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100vw',
            paddingLeft: 2, 
            paddingRight: 2, 
            height: 60, 
            backgroundColor: colors?.container, 
        }}>

        <Box
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
          }}
        >

          <Box
            sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: 35, 
              height: 35,
              color: 'white', 
              backgroundColor: colors?.lightBlue, 
              borderRadius: '50%', 
            }}
          >
            {index + 1}
          </Box>

          <Box 
            sx={{ 
              marginLeft: 3, 
            }}
          >{noteField?.title}
          </Box>

        </Box>

      </Box>

  );

};

export default FieldRow;