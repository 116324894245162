import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';

// Icons 

import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
  firstName?: string,
  lastName?: string,
  type?: string,
  email: string,
  team?: string,
  createdAt?: string, 
  accountStatus: string, 
};


const PatientRow: React.FC<Props> = ({firstName, lastName, type, email, team, createdAt, accountStatus }) => {

  const colors = useAppSelector(state => state.theme.colors); 
  
  return (

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
      '&:hover': { backgroundColor: colors?.sectionHover }
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '23%', 
                marginLeft: '2%',                  
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            <div style={{ 
                width: '20%',                  
                textAlign: 'left',
                overflow: 'hidden',
            }}>{email}</div>

            <div style={{ 
                width: '45%',                  
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

            <div style={{ 
                width: '10%',                 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                <Box
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    '&:hover': { backgroundColor: 'rgba(45, 153, 207, 0.6)'}
                  }}
                >
                  <MoreVertIcon></MoreVertIcon>
                </Box>

            </div>


    </Box>

  );

};

export default PatientRow;