import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConsultationState {
    consultationId: string; 
    section: string;
    category: string; 
    patientId: string; 
    patientName: string; 
    securityPin: string; 
    currentTime: string; 
    patientsList: any[]; 
    notes: any[]; 
    attachedFiles: any[]; 
    images: any[]; 
    templates: any[]; 
    selectedConsultationNote: any;
    selectedAttachedFile: any;  
    selectedImage: any; 
    isNewNoteCreated: boolean; 
    isNewFileAttached: boolean; 
    isNewImageUploaded: boolean; 
    isNewTemplateCreated: boolean; 
    initialRendering: boolean; 
    mouseInfo: any; 
    selectPatientSearchQuery: string; 
    isImageSlideOpened: boolean; 
    isRefreshContent: boolean; 
    selectedTemplate: any; 
    templateOptionsXPosition: number, 
    templateOptionsYPosition: number, 
}

const initialState: ConsultationState = {
    consultationId: '',
    section: 'selectPatient', 
    category: 'actions', 
    patientId: '',
    patientName: '', 
    securityPin: '', 
    currentTime: '', 
    patientsList: [],
    notes: [], 
    attachedFiles: [], 
    images: [], 
    templates: [], 
    selectedConsultationNote: null, 
    selectedAttachedFile: null, 
    selectedImage: null, 
    isNewNoteCreated: false, 
    isNewFileAttached: false, 
    isNewImageUploaded: false, 
    isNewTemplateCreated: false, 
    initialRendering: true,  
    mouseInfo: null, 
    selectPatientSearchQuery: '', 
    isImageSlideOpened: false, 
    isRefreshContent: false, 
    selectedTemplate: null, 
    templateOptionsXPosition: 0, 
    templateOptionsYPosition: 0, 
};

const consultationSlice = createSlice({
  name: 'consultation',
  initialState,
  reducers: {
    setConsultationId: (state, action: PayloadAction<string>) => {
      state.consultationId = action.payload;
    },
    setSection: (state, action: PayloadAction<string>) => {
      state.section = action.payload;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload;
    },
    setPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload;
    }, 
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload;
    }, 
    setCurrentTime: (state, action: PayloadAction<string>) => { 
      state.currentTime = action.payload; 
    }, 
    setPatientsList: (state, action: PayloadAction<any>) => {
      state.patientsList = action.payload;
    },
    setNotes: (state, action: PayloadAction<any>) => {
      state.notes = action.payload;
    },
    setAttachedFiles: (state, action: PayloadAction<any>) => {
      state.attachedFiles = action.payload;
    },
    setImages: (state, action: PayloadAction<any>) => {
      state.images = action.payload;
    },
    setTemplates: (state, action: PayloadAction<any>) => {
      state.templates = action.payload;
    },
    setSelectedConsultationNote: (state, action: PayloadAction<any>) => {
      state.selectedConsultationNote = action.payload; 
    },
    setSelectedAttachedFile: (state, action: PayloadAction<any>) => {
      state.selectedAttachedFile = action.payload; 
    },
    setSelectedImage: (state, action: PayloadAction<any>) => {
      state.selectedImage = action.payload; 
    },
    setIsNewNoteCreated: (state, action: PayloadAction<boolean>) => {
      state.isNewNoteCreated = action.payload;
    },
    setIsNewFileAttached: (state, action: PayloadAction<boolean>) => {
      state.isNewFileAttached = action.payload;
    },
    setIsNewImageUploaded: (state, action: PayloadAction<boolean>) => {
      state.isNewImageUploaded = action.payload;
    },
    setIsNewTemplateCreated: (state, action: PayloadAction<boolean>) => {
      state.isNewTemplateCreated = action.payload;
    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
      state.initialRendering = action.payload;
    },
    setMouseInfo: (state, action: PayloadAction<any>) => { 
      state.mouseInfo = action.payload
    }, 
    setSelectPatientSearchQuery: (state, action: PayloadAction<string>) => { 
      state.selectPatientSearchQuery = action.payload
    }, 
    setIsImageSlideOpened: (state, action: PayloadAction<boolean>) => { 
      state.isImageSlideOpened = action.payload
    }, 
    setIsRefreshContent: (state, action: PayloadAction<boolean>) => { 
      state.isRefreshContent = action.payload
    }, 
    setSelectedTemplate: (state, action: PayloadAction<any>) => { 
      state.selectedTemplate = action.payload
    }, 
    setTemplateOptionsXPosition: (state, action: PayloadAction<number>) => { 
      state.templateOptionsXPosition = action.payload
    }, 
    setTemplateOptionsYPosition: (state, action: PayloadAction<number>) => { 
      state.templateOptionsYPosition = action.payload
    }, 
    resetConsultation: (state) => { 
        
      state.consultationId = '';
      state.section = 'selectPatient';
      state.category = 'actions'; 
      state.patientId = '';
      state.patientName = ''; 
      state.securityPin = ''; 
      state.currentTime = ''; 
      state.patientsList = []; 
      state.notes = []; 
      state.selectedConsultationNote = null; 
      state.selectedAttachedFile = null; 
      state.selectedImage = null; 
      state.attachedFiles = []; 
      state.images = []; 
      state.templates = []; 
      state.isNewNoteCreated = false; 
      state.isNewFileAttached = false; 
      state.isNewImageUploaded = false; 
      state.isNewTemplateCreated = false; 
      state.initialRendering = true; 
      state.mouseInfo = null; 
      state.selectPatientSearchQuery = ''; 
      state.isImageSlideOpened = false; 
      state.isRefreshContent = false; 
      state.selectedTemplate = null; 
      state.templateOptionsXPosition = 0; 
      state.templateOptionsYPosition = 0; 

    } 
  },
});

export const { 
  setConsultationId, 
  setSection, 
  setCategory, 
  setPatientId,
  setPatientName, 
  setSecurityPin, 
  setCurrentTime, 
  setPatientsList, 
  setNotes, 
  setSelectedConsultationNote, 
  setSelectedAttachedFile, 
  setSelectedImage, 
  setAttachedFiles, 
  setImages, 
  setTemplates, 
  setIsNewNoteCreated, 
  setIsNewFileAttached, 
  setIsNewImageUploaded, 
  setIsNewTemplateCreated, 
  setInitialRendering, 
  setMouseInfo, 
  setSelectPatientSearchQuery, 
  setIsImageSlideOpened, 
  setIsRefreshContent,
  setSelectedTemplate, 
  setTemplateOptionsXPosition,
  setTemplateOptionsYPosition, 
  resetConsultation } = consultationSlice.actions;

export default consultationSlice.reducer;