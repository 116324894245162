import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteDropdown from './NoteDropdown';

interface Props { 
    title: string; 
    updatedAt: string; 
}; 

const NoteRow: React.FC<Props> = ({ title, updatedAt }) => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    const [isDropdownOpened, setIsDropdownOpened] = useState(false); 
    const [formattedTitle, setFormattedTitle] = useState(''); 

    // -- 

    const handleDropdown = () => { 

        setIsDropdownOpened(!isDropdownOpened); 

    }; 

    // -- 

    useEffect(() => { 

        if (updatedAt) { 

            title ? setFormattedTitle(title) : setFormattedTitle(String(`(${t('draft')})`)); 

        }; 

    },[title, updatedAt]); 

    // -- 

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                fontSize: 12, 
                height: 50,
                cursor: 'pointer',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}>
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>

                    <Box component="img" sx={{ width: 20, marginLeft: 1 }} src={require('../../../../../assets/icons/note-thumbnail.png')} />

                </div>
                
                <div style={{ 
                    width: '70%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{formattedTitle}</div>

                <div style={{ 
                    width: '10%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{moment.utc(updatedAt).format('L')}</div>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    width: '10%', 
                }}>

                    <MoreVertIcon onClick={handleDropdown} sx={{ color: colors?.text, fontSize: 20 }} />

                </div>
    
            </Box>

            { isDropdownOpened && <NoteDropdown />}

        </>

    );
};

export default NoteRow;