import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Redux 

import { useTranslation } from 'react-i18next';
import { setNotificationMessage } from '../../../redux/features/general/notification';

// Routes

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { setOpenPopup } from '../../../redux/features/general/popup';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  positionX: number,
  positionY: number,
};


const Dropdown: React.FC<Props> = ({positionX, positionY }) => {

    const { t } = useTranslation(); 

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const group = useAppSelector(state => state.doctorAccount.group); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const [isLoading, setIsLoading] = useState(false); 

    // -- 
    
    return (

    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 200,
            left: positionX - 250, 
            top: positionY, 
            position: 'absolute',
            backgroundColor: colors?.dropdown,
            borderRadius: 4,
            cursor: 'pointer',
            zIndex: 5, 
        }}
    >

    </Box>

    ); 

};

export default Dropdown;