import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 
import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField, setErroredField, setEditedPatientChange, setErroredCategory, removeErroredCategory} from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setHealthCardNumber, setEmail, setPhone, setAddress, setSection, setCompletedField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputHeader from './items/InputHeader';
import BottomSpace from './items/BottomSpace';
import InputRow from './items/InputRow';

// Icons 

import ContactMailIcon from '@mui/icons-material/ContactMail';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';


// Style 

import iconStyle from './styles/iconStyle';
import inputPropsStyle from './styles/inputPropsStyle';
import textFieldStyle from './styles/textFieldStyle';

const ContactInfo: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);
    const nextSection = useAppSelector((state) => state.createPatient.nextSection); 

    const healthCardNumber = useAppSelector((state) => state.createPatient.healthCardNumber); 
    const phone = useAppSelector((state) => state.createPatient.phone); 
    const email = useAppSelector((state) => state.createPatient.email); 
    const address = useAppSelector((state) => state.createPatient.address); 

    const [healthCardNumberError, setHealthCardNumberError] = useState(''); 
    const [phoneError, setPhoneError] = useState(''); 
    const [emailError, setEmailError] = useState(''); 
    const [addressError, setAddressError] = useState(''); 

    const healthCardNumberRef = useRef() as any; 
    const phoneRef =  useRef() as any; 
    const emailRef =  useRef() as any; 
    const addressRef = useRef() as any; 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'healthCardNumber') { 

            dispatch(setHealthCardNumber(input)); 

        } else if (section === 'email') { 

            dispatch(setEmail(input)); 

        } else if (section === 'phone') { 

            const reg = new RegExp('^[0-9\+\(\)\-\]+$');

            if (reg.test(input)) { 

                dispatch(setPhone(input)); 

            }; 

        } else if (section === 'address') { 

            dispatch(setAddress(input)); 

        }; 

        dispatch(setEditedPatientChange(true)); 

    };

    // -- 

    const confirmInput = (key: string, field: string) => { 

        if (key == 'Enter') { 

            if (field === 'healthCardNumber') { 

                phoneRef.current.focus();

                !healthCardNumber && dispatch(setErroredField({ field: 'healthCardNumber', error: 'requiredField' }));
    
            } else if (field === 'phone') { 

                addressRef.current.focus();

                !phone && dispatch(setErroredField({ field: 'phone', error: 'requiredField' }));
    
            } else if (field === 'address') { 

                emailRef.current.focus(); 

                !address && dispatch(setErroredField({ field: 'address', error: 'requiredField' }));
    
            } else if (field === 'email') {

                !email && dispatch(setErroredField({ field: 'email', error: 'requiredField' }));

                if (section !== 'confirmation') { 

                    dispatch(setSection(nextSection)); 

                };

            }; 

        }; 

    };

    // -- 

    useEffect(() => { 

        if (erroredFields.length > 0) { 

            setHealthCardNumberError('');
            setPhoneError('');
            setEmailError('');
            setAddressError('');

            const fields = ['healthCardNumber', 'phone', 'email', 'address'];

            const sectionErrors = erroredFields.filter((item: any) => { 

                if (fields.includes(item.field)) { 

                        return item; 
                }; 

            }); 

            (sectionErrors?.length > 0) ? dispatch(setErroredCategory('contactInfo')) : dispatch(removeErroredCategory('contactInfo')); 

            erroredFields.forEach((item: any) => { 

                if (item.field === 'healthCardNumber') { 

                    setHealthCardNumberError(item.error); 

                } else if (item.field === 'phone') { 

                    setPhoneError(item.error); 

                } else if (item.field === 'email') { 

                    setEmailError(item.error); 

                } else if (item.field === 'address') { 

                    setAddressError(item.error); 

                }; 

                if (fields.includes(item.field) && (item.error === 'requiredField')) { 

                    dispatch(setErroredCategory('contactInfo')); 

                }; 

            });

        }; 

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: section === 'confirmation' ? 'auto' : '95%',
        }}>

            <InputHeader title={'healthCardNumber'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/healthCardNumber.png')} />} 
                iconColor='rgb(207, 137, 45)' 
                required 
                isDisabled={editedPatient ? true : false}
                value={healthCardNumber}
                error={healthCardNumberError}>

                <TextField
                    value={healthCardNumber}
                    placeholder={String(t('healthCardNumber'))}
                    onChange={(e) => handleInput(e.target.value, 'healthCardNumber')}
                    variant="standard"
                    autoComplete='off'
                    disabled={editedPatient ? true : false}
                    autoFocus={editedPatient ? false: true}
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'healthCardNumber')}
                    inputRef={healthCardNumberRef}
                />
            </InputRow>

            <InputHeader title={'phone'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/phone.png')} />} 
                iconColor='rgb(45, 153, 207)' 
                value={phone}
                required 
                isCustomSelection
                action='phone'
                error={phoneError}>

                <TextField
                    value={phone}
                    placeholder={String(t('pressSelectToContinue'))}
                    onChange={(e) => handleInput(e.target.value, 'phone')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'phone')}
                    inputRef={phoneRef}
                />
            </InputRow>

            <InputHeader title={'address'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/address.png')} />} 
                iconColor='rgb(45, 207, 62)' 
                value={address}
                required 
                error={addressError}>

                <TextField
                    value={address}
                    placeholder={String(t('address'))}
                    onChange={(e) => handleInput(e.target.value, 'address')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'address')}
                    inputRef={addressRef}
                />
            </InputRow>

            <InputHeader title={'email'} /> 

            <InputRow 
                icon={<Box component="img" sx={{ width: 20, height: 20 }} src={require('../../../../../../assets/icons/patient/email.png')} />} 
                iconColor='rgb(45, 207, 196)' 
                value={email}
                required 
                error={emailError}>

                <TextField
                    value={email}
                    placeholder={String(t('email'))}
                    onChange={(e) => handleInput(e.target.value, 'email')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'email')}
                    inputRef={emailRef}
                />
            </InputRow>

            <BottomSpace /> 
            
        </Box>
    );
};

export default ContactInfo;