import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { setGroupId, setGroupName } from '../../../../redux/features/hacker/users/createDoctor';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getGroups } from '../../../../routes/hacker/users';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import getRandomColor from '../../../../util/style/getRandomColor';

const GroupSelection: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const [text, setText] = useState(''); 
    const [isOptionsListOpened, setIsOptionsListOpened] = useState(false); 
    const [groups, setGroups] = useState([]); 

    // -- 

    const handleChange = (e: any) => { 

        setText(e.target.value); 

        dispatch(setGroupName(e.target.value)); 
        dispatch(setGroupId('')); 

    }; 

    // --

    const handleSelection = (e: any) => { 

        setIsOptionsListOpened(!isOptionsListOpened); 

    }; 

    // -- 

    const selectGroup = (item: any) => { 

        dispatch(setGroupName(item.name)); 
        dispatch(setGroupId(item._id)); 

        setText(item.name); 

        setIsOptionsListOpened(false); 

    }; 

    // -- 

    const handleGroupList = async () => { 

        const res = await getGroups() as any; 

        if (res.data.status === 200) { 

            setGroups(res.data.groups); 

        }; 

    }; 

    // --

    useEffect(() => { 

        handleGroupList(); 

    },[]); 

    // -- 

    return (

        <>

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '100%', 
            marginTop: 2, 
            marginBottom: 2, 
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 2, 
                    width: '80%'
                }}
            >
                <HomeWorkIcon sx={{ color: colors?.green }} />

                <Box
                    sx={{ 
                        marginLeft: 1
                    }}
                >{t('group')}
                </Box>

            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%', 
                    padding: 1, 
                    borderColor: 'rgb(25, 43, 122)', 
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 2, 
                    
                }}
            >

                <TextField
                    id="create-account-form"
                    placeholder={'Select or create a group'}
                    variant="standard"
                    value={text}
                    autoFocus
                    InputProps={{
                        style: {fontSize: 16, color: colors?.text },
                        disableUnderline: true, 
                    }}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '80%',
                        borderBottomColor: colors?.searchIcon
                    }}
                />

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Button 
                    onClick={handleSelection}
                        sx={{ 
                            textTransform: 'none',
                            width: 'auto', 
                            height: 40, 
                            paddingLeft: 1, 
                            paddingRight: 1, 
                            marginRight: 1,
                            backgroundColor: colors?.createPatientSelect }} 
                        variant="contained">{t('select')}
                    </Button>
            
                </Box>

            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%',
                    marginBottom: 1,
                    marginTop: 0.5,  
                    fontSize: 10, 
                }}
            >
                {t('groupSelectionInfo')}
            </Box>

        </Box>
        
        {(groups && isOptionsListOpened) && 
        
            <Box sx={{ 
                width: '100%', 
                backgroundColor: colors?.createPatientOptionsList,
                borderRadius: 3, 
                fontSize: 12,
                position: 'relative',
                marginBottom: 3, 
                zIndex: 20,
                left: 0,
                marginLeft: 15, 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',  
            }}>
    
                { groups?.length >= 1 && 
    
                <Box 
                    
                    sx={{ 
                        width: '100%',
                        height: 'auto',
                        scrollbarWidth: 'none' 
                    }}>
    
                    <List dense disablePadding>
    
                    {groups?.map((item: any, index) => (
    
                        <ListItem onClick={() => selectGroup(item)} button key={index} disablePadding>
    
                            <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    alignItems: 'center', 
                                    justifyContent: 'space-between', 
                                    width: '100%',
                                    paddingLeft: 1, 
                                    paddingRight: 1,
                                    minHeight: 40, 
                                    borderRadius: 3, 
                                    color: colors?.text,
                                    zIndex: 15,
                                    '&:hover': { backgroundColor: colors?.patientSectionHover }
                            }}>

                                <Box
                                    sx={{ 
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center' 
                                    }}
                                >

                                    <AddLocationAltIcon sx={{ color: getRandomColor() }} /> 

                                    <Box sx={{ marginLeft: 2}}>{item.name}</Box>

                                </Box>

                                <Box
                                    sx={{ 
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center' 
                                    }}
                                >{item._id}
                                </Box>

                            </Box>
    
                        </ListItem>
    
                    ))}
    
                    </List>
    
                </Box>}
                        
            </Box>}
        
        </>

  );

};

export default GroupSelection;