import moment from 'moment';

const getDaysUntilDeletion = (creationDate: any) => { 

    const today = moment(new Date()); 
    const expirationDate = moment(creationDate).add(30, "days") as any; 

    return expirationDate.diff(today, 'days'); 

}; 

export default getDaysUntilDeletion; 