import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { createDoctor } from '../../../../routes/hacker/users';
import { resetCreateDoctor } from '../../../../redux/features/hacker/users/createDoctor';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import { setIsRefreshDoctorsList } from '../../../../redux/features/hacker/users/doctorsAccess';
import { setDashboardSection } from '../../../../redux/features/general/navigation';

const SubmitButton: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const firstName = useAppSelector(state => state.createDoctor.firstName); 
    const lastName = useAppSelector(state => state.createDoctor.lastName); 
    const email = useAppSelector(state => state.createDoctor.email); 
    const securityPin = useAppSelector(state => state.createDoctor.securityPin); 
    const groupId = useAppSelector(state => state.createDoctor.groupId); 
    const groupName = useAppSelector(state => state.createDoctor.groupName); 
    const type = useAppSelector(state => state.createDoctor.type); 

    const [allowUserCreation, setAllowUserCreation] = useState(false);
    const [error, setError] = useState('');  

    // -- 

    useEffect(() => { 

    if (firstName && lastName && email && (securityPin?.length === 4) && (groupId || groupName)) { 

        setAllowUserCreation(true); 

    } else { 

        setAllowUserCreation(false); 

    };

    setError(''); 

    },[firstName, lastName, email, securityPin, groupId, groupName]); 

    // -- 

    const handleCreateUser = async () => { 

        const res = await createDoctor(firstName, lastName, email, securityPin, groupId, groupName) as any; 

        if (res.data.status === 200) { 

            dispatch(resetCreateDoctor()); 

            dispatch(setIsRefreshDoctorsList(true)); 

            dispatch(setDashboardSection('doctors')); 

            dispatch(setNotificationMessage('Account Successfully Created')); 

        } else if (res.data.status === 401) { 

            setError(res.data.error); 

        }; 

    }; 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '80%'
            }}
        >

            <Box
                onClick={handleCreateUser}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    padding: 1, 
                    fontSize: 16, 
                    borderRadius: 1, 
                    marginBottom: 4, 
                    marginTop: 3, 
                    marginRight: 2, 
                    height: 40,
                    color: allowUserCreation ? 'white' : colors?.text, 
                    cursor: allowUserCreation ? 'pointer' : 'default', 
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: allowUserCreation ? colors?.navbarToolEnabled : colors?.navbarToolDisabled, 
                    '&:hover': { backgroundColor: allowUserCreation ? colors?.navbarToolEnabledHover : colors?.navbarToolDisabled  }
                }}
            >

                <Box>{t('createAccountButton')}</Box>

                <AddCircleOutlineIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

            </Box>

            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginLeft: 1, 
                    color: colors?.red
                }}
            >
                {error}
            </Box>

        </Box>



  );

};

export default SubmitButton;