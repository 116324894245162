import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Header from './header/Header';

const Document: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '48.5%',
          height: '98%',
          borderRadius: 2,  
          bottom: '1%', 
          right: '0.5%', 
          position: 'absolute', 
          backgroundColor: colors?.container,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}>

        <Header />

      </Box>

  );

};

export default Document;