import { UseScrollTriggerOptions } from '@mui/material/useScrollTrigger/useScrollTrigger';
import axios from 'axios';
import Cookies from 'js-cookie';


// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('adminJwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 


// --- 

export const getDoctors = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctors`, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error getting a list of doctors:', error);
    };

};

// -- 

export const createDoctor = async (firstName: string, lastName: string, email: string, securityPin: string, groupId: string, groupName: string) => {

    try {

        const content = { 
            firstName: firstName, 
            lastName: lastName, 
            email: email,
            securityPin: securityPin,
            groupId: groupId, 
            groupName: groupName
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/create/doctor`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a doctor: createDoctor()', error);
    };

};

// -- 

export const getSupportTickets = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/support/tickets`, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error: getSupportTickets()', error);
    };

};

// -- 

export const getTicketDetails = async (author: string) => { 

    try { 

        const content = { 
            author: author
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/support/ticketInfo`, content, getConfig()); 

        if (res.status === 200) { 

            return res.data.author; 

        }; 

    } catch (error) {
        console.error('Error getting the ticket details:', error);
    }; 

}; 

// -- 

export const createSupportTicket = async (author: string, status?: string, category?: string, description?: string) => { 

    try { 

        const content = { 
            author: author,
            status: status,
            category: category, 
            description: description
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/support/tickets/create`, content, getConfig()); 

        if (res.status === 200) { 

            return res; 

        }; 

    } catch (error) {
        console.error('Error creating a support ticket:', error);
    }; 

}; 

// -- 

export const archiveSupportTicket = async (supportTicketId: string) => { 

    try { 

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/support/tickets/archive/${supportTicketId}`, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error in archiveSupportTicket():', error);
    }; 

}; 

// --- 

export const createDevHackerAccount = async (email: string, firstName: string, lastName: string) => {

    try {

        const content = { 
            email: email, 
            firstName: firstName, 
            lastName: lastName
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/create/hacker`, content); 

        return res; 

    } catch (error) {
        console.error('Error creating a dev Hacker account.', error);
    };

};

// -- 

export const createHacker = async (email: string, firstName: string, lastName: string, main: boolean) => {

    try {

        const content = { 
            email: email, 
            firstName: firstName, 
            lastName: lastName,
            main: main, 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/create/hacker`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a hacker.', error);
    };

};

// -- 

export const getPatients = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/patients`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error getting a list of patients:', error);
    };

};

// -- 

export const getHackers = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/hackers`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error getting a list of hackers:', error);
    };

};

// -- 

export const deletePatient = async (patientId: string) => {

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/hacker/patient/${patientId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error deleting a patient:', error);
    };

};

// -- 

export const deactivateDoctor = async (doctorId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctor/deactivate/${doctorId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error deactivating a doctor:', error);
    };

};

// -- 

export const reactivateDoctor = async (doctorId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctor/reactivate/${doctorId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error reactivating a doctor:', error);
    };

};

// -- 

export const getDashboardData = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/data`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving dashboard data.', error);
    };

};

// -- 

export const getNumberOfDoctorsByDate = async (date: any) => {

    try {

        const content = { 
            date: date,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/data/doctors/date`, content, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving the dashboard doctor date.', error);
    };

};

// -- 

export const getNumberOfPatientsByDate = async (date: any) => {

    try {

        const content = { 
            date: date,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/data/patients/date`, content, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving the dashboard patient date.', error);
    };

};

// -- 

export const getSecurityData = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/data/security`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving dashboard security data.', error);
    };

};

// -- 

export const getActivityData = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/data/activity`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving dashboard activity data.', error);
    };

};

// -- 

export const getActivityByHour = async (date: any) => {

    try {

        const content = { 
            date: date,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/data/activity/hour`, content, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving the activity by hour.', error);
    };

};

// -- 

export const requestDoctorDeletion = async (doctorId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctor/account/deletion/request/${doctorId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error in requestDoctorDeletion.', error);
    };

};

// -- 

export const confirmDoctorDeletion = async (deletedAccountId: any, authCode: any) => {

    try {

        const content = { 
            deletedAccountId: deletedAccountId, 
            authCode: authCode
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctor/account/deletion/confirm`, content,  getConfig()); 

        return res;

    } catch (error) {
        console.error('Error in confirmDoctorDeletion.', error);
    };

};

// --

export const getGroups = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/groups`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error in getGroups().', error);
    };

};


export const getGroup = async (groupId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/group/${groupId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error in getGroup().', error);
    };

};

// -- 

export const getGroupsByDoctor = async (doctorId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/hacker/doctor/groups/${doctorId}`, getConfig()); 

        return res;

    } catch (error) {
        console.error('Error retrieving a list of groups by doctors.', error);
    };

};