import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import { setIsFieldsListOpened, setIsOptionsListOpened } from '../../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

const Options: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // -- 

    const handleOptions = () => { 

      dispatch(setIsFieldsListOpened(true)); 

    }; 

    // --

    return (

      <Box 
        onClick={handleOptions}
        sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: 50,
              height: 50,
              borderRadius: '50%', 
              overflow: 'hidden',  
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors?.avatarBorder,
              backgroundColor: colors?.microphoneOption
        }}>

        <DescriptionIcon sx={{ color: colors?.microphoneOptionIcon }} /> 

      </Box>

  );

};

export default Options;