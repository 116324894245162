import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    icon: React.ReactNode; 
    title: string; 
    description: string; 
}

const InfoBox: React.FC<Props> = ( {icon, title, description }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                textAlign: 'center', 
                width: 300,
            }}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 50,
                    height: 50,
                    borderRadius: '50%', 
                    backgroundColor: 'rgba(177, 168, 168, 0.3)', 
                }}
            >
                {icon}

            </Box>

            <Box sx={{ 
                marginTop: 2,
                fontWeight: 'bold', 
            }}>{t(title)}</Box>

            <Box sx={{ 
                marginTop: 2,
                fontSize: 12,
                width: '80%',
                color: 'rgba(170, 170, 170)',
            }}>{t(description)}</Box>
            
        </Box>

  );

};

export default InfoBox;