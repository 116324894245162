import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setMode, setSelectedNoteField } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

const AutoComplete: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const noteFields = useAppSelector(state => state.noteMicrophone.noteFields); 
    const selectedNoteField = useAppSelector(state => state.noteMicrophone.selectedNoteField); 

    // -- 

    useEffect(() => { 

        if (noteFields?.length > 0) { 

            if (!selectedNoteField) { 

                dispatch(setSelectedNoteField(noteFields[0]));  

            }; 

        } else { 

            dispatch(setSelectedNoteField(null));  
            dispatch(setMode('')); 

        }; 

    },[noteFields]); 

    // -- 

    return (

      <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '100%', 
            bottom: '60%', 
            position: 'absolute', 
            overflow: 'hidden',  
            fontSize: 25, 
            borderColor: colors?.avatarBorder,
            color: colors?.text, 
      }}>{selectedNoteField?.title}

      </Box>

  );

};

export default AutoComplete;