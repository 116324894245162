import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Settings from './Settings';
import Actions from './Actions';

const Sidebar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '19.5%',
          height: '98%',
          bottom: '1%', 
          position: 'absolute', 
          left: '0.5%',
          borderRadius: 2, 
          backgroundColor: colors?.container, 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}>

        <Actions />

        <Settings />

      </Box>

  );

};

export default Sidebar;