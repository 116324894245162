import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setMain } from '../../../../redux/features/hacker/users/createHacker';

// Components 

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import SecurityIcon from '@mui/icons-material/Security';


const Access: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const { t } = useTranslation(); 

  const colors = useAppSelector(state => state.theme.colors); 

  const main = useAppSelector(state => state.createHacker.main); 

  const [isMain, setIsMain] = useState(false); 

  useEffect(() => { 

    dispatch(setMain(isMain)); 

  },[isMain]); 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        width: '100%',
        height: '100%', 
        marginTop: 3, 
    }}>

          <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 2, 
                width: '80%', 
            }}
        >
            <SecurityIcon sx={{ color: colors?.purple }} /> 

            <Box
                sx={{ 
                    marginLeft: 1
                }}
            >{t('permissions')}
            </Box>

        </Box>
    
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '80%', 
            marginBottom: '3vh',
            borderColor: 'rgb(138, 133, 133)', 
            borderStyle: 'solid',
            borderWidth: 2, 
            borderRadius: 2, 
            padding: 1 
        }}>

          <Box>{t('fullAccess')}</Box>

          <FormGroup>
              <FormControlLabel 
                control={ 
                  <Switch 
                    onChange={(e) => setIsMain(e.target.checked)} 
                    sx={{
                      "&.MuiSwitch-root .MuiSwitch-switchBase": {
                        color: "grey"
                      },
                      "&.MuiSwitch-track": {
                        color: "grey",
                        backgroundColor: 'grey'
                      },
                    
                      "&.MuiSwitch-root .Mui-checked": {
                       color: colors?.darkBlue
                      }
                    }}
                  /> 
                } 
                label="" 
              />
          </FormGroup>

        </Box>

    </Box>

  );

};

export default Access;