import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
// Redux 

import { selectFile, selectFolder, setConfirmedDraggedFile, setIsDropdownOpened, setMouseInfo, setUnconfirmedDraggedFile } from '../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import NavigationColumn from './folders/NavigationColumn';
import FilesSection from './filesList/FilesSection';
import Box from '@mui/material/Box';
import CorrectFile from './actions/correctFile/CorrectFile';
import GeneralDropdown from './actions/dropdowns/GeneralDropdown';
import Consultations from './consultations/Consultations';
import Trash from './trash/Trash';


const FileExplorer: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const colors = useAppSelector((state) => state.theme.colors); 

    const isDropdownOpened = useAppSelector((state) => state.fileExplorer.isDropdownOpened); 
    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile);
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder); 
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 
    const directory = useAppSelector((state) => state.fileExplorer.directory); 
    const mode = useAppSelector((state) => state.fileExplorer.mode); 

    // -- 

    const handleClick = (e: any) => { 

        if (isDropdownOpened) { 

            dispatch(setIsDropdownOpened(false)); 

        }; 

    }; 
    
    // --

    const handleClickOptions = (e: any) => { 

        if (mode === 'moveFile' || mode === 'sharedFolder') { 

            return; 

        };

        if (e.button == 2) { 

            if (isDropdownOpened && (selectedFolder || selectedFile)) { 

                dispatch(selectFile(null)); 
                dispatch(selectFolder(null)); 
    
            } else if (isDropdownOpened) { 

                dispatch(setIsDropdownOpened(false)); 

            } else { 

                if (directory?.includes('Shared')) { 

                    dispatch(setIsDropdownOpened(false)); 

                } else { 

                    dispatch(setIsDropdownOpened(true)); 
                    dispatch(setMouseInfo({ clientX: e.clientX, clientY: e.clientY }));

                }; 

            }; 

        }; 

    }; 

    // -- 

    return (

        <Box 
            id='fileExplorer'
            onClick={handleClick}
            onMouseDown={(e) => handleClickOptions(e)} 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '95vw',
                height: '95vh', 
                overflow: 'hidden',
                scrollbarWidth: 'none',
                left: leftOffset,
                bottom: 0,
                position: 'absolute',
                backgroundColor: colors?.interface,
            }}>

            {/* Sections */}

            <NavigationColumn /> 

            {((mode !== 'consultations') && (mode !== 'trash')) && <FilesSection />}
            { (mode === 'consultations') && <Consultations />}
            {(mode === 'trash') && <Trash />}

            { (isDropdownOpened && !selectedFile && !selectedFolder) && <GeneralDropdown />}

            {/* { mode === 'correctFile' && <CorrectFile />} */}

        </Box>

    );

};

export default FileExplorer;