import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import Box from '@mui/material/Box';
import Info from './info/Info';

const Start: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors);

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '95%',
            height: '95%', 
            borderRadius: 5,
            backgroundColor: colors?.container,
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>

            <Info /> 

        </Box>

    );

};

export default Start;