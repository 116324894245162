import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Box from '@mui/material/Box';
import Logo from './Logo';
import ItemsList from './ItemsList';
import { setIsNavigationDropdownOpened } from '../../../redux/features/general/navigation';

type Props = {
  children?: React.ReactNode;
  disableShadow?: boolean; 
};

const NavBar: React.FC<Props> = ({ children, disableShadow }) => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100vw',
        height: '5vh',
        position: 'absolute',
        backgroundColor: colors?.navBar,
        boxShadow: disableShadow ? '' : '0 4px 6px rgba(0, 0, 0, 0.2)',
        zIndex: 10000000,
        padding: '0 1vw',
      }}
    >
      <Logo size={20} />
      <ItemsList>

        {children}
      </ItemsList>
    </Box>
  );
};

export default NavBar;