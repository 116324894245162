import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import metaReducer from '../redux/features/general/meta';
import notificationReducer from '../redux/features/general/notification'; 
import navigationReducer from '../redux/features/general/navigation';
import popupRedcuer from '../redux/features/general/popup'; 
import createSupportTicketReducer from '../redux/features/general/createSupportTicket'; 
import themeReducer from '../redux/features/general/theme'; 
import loginReducer from '../redux/features/auth/login'; 

import doctorAccountReducer from '../redux/features/doctor/Account/account';
import doctorPreferencesReducer from '../redux/features/doctor/Account/preferences';
import fileExplorerReducer from './features/doctor/Dashboard/documents/fileExplorer';
import fileViewerReducer from './features/doctor/Dashboard/documents/fileViewer'; 
import imageViewerReducer from './features/doctor/Dashboard/documents/imageViewer'; 
import noteViewerReducer from './features/doctor/Dashboard/documents/noteViewer'; 
import editFileReducer from './features/doctor/Dashboard/documents/editFile'; 
import editFolderReducer from './features/doctor/Dashboard/documents/editFolder'; 
import createFolderReducer from './features/doctor/Dashboard/documents/createFolder'; 
import patientReducer from '../redux/features/doctor/Dashboard/patients/patient';
import patientNoteReducer from '../redux/features/doctor/Dashboard/patients/patientNote'; 
import patientDirectiveReducer from '../redux/features/doctor/Dashboard/patients/patientDirective'; 
import patientListReducer from '../redux/features/doctor/Dashboard/patients/patientList';
import patientLogsReducer from '../redux/features/doctor/Dashboard/patients/patientLogs'; 
import notificationsReducer from '../redux/features/doctor/Dashboard/patients/notifications'; 
import accessRequestsReducer from '../redux/features/doctor/Dashboard/patients/accessRequests'; 
import trashReducer from '../redux/features/doctor/Dashboard/documents/trash'; 

import searchPatientReducer from '../redux/features/doctor/Dashboard/patients/searchPatient'; 
import createPatientReducer from '../redux/features/doctor/Dashboard/patients/createPatient'; 
import correctFileReducer from '../redux/features/doctor/Dashboard/documents/correctFile';
import uploadFileReducer from './features/doctor/Dashboard/documents/uploadFile';
import templatesReducer from './features/doctor/Dashboard/templates/templates'; 
import scanTemplateReducer from './features/doctor/Dashboard/templates/scanTemplate'; 
import adminCreateTeamReducer from '../redux/features/admin/dashboard/team/createTeam';
import adminCreateUserReducer from '../redux/features/admin/dashboard/users/createUser';
import adminUserAccessReducer from '../redux/features/admin/dashboard/users/userAccess';
import adminNoteReducer from '../redux/features/admin/dashboard/documents/note';
import adminCreateNoteReducer from '../redux/features/admin/dashboard/documents/createNote';
import adminRefreshNotesReducer from '../redux/features/admin/dashboard/documents/refreshNotes';
import adminFileReducer from '../redux/features/admin/dashboard/documents/file';
import adminRefreshFilesReducer from '../redux/features/admin/dashboard/documents/refreshFiles';
import adminConfirmationReducer from '../redux/features/admin/account/confirmation';

import templateReducer from '../redux/features/template/template'; 

import hackerDataReducer from './features/hacker/data/hackerData'; 
import createDoctorReducer from './features/hacker/users/createDoctor';
import createHackerReducer from './features/hacker/users/createHacker';
import supportTicketReducer from './features/support/supportTicket';
import supportEmailReducer from './features/support/supportEmail'; 
import groupsAccessReducer from './features/hacker/users/groupsAccess'; 
import doctorsAccessReducer from './features/hacker/users/doctorsAccess'; 
import patientsAccessReducer from './features/hacker/users/patientsAccess'; 
import supportTicketsAccessReducer from './features/hacker/users/supportTicketsAccess'; 
import hackersAccessReducer from './features/hacker/users/hackersAccess'; 

import consultationReducer from './features/doctor/Dashboard/consultation/consultation'; 
import consultationNoteReducer from './features/doctor/Dashboard/consultation/consultationNote'; 
import savedConsultationReducer from './features/doctor/Dashboard/consultation/savedConsultation'; 
import noteMicrophoneReducer from './features/doctor/Dashboard/consultation/noteMicrophone'; 
import cameraReducer from './features/doctor/Dashboard/consultation/camera'; 

import groupReducer from './features/doctor/group/group'; 
import createGroupAccessReducer from './features/doctor/group/createGroupAccess'; 
import groupAccessReducer from './features/doctor/group/groupAccess'; 


const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, metaReducer);

export const store = configureStore({
  reducer: {
    meta: persistedReducer,
    notification: notificationReducer, 
    navigation: navigationReducer, 
    popup: popupRedcuer, 
    createSupportTicket: createSupportTicketReducer, 
    theme: themeReducer, 
    login: loginReducer, 

    hackerData: hackerDataReducer, 
    createDoctor: createDoctorReducer, 
    createHacker: createHackerReducer, 
    supportTicket: supportTicketReducer, 
    supportEmail: supportEmailReducer,

    groupsAccess: groupsAccessReducer,
    doctorsAccess: doctorsAccessReducer,
    patientsAccess: patientsAccessReducer, 
    supportTicketsAccess: supportTicketsAccessReducer, 
    hackersAccess: hackersAccessReducer, 

    doctorAccount: doctorAccountReducer,
    doctorPreferences: doctorPreferencesReducer, 
    fileExplorer: fileExplorerReducer,
    templates: templatesReducer, 
    scanTemplate: scanTemplateReducer, 
    editFile: editFileReducer, 
    editFolder: editFolderReducer, 
    fileViewer: fileViewerReducer, 
    imageViewer: imageViewerReducer,
    noteViewer: noteViewerReducer, 
    createFolder: createFolderReducer, 
    patient: patientReducer,
    patientNote: patientNoteReducer, 
    patientDirective: patientDirectiveReducer, 
    patientList: patientListReducer,
    patientLogs: patientLogsReducer, 
    notifications: notificationsReducer, 
    accessRequests: accessRequestsReducer, 
    trash: trashReducer, 
    searchPatient: searchPatientReducer, 
    createPatient: createPatientReducer, 
    correctFile: correctFileReducer,
    uploadFile: uploadFileReducer,
    adminCreateTeam: adminCreateTeamReducer,
    adminCreateUser: adminCreateUserReducer,
    adminUserAccess: adminUserAccessReducer,
    adminNote: adminNoteReducer,
    adminCreateNote: adminCreateNoteReducer,
    adminRefreshNotes: adminRefreshNotesReducer,
    adminFile: adminFileReducer,
    adminRefreshFiles: adminRefreshFilesReducer,
    adminConfirmation: adminConfirmationReducer,

    template: templateReducer, 

    consultation: consultationReducer, 
    consultationNote: consultationNoteReducer, 
    savedConsultation: savedConsultationReducer, 
    noteMicrophone: noteMicrophoneReducer, 
    camera: cameraReducer,

    group: groupReducer, 
    createGroupAccess: createGroupAccessReducer,
    groupAccess: groupAccessReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];