import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

const AccessRequestsHeader: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        width: '100%',
        paddingLeft: '1%',
        fontSize: 12, 
        backgroundColor: colors?.listHeaderBackground,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
    }}>

        <>

          <div style={{  width: '18%', marginLeft: '2%' }}>Patient</div>

          <div style={{ width: '50%', marginLeft: '5%' }}>Status</div>

          <div style={{ width: '15%' }}>Sent</div>

          <div style={{ width: '10%', textAlign: 'center' }}>More</div>

        </>


    </Box>

  );

};

export default AccessRequestsHeader;