import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { getDoctors } from '../../../routes/hacker/users';
import { setDashboardSection } from '../../../redux/features/general/navigation';
import { setDoctors, setInitialRendering, setIsRefreshDoctorsList, setSelectedDoctor } from '../../../redux/features/hacker/users/doctorsAccess';

// Components 

import ListHeader from '../../hacker/doctors/ListHeader';
import DoctorRow from './DoctorRow';
import LinearProgress from '@mui/material/LinearProgress';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Dropdown from './Dropdown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Popup from '../../general/Popup';
import AuthCode from './AuthCode';

const DoctorsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);  

    const isRefreshDoctorsList = useAppSelector(state => state.doctorsAccess.isRefreshDoctorsList);  
    const selectedDoctor = useAppSelector(state => state.doctorsAccess.selectedDoctor);  
    const doctors = useAppSelector(state => state.doctorsAccess.doctors);  
    const initialRendering = useAppSelector(state => state.doctorsAccess.initialRendering);  
    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    const [postionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const getDoctorsList = async () => { 

      const res = await getDoctors() as any; 

      if (res.data.status === '200') { 

        dispatch(setDoctors(res.data.doctors)); 

      };

    }; 

    // -- 

    const handleSelection = (e: any, doctor: any) => { 

      if (selectedDoctor) { 

        if (selectedDoctor?._id == doctor?._id) { 

          dispatch(setSelectedDoctor(null)); 

          return; 
  
        }; 

      }; 

      setPositionX(e.pageX);
      setPositionY(e.pageY); 

      dispatch(setSelectedDoctor(doctor)); 

    }; 

    // -- 

    const changeSection = () => { 

      dispatch(setDashboardSection('createDoctor')); 

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getDoctorsList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

      if (isRefreshDoctorsList) { 

        getDoctorsList(); 

        dispatch(setIsRefreshDoctorsList(false)); 

      }; 

    },[isRefreshDoctorsList]); 

    // -- 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              width: '95vw',
              text: colors?.text, 
              backgroundColor: colors?.container,
            }}>            
            
            <ListHeader />

            { doctors?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {doctors?.map((item, index) => (
        
                    <ListItem sx={{ color: colors?.text }} onClick={(e) => handleSelection(e, item)} button key={index}>

                        <DoctorRow 
                          _id={item._id}
                          firstName={item.firstName}
                          lastName={item.lastName}
                          accountStatus={item.accountStatus} 
                          email={item.email}
                          lastActive={item.lastActive}
                          groupId={item.groupId}
                          version={item.version}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {doctors?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly', 
              height: '100%',
              width: '80%', 
          }}>

              <Box
                  sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}>

                  <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                  <Box>Retrieving Doctors...</Box>

              </Box>

          </Box>}

          { selectedDoctor && <Dropdown positionX={postionX} positionY={positionY - 30} />}

          <AddCircleIcon 
            onClick={changeSection}
            sx={{ 
              width: 70,
              height: 70, 
              color: 'rgb(45, 207, 196)', 
              position: 'absolute', 
              bottom: 30, 
              right: 30, 
              cursor: 'pointer',
              '&:hover' : { color: 'rgb(65, 227, 216)'}
            }}
          />
        
            </Box>

            { isPopupOpened && 
              <Popup>
                <AuthCode />
              </Popup>}

        </Box>
    
      );

};

export default DoctorsList;