import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Header from './header/Header';
import Files from './files/Files';

// Components 

const Trash: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box 
            
        style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '80%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: colors?.container,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            
        }}>

            <Header /> 

            <Files /> 

            {/* <Footer /> */}

        </Box>

    );

};

export default Trash;