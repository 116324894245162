import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../general/inputs/Button';
import { createConsultation } from '../../../../routes/doctor/consultation';
import { setConsultationId } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';

const Actions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // --

    const handleConsultationCreation = async () => { 

        const res = await createConsultation() as any; 

        if (res?.data.status === 200) { 

            dispatch(setConsultationId(res.data.consultation._id)); 

            navigate(`/consultation/${res.data.consultation._id}`);

            window.location.reload(); 

        }; 

    }; 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          position: 'absolute',
          top: 0, 
          fontSize: 14 
      }}>

        <Button 
            title={'newConsultation'}
            icon={<AddIcon sx={{ color: 'white', fontSize: 18 }} />}
            triggerFunction={handleConsultationCreation}
            extraStyle={{ width: '80%' }}
        />

      </Box>

  );

};

export default Actions;